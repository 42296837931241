import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs, Button, Dropdown, Menu, Table, Spin, Modal } from 'antd';
import { DeleteOutlined, NodeIndexOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    loadDeletedList,
    deleteContactList,
    restoreContactList,
} from 'redux/actions/contactListAction';
import {
    ParentWrapper,
    Title,
    NoteWrapper,
    TitleWrapper,
    HeaderWrapper,
    TableWrapper,
    TrashStyledTable,
} from './Styles';
import { contactListColumns, segmentListColumns } from '../../constants/ContactListTable';
import * as STRINGS from 'app/language/en.lang';
import { VerticalDots, EnvelopeIcon, UserIcon } from 'app/constants/icons';

const {
    DELETE_CONTACT_LIST,
    LIST,
    RESTORE_CONTACT_LIST,
    TRASH_BIN,
    TRASH_BIN_NOTE,
    CONTACT_LIST_BIN,
    SEGMENTS_BIN,
    CONTACT_BIN,
    SEGMENTS_LISTS,
    DELETE_LIST_INFO,
    RESTORE_LIST,
    RESTORE_LIST_INFO,
    DELETE_LIST,
    DEFAULT_ERROR_MESSAGE,
} = STRINGS.CONTACT_LIST_DASHBOARD;

const TrashBin = () => {
    let { t } = useTranslation('translation');

    const dispatch = useDispatch();
    // const firstLoad = useSelector((state) => state?.contactList?.trashLoading ?? false);
    const contactListData = useSelector((state) => state?.contactList?.softDeleteList ?? []);

    const listAreaRef = useRef();

    const { TabPane } = Tabs;

    const [firstLoad, setFirstLoad] = useState(true);
    const [pageErrorState, setPageErrorState] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [collectionListId, setCollectionListId] = useState({});
    const [confirmDeleteLoading, setConfirmDeleteLoading] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
    const [confirmRestoreLoading, setConfirmRestoreLoading] = useState(false);
    const [isRestoreModelOpen, setIsRestoreModelOpen] = useState(false);

    //handle delete or edit list click
    const handelActionEvent = (e) => {
        if (e.key === 'deletecontacts') {
            setIsDeleteModelOpen(true);
        }

        if (e.key === 'restorecontacts') {
            setIsRestoreModelOpen(true);
        }
    };

    const handleDeleteCancel = () => {
        setCollectionListId({});
        setIsDeleteModelOpen(false);
        setConfirmDeleteLoading(false);
    };

    //Submit Delete Model
    const handleDeleteOk = () => {
        const listId = collectionListId.id;
        if (listId) {
            setConfirmDeleteLoading(true);
            const finishDel = () => {
                setIsDeleteModelOpen(false);
                setConfirmDeleteLoading(false);
                setCollectionListId({});

                // dispatch(loadDeletedList());
            };
            dispatch(deleteContactList({ listId, finishDel }));
        }
    };

    //close restore model
    const handleRestoreCancel = () => {
        setCollectionListId({});
        setIsRestoreModelOpen(false);
        setConfirmRestoreLoading(false);
    };

    //Submit Restore Model
    const handleRestoreOk = () => {
        const listId = collectionListId.id;
        if (listId) {
            setConfirmRestoreLoading(true);
            const finishRestore = () => {
                setIsRestoreModelOpen(false);
                setConfirmRestoreLoading(false);
                setCollectionListId({});

                // dispatch(loadDeletedList());
            };
            dispatch(restoreContactList({ listId, finishRestore }));
        }
    };

    const actionMenu = (
        <Menu onClick={handelActionEvent}>
            <Menu.Item key="restorecontacts" icon={<NodeIndexOutlined />}>
                {t(RESTORE_CONTACT_LIST)}
            </Menu.Item>
            <Menu.Item key="deletecontacts" icon={<DeleteOutlined />}>
                {t(DELETE_CONTACT_LIST)}
            </Menu.Item>
        </Menu>
    );

    const fetchLists = async () => {
        setLoadingContacts(true);
        const callback = (error = null) => {
            setFirstLoad(false);
            setLoadingContacts(false);
            if (error) {
                setPageErrorState(true);
            }
        };
        dispatch(loadDeletedList({ callback }));
    };

    useEffect(() => {
        fetchLists();
    }, []);

    useEffect(() => {
        if (Array.isArray(contactListData) && contactListData.length > 0) {
            let userCollectionList = contactListData.map((items) => {
                return {
                    key: items._id,
                    name: (
                        <div className={'list-name action-btn-block'}>
                            <Dropdown
                                overlay={actionMenu}
                                onMouseEnter={() => {
                                    setCollectionListId({ id: items._id, name: items.name });
                                }}
                            >
                                <button
                                    onClick={() => {
                                        setCollectionListId({ id: items._id, name: items.name });
                                    }}
                                >
                                    <VerticalDots />
                                </button>
                            </Dropdown>
                            <span>{items.name}</span>
                        </div>
                    ),
                    type: (
                        <>
                            <h4>{t(LIST)}</h4>
                        </>
                    ),
                    count: (
                        <div className={'list-count'}>
                            <EnvelopeIcon />
                            <span>{items.contactCount}</span>
                        </div>
                    ),
                    subscriberCount: (
                        <div className={'list-subscriber-count'}>
                            <UserIcon />
                            <span>{items.subscriberCount}</span>
                        </div>
                    ),
                };
            });
            setContactList(userCollectionList);
            setLoadingContacts(false);
        }

        if (!firstLoad && loadingContacts && contactListData.length == 0) {
            setLoadingContacts(false);
        }
    }, [contactListData, firstLoad]);

    return (
        <>
            {!loadingContacts && !pageErrorState && (
                <ParentWrapper ref={listAreaRef} className={'trash-bin-container'}>
                    <HeaderWrapper>
                        <TitleWrapper>
                            <Title>{t(TRASH_BIN)}</Title>
                            <NoteWrapper>
                                <p>{t(TRASH_BIN_NOTE)}</p>
                            </NoteWrapper>
                        </TitleWrapper>
                    </HeaderWrapper>
                    <TableWrapper>
                        <Tabs defaultActiveKey="1" className={'table-tabs'}>
                            <TabPane tab={t(CONTACT_LIST_BIN)} key="1">
                                {loadingContacts ? (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                type={'loading'}
                                                style={{ fontSize: '50px' }}
                                            />
                                        }
                                    />
                                ) : (
                                    <TrashStyledTable
                                        dataSource={contactList}
                                        columns={contactListColumns}
                                        pagination={{
                                            pageSize: 10,
                                        }}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab={t(SEGMENTS_BIN)} key="2">
                                <h6>{t(SEGMENTS_LISTS)}</h6>
                            </TabPane>
                            {/* <TabPane tab={t(CONTACT_BIN)} key="3">
                        <h6>{t(CONTACT_BIN)}</h6>
                    </TabPane> */}
                        </Tabs>
                    </TableWrapper>
                    <Modal
                        title={t(RESTORE_LIST)}
                        visible={isRestoreModelOpen}
                        onOk={handleRestoreOk}
                        confirmLoading={confirmRestoreLoading}
                        onCancel={handleRestoreCancel}
                    >
                        <p>
                            {t(RESTORE_LIST_INFO)} {collectionListId.name}
                        </p>
                    </Modal>
                    <Modal
                        title={t(DELETE_LIST)}
                        visible={isDeleteModelOpen}
                        onOk={handleDeleteOk}
                        confirmLoading={confirmDeleteLoading}
                        onCancel={handleDeleteCancel}
                    >
                        <p>
                            {t(DELETE_LIST_INFO)} {collectionListId.name}
                        </p>
                    </Modal>
                </ParentWrapper>
            )}
            {loadingContacts && (
                <Spin
                    indicator={<LoadingOutlined type={'loading'} style={{ fontSize: '50px' }} />}
                />
            )}
            {pageErrorState && !loadingContacts && <p>{t(DEFAULT_ERROR_MESSAGE)}</p>}
        </>
    );
};

export default TrashBin;
