export const showModal = (callback, mode, single, selectedMedia, options) => {
    return {
        type: 'SHOW_MEDIA_MODAL',
        callback,
        mode,
        single,
        selectedMedia,
        options,
    };
};

export const hideModal = () => ({
    type: 'HIDE_MEDIA_MODAL',
});
