import React from 'react';
import msLogo from '../../../assets/images/MS-logo.png';
import styled from 'styled-components';

function MeLogo() {
    return <LogoImage className="logo-image" src={msLogo} alt="Make-stories-logo-image" />;
}

export default MeLogo;

const LogoImage = styled.img`
    width: 11.5rem;
    padding: 2.9375rem 0 1.0625rem;
`;
