import { createSlice } from '@reduxjs/toolkit';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

const INITIAL_STATE = {
    gifs: [],
    error: false,
    loading: false,
    pos: false,
    searchTerm: '',
    noGifsFound: false,
};

const tenorGifsSlice = createSlice({
    name: 'tenorGifs',
    initialState: INITIAL_STATE,
    reducers: {
        loadGifs(state, action) {
            state.loading = true;
            state.error = false;
            state.noGifsFound = false;
        },
        loadError(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.noGifsFound = false;
        },
        loadSuccess(state, action) {
            state.loading = false;
            state.gifs = [...state.gifs, ...action.payload.gifs];
            state.error = false;
            state.pos = action.payload.next;
            state.noGifsFound = false;
        },
        updateSearch(state, action) {
            if (action.payload || action.payload === '') {
                state.searchTerm = action.payload;
                state.noGifsFound = false;
            }
        },
        emptyGifs(state, action) {
            state.gifs = [];
            state.error = false;
            state.pos = false;
            state.noGifsFound = false;
        },
        noGifsFound(state, action) {
            state.noGifsFound = action.payload;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(closeTemplate, () => {
            return INITIAL_STATE;
        });
    },
});

export const { loadGifs, loadError, loadSuccess, updateSearch, emptyGifs, noGifsFound } =
    tenorGifsSlice.actions;

export default tenorGifsSlice.reducer;

export const selectTenorGifs = (state) => state.tenorGifs;
