import styled from 'styled-components';

export const colors = {
    f: '#ffffff',
    black: '#101218',
    lightgrey: '#fafafa',
    darkgrey: '#959595',
    text: '#303030',
    red: ' #C5292A',
    lightRed: '#f9e9e9',
    blue: '#0e64ff',
    lightBlue: '#ECF3FF',
    bluehover: '#0a55dc',
    green: '#0ABA61',
    lightGreen: '#E3F7ED',
    Yellow: '#FBBF2E',
    lightYellow: '#FFF3D6',
};

export const WorkSpaceButtonContainer = styled.div`
    padding: 10px 20px 0;
`;

export const DropDownContainer = styled.div`
    position: relative;

    .dropbtn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        text-align left;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }

    .dropdown-content {
        display: none;
        margin: 20px 0 0 20px;
        z-index: 1;

        ul {
            border-left: 1px solid #f0f0f0;
            padding-left: 8px;

            li {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .show {
        display: block;
    }

    .dropdown-btn-block {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
`;

export default styled.nav`
    .header-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 35px 0;
        background-color: #fff;
        z-index: 99;
    }
    .side-bar-main {
        position: relative;
        top: 0;
        left: 0;
        width: 72px;
        background-color: ${colors.f};
        height: 100vh;
        transition: 0.4s ease;
        overflow: hidden;
        z-index: 99;
        display: flex;
        flex-direction: column;

        .logo-area {
            display: flex;
            justify-content: space-between;

            svg {
                opacity: 0.2;
                margin: 5px 10px;
            }
        }

        .hidden-text {
            opacity: 0;
            transition: 0.3s ease;
        }

        .siderbar-nav-contents {
            width: 225px;
            padding: 20px 5px 50px;
            position: relative;
            height: 100%;
            list-style-type: none;
            margin: 0;

            li {
                margin: 0;

                > a {
                    display: block;
                    margin-bottom: 30px;
                    padding: 0 16px;
                }
            }

            .nav-user {
                display: flex;
                align-items: center;
                position: relative;
                padding: 0px 16px;
                margin-bottom: 2em;
                line-height: inherit;
                &:before {
                    background-image: url(/img/double-arrow.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    position: absolute;
                    right: 12px;
                    top: 9px;
                    width: 13px;
                    height: 13px;
                    content: '';
                    transition: 0.3s ease;
                    transform: rotateZ(0);
                }
                & div {
                    &:first-child {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .hidden-text {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    margin-left: 8px;
                }
            }

            .user-dropdown {
                background: #ffffff;
                border: 1.0251px solid #f7f7f7;
                box-shadow: 0px -5.12552px 33.8285px rgba(0, 0, 0, 0.1);
                padding: 15px;
                width: 90%;
                position: absolute;
                top: 40px;
                z-index: 99;
                margin: 0 auto;
                left: 0;
                right: 0;
                border-radius: 8px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(15px);
                transition: 0.4s ease;

                p {
                    font-size: 12px;
                    color: #000;
                    margin: 0;
                    font-weight: 600;
                }

                div span {
                    font-size: 12px;
                    opacity: 0.6;
                    line-height: 1;
                }

                a {
                    margin-bottom: 15px;
                }

                .link {
                    font-weight: 600;
                    display: block;
                    text-align: center;
                    color: ${colors.blue};
                    margin: 20px 0 0;
                }

                .workspace-item {
                    display: flex;
                    align-items: center;

                    & > div {
                        &:first-child {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .workspace-details {
                        padding-left: 10px;

                        .invite-btn {
                            width: 50px;
                            margin-left: 8px;
                            padding: 0px 5px;
                            font-size: 0.9em;
                        }
                    }
                }
            }
            .show-dropdown {
                .user-dropdown {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }

            .nav-profile {
                font-size: 10.24px;
                color: ${colors.green};
                width: 32px;
                height: 32px;
                background-color: ${colors.lightGreen};
                display: inline-block;
                line-height: 32px;
                border-radius: 50%;
                text-align: center;
            }
            .nav-link {
                margin-left: 5px;
                padding: 12px 16px;
                margin-bottom: 8px;
                border-radius: 6px;
                display: block;

                svg {
                    fill: #252525;
                    transition: 0.3s ease;
                }

                .nav-icon {
                    display: inline-block;
                    vertical-align: middle;
                }

                .hidden-text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #252525;
                    display: inline-block;
                    margin-left: 15px;
                }

                &:hover {
                    background-color: #fafafa;
                }

                .nav-dropdown-menu {
                    .is-active {
                        color: #1657cd;
                    }
                }
            }

            .nav-link.active {
                .hidden-text {
                    color: rgb(14, 100, 255);
                }
            }

            .new-story-button {
                padding: 8px 13px;
                background-color: transparent;
                border-radius: 0;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                transition: 0.3s ease;
                position: relative;
                overflow: hidden;
                margin: 0 auto 12px;
                max-width: 210px;
                border-radius: 0;
                display: block;
                height: 46px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 0;
                    height: 100%;
                    content: '';
                    transition: 0.4s ease;
                    background-color: ${colors.blue};
                    z-index: 0;
                    opacity: 0;
                }

                .hidden-text {
                    margin-left: 8px;
                    position: relative;
                    z-index: 4;
                }

                .story-icon {
                    width: 32px;
                    height: 32px;
                    background-color: ${colors.blue};
                    display: inline-block;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 32px;
                    transition: 0.4s ease;

                    svg {
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }
                }

                &:hover {
                    .story-icon,
                    &:before {
                        background-color: ${colors.bluehover};
                    }
                }
            }

            .bottom-bandwidth {
                position: absolute;
                width: 100%;
                bottom: 0px; /* 40px -> 0px */
                /* margin: 0; */

                .bandwidth-icon {
                    transition: 0.3s ease;
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    display: block;
                }
                .bandwidth-block {
                    background: #fff;
                    border-radius: 8px;
                    padding: 8px;
                    position: absolute;
                    top: -25px;
                    max-width: 190px;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.3s ease;
                    > p {
                        font-size: 14px;
                        line-height: 24px;
                        color: #252525;
                        margin: 0 0 6px;
                    }

                    .bandwidth-progress {
                        height: 5px;
                        border-radius: 30px;
                        background-color: rgba(226, 231, 245, 0.5);
                        position: relative;
                        overflow: hidden;

                        span {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            background-color: ${colors.blue};
                        }
                    }

                    .flex-row p {
                        font-size: 12px;
                        line-height: 16px;
                        color: rgba(0, 0, 0, 0.5);
                        margin: 6px 0 0;
                    }
                }
            }
            .nav-dropdown {
                .nav-link {
                    position: relative;

                    &:before {
                        background-image: url(/img/chevron-down.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        position: absolute;
                        right: 12px;
                        top: 19px;
                        width: 13px;
                        height: 13px;
                        content: '';
                        transition: 0.3s ease;
                        transform: rotateZ(0);
                        opacity: 0.3;
                    }
                }
            }
            .nav-dropdown-menu {
                display: block;
                padding-left: 25px;
                border-left: 2px solid #e8e8e8;
                margin: 0 0 0 40px;
                max-height: 0;
                overflow: hidden;
                transition: 0.5s ease;

                a {
                    display: block;
                    margin-bottom: 18px;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.5px;
                    color: #252525;

                    &:hover {
                        color: ${colors.blue};
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
                ::-webkit-scrollbar {
                    width: 0px;
                }

                .is-active {
                    color: #1657cd;
                }
            }

            .show-dropdown {
                .nav-link {
                    background-color: #fafafa;
                    margin-bottom: 20px;

                    &:before {
                        transform: rotateZ(180deg);
                    }
                }
                .nav-dropdown-menu {
                    margin: 0 0 30px 41px;
                    max-height: 200px;
                    overflow: auto;
                }
            }
        }

        &.dropdown-open {
            width: 225px;

            .hidden-text {
                opacity: 1;
            }
            .siderbar-nav-contents {
                .new-story-button {
                    padding: 8px 7px;
                    border-radius: 8px;
                    margin: 0 auto 12px;

                    &:before {
                        width: 100%;
                        opacity: 1;
                    }
                }

                .bottom-bandwidth {
                    .bandwidth-icon {
                        opacity: 0;
                        visibility: hidden;
                        transform: translateX(45px);
                    }
                    .bandwidth-block {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    .sidebar-menu-items-group {
        overflow-x: hidden;
        overflow-y: auto;
        /*height: 548px;*/
        min-height: 85%;
        height: 85%;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-bottom: 25px;

        .last-element {
            margin-top: auto !important;
        }
    }
    .nav-link-alternative {
        margin-left: 5px;
        padding: 12px 16px;
    }
    .add-margin-custom {
        margin-bottom: 10px !important;
    }
    .language-dropdown {
        margin-left: 5px;
        padding: 12px 16px;
        margin-bottom: 8px;
        width: 100%;
        overflow: hidden;

        .user-profile-block {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            div {
                margin-left: 0;

                & > span {
                    display: flex;
                    align-items: center;
                }
                .ant-avatar {
                    width: 26px;
                    height: 26px;
                    margin-right: 0;
                }
            }

            .profile-name {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                margin-left: 8px;
            }
        }

        button {
            width: 100%;
        }
    }

    .nav-dropdown-menu {
        is-active {
            color: rgb(14, 100, 255);
        }
    }
`;

export const TextEllipsis = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${(props) => (props && props.width ? `${props.width}px` : '100px')};
`;
