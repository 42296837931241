import * as STRINGS from './en.lang';

export const spanish = {
    [STRINGS.COUNTER_TEST.ADD_VALUE]: 'Mehrwert',
    [STRINGS.COUNTER_TEST.ADD_ASYNC]: 'Asyn hinzufügen',
    [STRINGS.PROFILE_SETTINGS.PROFILE]: 'Profil',
    [STRINGS.PROFILE_SETTINGS.LOGOUT]: 'Abmelden',
    [STRINGS.LOGIN_PAGE.BACK]: 'espalda',

    [STRINGS.LOGIN_PAGE.TRIP_TO_BALI]: 'Viaje a Bali',
    [STRINGS.LOGIN_PAGE.SIGN_IN]: 'Iniciar Sesión',
    [STRINGS.LOGIN_PAGE.SIGN_UP]: 'Registrarse',
    [STRINGS.LOGIN_PAGE.WELCOME_BACK_TO_MAKESTORIES]: 'Bienvenido de nuevo a MakeStories',
    [STRINGS.LOGIN_PAGE.EMAIL_ADD]: 'Dirección email',
    [STRINGS.LOGIN_PAGE.PASSWORD]: 'Contraseña',
    [STRINGS.LOGIN_PAGE.FORGOT_PASSWORD]: 'Olvide mi contraseña',
    [STRINGS.LOGIN_PAGE.CAPS_SIGN_IN]: 'INICIAR SESIÓN',
    [STRINGS.LOGIN_PAGE.OR]: 'O',
    [STRINGS.LOGIN_PAGE.GOOGLE]: 'GOOGLE',
    [STRINGS.LOGIN_PAGE.START_BUILDING_STORIES]: 'Empezar a Construir Historias',
    [STRINGS.LOGIN_PAGE.ABSOLUTELY_FREE]: 'Completamente Gratis',
    [STRINGS.LOGIN_PAGE.USERNAME]: 'Nombre de Usuario',
    [STRINGS.LOGIN_PAGE.SIGN_UP_FOR_FREE]: 'Registrarse Gratis',
    [STRINGS.LOGIN_PAGE.WE_WILL_SEND_EMAIL_WITH_INSTRUCTION]:
        'Le enviaremos un email con instrucciones',
    [STRINGS.LOGIN_PAGE.SEND_RESET_INSTRUCTION]: 'Enviar instrucciones para restablecer',

    [STRINGS.LOGIN_PAGE.INSTRUCTIONS_ARE_SENT_TO_YOUR_EMAIL]:
        'Las instrucciones se enviarán a su correo electrónico.',
    [STRINGS.LOGIN_PAGE.SIGN_IN_WITH_GOOGLE]: 'Inicia sesión con Google',
    [STRINGS.LOGIN_PAGE.SIGN_IN_WITH_APPLE]: 'Iniciar sesión en Apple',
    [STRINGS.LOGIN_PAGE.NOT_SIGN_IN]: '¿Aún no estás registrado?',
    [STRINGS.LOGIN_PAGE.CREATE_ACCOUNT]: 'Crea una cuenta',
    [STRINGS.LOGIN_PAGE.PLEASE_ENTER_YOUR_EMAIL]:
        'Por favor, introduzca su dirección de correo electrónico',
    [STRINGS.USER_PROFILE.NEW_PASSWORD]: 'Nueva Contraseña',
    [STRINGS.USER_PROFILE.CHANGE_PASSWORD]: '¿Cambiar Contraseña?',
    [STRINGS.USER_PROFILE.OLD_PASSWORD]: 'Contraseña Anterior',
    [STRINGS.USER_PROFILE.CONFIRM_PASSWORD]: 'Contraseña Confirmada',
    [STRINGS.USER_PROFILE.RESET]: 'Restablecer',
    [STRINGS.USER_PROFILE.PROFILE_SETTINGS]: 'Configuración de Perfil',
    [STRINGS.USER_PROFILE.NAME]: 'Nombre',
    [STRINGS.USER_PROFILE.EMAIL]: 'Email',
    [STRINGS.USER_PROFILE.SAVE_CHANGES]: 'Guardar Cambios',
    [STRINGS.USER_PROFILE.CURRENT_PASSWORD]: 'Contraseña Actual',
    [STRINGS.USER_PROFILE.UPDATE_PASSWORD]: 'Actualizar Contraseña',
    [STRINGS.PROFILE_NOTIFICATIONS.NAME_UPDATED]: 'Nombre Actualizado',
    [STRINGS.PROFILE_NOTIFICATIONS.PROFILE_SUCCESSFULLY_UPDATED]:
        'Su perfil ha sido actualizado con éxito',
    [STRINGS.PROFILE_NOTIFICATIONS.ERROR]: 'Error',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PROFILE]: 'Error al actualizar perfil',
    [STRINGS.PROFILE_NOTIFICATIONS.EMPTY_DISPLAY_NAME]:
        'El Nombre Para Mostrar No Puede Estar Vacío',
    [STRINGS.PROFILE_NOTIFICATIONS.USER_NOT_FOUND]: 'No se puede encontrar al usuario',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_UPDATED]: 'Contraseña Actualizada',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_SUCCESSFULLY_UPDATED]:
        'Su contraseña ha sido actualizada con éxito',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PASSWORD]: 'Error al actualizar la contraseña',
    [STRINGS.PROFILE_NOTIFICATIONS.DIFFERENT_PASSWORD]:
        'La Nueva Contraseña y la Confirmación de Contraseña son diferentes. Por favor, verifique que sean las mismas e inténtelo de nuevo',
    [STRINGS.PROFILE_NOTIFICATIONS.INCOMPLETE_DETAILS]:
        'Por favor, complete todos los datos para proceder',
    [STRINGS.EMAIL_TEMPLATES.CREATE_NEW_DESIGN]: 'Crear nuevo diseño',
    [STRINGS.EMAIL_TEMPLATES.DELETE]: 'Borrar',
    [STRINGS.EMAIL_TEMPLATES.CLONE]: 'Clon',
    [STRINGS.EMAIL_TEMPLATES.EDIT]: 'Editar',
    [STRINGS.EMAIL_TEMPLATES.MY_TEMPLATES]: 'Mis plantillas',
    [STRINGS.EDITOR.SAVE]: 'Ahorrar',
    [STRINGS.EDITOR.SAVING]: 'Ahorro',
    [STRINGS.EDITOR.DEFAULT_BLOCKS]: 'Bloques predeterminados',
    [STRINGS.EDITOR.SAVED_TEMPLATES]: 'Plantillas guardadas',
    [STRINGS.EDITOR.FORM]: 'Formulario',
    [STRINGS.EDITOR.CREATE_CUSTOM_FORM]: 'Crear formulario personalizado',
    [STRINGS.RIGHTBAR.EDITOR.OUTER_SECTION]: 'Sección exterior',
    [STRINGS.RIGHTBAR.EDITOR.SECTION]: 'Sección',
    [STRINGS.RIGHTBAR.EDITOR.APPEARANCE]: 'Apariencia',
    [STRINGS.RIGHTBAR.EDITOR.COLOR]: 'Color',
    [STRINGS.RIGHTBAR.EDITOR.BACKGROUND_COLOR]: 'Color de fondo',
    [STRINGS.RIGHTBAR.EDITOR.FONT_WEIGHT]: 'Peso de fuente',
    [STRINGS.RIGHTBAR.EDITOR.FONT_FAMILY]: 'Familia tipográfica',
    [STRINGS.RIGHTBAR.EDITOR.BORDER]: 'Frontera',
    [STRINGS.RIGHTBAR.EDITOR.SHADOW]: 'Sombra',
    [STRINGS.RIGHTBAR.EDITOR.CTA_SETTINGS]: 'Configuración de CTA',
    [STRINGS.RIGHTBAR.EDITOR.IMAGE]: 'Imagen',
    [STRINGS.RIGHTBAR.EDITOR.TEXT]: 'Texto',
    [STRINGS.RIGHTBAR.EDITOR.CTA_TEXT]: 'Texto CTA',
    [STRINGS.RIGHTBAR.EDITOR.CTA_IMAGE]: 'Imagen CTA',
    [STRINGS.RIGHTBAR.EDITOR.CTA_DESIGNS]: 'Imágenes CTA',
    [STRINGS.RIGHTBAR.EDITOR.IMAGE_SETTINGS]: 'Configuración de Imagen',
    [STRINGS.RIGHTBAR.EDITOR.COLORS]: 'Colores',
    [STRINGS.RIGHTBAR.EDITOR.FILTER]: 'Filtro',
    [STRINGS.RIGHTBAR.EDITOR.LETTER_SPACING]: 'Espaciado de Letras',
    [STRINGS.RIGHTBAR.EDITOR.LINE_HEIGHT]: 'Altura de Línea',
    [STRINGS.RIGHTBAR.EDITOR.OPACITY]: 'Opacidad',
    [STRINGS.RIGHTBAR.EDITOR.FONT_SIZE]: 'Tamaño de Fuente',
    [STRINGS.RIGHTBAR.EDITOR.STROKE_SIZE]: 'Tamaño de Trazo',
    [STRINGS.RIGHTBAR.EDITOR.STROKE_COLOR]: 'Color de Trazo',
    [STRINGS.RIGHTBAR.EDITOR.WIDTH]: 'Ancho',
    [STRINGS.RIGHTBAR.EDITOR.ALIGNMENT]: 'Alineación',
    [STRINGS.RIGHTBAR.EDITOR.SOCIAL]: 'Social',
    [STRINGS.RIGHTBAR.EDITOR.BOX_SHADOW]: 'Sombra de la caja',
    [STRINGS.RIGHTBAR.EDITOR.DROP_SHADOW]: 'Sombra paralela',
    [STRINGS.RIGHTBAR.EDITOR.BLUR]: 'Difuminar',
    [STRINGS.RIGHTBAR.EDITOR.SPREAD]: 'Propagar',
    [STRINGS.RIGHTBAR.EDITOR.PADDING]: 'Relleno',
    [STRINGS.RIGHTBAR.EDITOR.BORDER_RADIUS]: 'Radio de borde',
    [STRINGS.RIGHTBAR.EDITOR.MARGIN]: 'Margen',
    [STRINGS.RIGHTBAR.EDITOR.ALTERNATE_TEXT]: 'Texto alternativo',
    [STRINGS.RIGHTBAR.EDITOR.ALT]: 'Alt',
    [STRINGS.RIGHTBAR.EDITOR.URL]: 'URL',
    [STRINGS.RIGHTBAR.EDITOR.LINK_URL]: 'URL del enlace',
    [STRINGS.RIGHTBAR.EDITOR.IMAGE_URL]: 'URL de la imagen',
    [STRINGS.RIGHTBAR.EDITOR.ADD_SOCIAL_BLOCK]: 'Agregar bloque social',
    [STRINGS.SHOW_NOTIFICATION.SAVING_TEMPLATE]: 'Guardar plantilla',
    [STRINGS.SHOW_NOTIFICATION.TEMPLATE_SAVED_SUCCESSFULLY]: 'Plantilla guardada correctamente',
    [STRINGS.SHOW_NOTIFICATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER]:
        'Algo salió mal. Por favor, inténtelo de nuevo más tarde',
    [STRINGS.SHOW_NOTIFICATION.WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE]:
        'Nos encontramos con algún error. Actualiza la página para continuar.',
    [STRINGS.SHOW_NOTIFICATION.TEMPLATE_DELETED_SUCCESSFULLY]: 'Plantilla eliminada correctamente',
    [STRINGS.SHOW_NOTIFICATION.FAILED_TO_DELETE_TEMPLATE]: 'No se pudo borrar la plantilla',
    [STRINGS.SHOW_NOTIFICATION.TEMPLATE_CLONED_SUCCESSFULLY]: 'Plantilla clonada con éxito',
    [STRINGS.SHOW_NOTIFICATION.WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE]:
        'Se produjo un error al clonar la plantilla. Inténtelo de nuevo más tarde.',
    [STRINGS.SHOW_NOTIFICATION.DELETE_TEMPLATE]: 'Eliminar plantilla',
    [STRINGS.SHOW_NOTIFICATION.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE]:
        '¿Está seguro de que desea eliminar esta plantilla',
    [STRINGS.SHOW_NOTIFICATION.CANCEL]: 'Cancelar',
    [STRINGS.SHOW_NOTIFICATION.DELETE]: 'Borrar',
    [STRINGS.SHOW_NOTIFICATION.WHAT_WOULD_YOU_LIKE_TO_NAME_NEW_TEMPLATE]:
        '¿Cómo le gustaría nombrar la nueva plantilla',
    [STRINGS.SHOW_NOTIFICATION.CLONE]: 'Clon',
    [STRINGS.LEFT_NAVBAR.DASHBOARD]: 'Tablero',
    [STRINGS.LEFT_NAVBAR.EMAIL_TEMPLATES]: 'Plantillas de correo electrónico',
    [STRINGS.LEFT_NAVBAR.CAMPAIGNS]: 'Campañas',
    [STRINGS.LEFT_NAVBAR.INTEGRATIONS]: 'Integraciones',
    [STRINGS.LEFT_NAVBAR.SETTINGS]: 'Ajustes',
    [STRINGS.LEFT_NAVBAR.CONTACTS]: 'Contactos',
    [STRINGS.LEFT_NAVBAR.LISTS]: 'Liza',
    [STRINGS.LEFT_NAVBAR.FIELDS]: 'Los campos',
    [STRINGS.LEFT_NAVBAR.IMPORT_HISTORY]: 'Historial de importación',
    [STRINGS.LEFT_NAVBAR.IMPORT_CONTACTS]: 'Importar contactos',
    [STRINGS.LEFT_NAVBAR.TRASH]: 'Basura',
    [STRINGS.EDITOR_TOOLTIP.DELETE_ELEMENT]: 'Eliminar elemento',
    [STRINGS.EDITOR_TOOLTIP.MOVE_ELEMENT_UP]: 'Mover elemento hacia arriba',
    [STRINGS.EDITOR_TOOLTIP.MOVE_ELEMENT_DOWN]: 'Mover elemento hacia abajo',
    [STRINGS.EDITOR_TOOLTIP.DUPLICATE_ELEMENT]: 'Elemento duplicado',
    [STRINGS.EDITOR_TOOLTIP.DELETE_SECTION]: 'Eliminar sección',
    [STRINGS.EDITOR_TOOLTIP.DUPLICATE_SECTION]: 'Sección duplicada',
    [STRINGS.EDITOR_TOOLTIP.MOVE_SECTION_UP]: 'Mover sección hacia arriba',
    [STRINGS.EDITOR_TOOLTIP.MOVE_SECTION_DOWN]: 'Mover sección hacia abajo',
    [STRINGS.EDITOR_TOOLTIP.SAVE_SECTION]: 'Guardar sección como plantilla',
    [STRINGS.CONTACT_LIST_DASHBOARD.CONTACT_LIST]: 'Lista de contactos',
    [STRINGS.CONTACT_LIST_DASHBOARD.CREATE]: 'Crear',
    [STRINGS.CONTACT_LIST_DASHBOARD.ADD_CONTACT]: 'Agregar contacto',
    [STRINGS.CONTACT_LIST_DASHBOARD.SEGMENTS]: 'Segmentos',
    [STRINGS.CONTACT_LIST_DASHBOARD.SEGMENTS_LISTS]: 'Listas de segmentos',
    [STRINGS.CONTACT_LIST_DASHBOARD.CREATE_NEW_LIST]: 'Crear una lista nueva',
    [STRINGS.CONTACT_LIST_DASHBOARD
        .CREATE_LIST_INFO]: `Se recomienda crear una nueva lista. Solo los contactos que se agregan a una lista
        después de que una automatización se ponga en vivo, se ingresará en la automatización.`,
    [STRINGS.CONTACT_LIST_DASHBOARD.LIST_NAME]: 'Lista de nombres',
    [STRINGS.CONTACT_LIST_DASHBOARD.DELETE_LIST_INFO]: '¿Desea eliminar la tabla?: ',
    [STRINGS.CONTACT_LIST_DASHBOARD.NEW_LIST]: 'Lista nueva',
    [STRINGS.CONTACT_LIST_DASHBOARD.NEW_SEGMENT]: 'Nuevo segmento',
    [STRINGS.CONTACT_LIST_DASHBOARD.UPLOAD_CSV]: 'Subir CSV',
    [STRINGS.CONTACT_LIST_DASHBOARD.MANUAL_ADD]: 'Agregar manual',
    [STRINGS.CONTACT_LIST_DASHBOARD.UPDATE_CONTACT_LIST]: 'Actualizar lista de contactos',
    [STRINGS.CONTACT_LIST_DASHBOARD.DELETE_CONTACT_LIST]: 'Eliminar lista de contactos',
    [STRINGS.CONTACT_LIST_DASHBOARD.SEARCH_LIST]: 'Listas de búsqueda ...',
    [STRINGS.CONTACT_LIST_DASHBOARD.ACTIVE_IMPORTS]: 'Importaciones activas',
    [STRINGS.CONTACT_LIST_DASHBOARD.ALL_CONTACTS]: 'Todos los contactos',
    [STRINGS.CONTACT_LIST_DASHBOARD.GLOBAL]: 'Global',
    [STRINGS.CONTACT_LIST_DASHBOARD.LIST]: 'Lista',
    [STRINGS.CONTACT_LIST_DASHBOARD.TRASH_BIN]: 'Contenedor de basura',
    [STRINGS.CONTACT_LIST_DASHBOARD
        .TRASH_BIN_NOTE]: `Nota: la lista eliminada solo permanecerá aquí durante 30 días después de eso
        será eliminado permanentemente`,
    [STRINGS.CONTACT_LIST_DASHBOARD.CONTACT_LIST_BIN]: 'Contenedor de listas de contactos',
    [STRINGS.CONTACT_LIST_DASHBOARD.SEGMENTS_BIN]: 'Bandeja de segmentos',
    [STRINGS.CONTACT_LIST_DASHBOARD.CONTACT_BIN]: 'Contacto Bin',
};
