import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import MyTemplates from 'app/Pages/MyTemplates';
import { STYLE_CONSTANTS } from 'app/constants/styles.constants';
import * as STRINGS from 'app/language/en.lang';

const CreateNewTemplateBtn = styled(Link)`
    padding: 10px 15px;
    display: inline-block;
    background-color: ${STYLE_CONSTANTS.primaryColor};
    color: ${STYLE_CONSTANTS.bgWhiteLight};
    transition: all 1s;

    &:hover {
        color: ${STYLE_CONSTANTS.primaryColor};
        background-color: ${STYLE_CONSTANTS.bgWhiteLight};
    }
`;

const { TabPane } = Tabs;

const Templates = () => {
    const [t] = useTranslation('translation');
    const callback = () => {
        console.log('callback');
    };

    return (
        <div>
            <div style={{ textAlign: 'right' }}>
                <CreateNewTemplateBtn to="/email/new">
                    {t(STRINGS.EMAIL_TEMPLATES.CREATE_NEW_DESIGN)}
                </CreateNewTemplateBtn>
            </div>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab={t(STRINGS.EMAIL_TEMPLATES.MY_TEMPLATES)} key="1">
                    <MyTemplates />
                </TabPane>
                <TabPane tab={'Public Templates'} key="2">
                    <h2>Public Templates coming soon ...</h2>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Templates;
