import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    myTemplates: [],
    isTemplateListLoading: false,
};

const emailTemplatesSlice = createSlice({
    name: 'emailTemplates',
    initialState,
    reducers: {
        myTemplatesList(state, action) {
            if (Array.isArray(action.payload)) {
                state.myTemplates = action.payload;
            }
            return state;
        },
        setTemplateLoadingStatus(state, action) {
            state.isTemplateListLoading = action.payload;
        },
    },
});

export const { myTemplatesList, setTemplateLoadingStatus } = emailTemplatesSlice.actions;

export default emailTemplatesSlice.reducer;

export const selectEmailTemplates = (state) => state.emailTemplates;
