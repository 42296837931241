import { createSlice } from '@reduxjs/toolkit';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

const initialState = {
    savingTemplate: false,
    savingCustomTemplate: false,
    copiedBlock: false,
    activeLeftbarTab: 'blocks',
};

const editorSettingsSlice = createSlice({
    name: 'editorSettings',
    initialState: initialState,
    reducers: {
        savingTemplate(store, action) {
            store.savingTemplate = action.payload;
        },
        copyBlock(store, action) {
            if (action.payload) {
                store.copiedBlock = action.payload;
            }
        },
        updateActiveLeftbarTab(store, action) {
            if (action.payload) {
                store.activeLeftbarTab = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(closeTemplate, () => {
            return initialState;
        });
    },
});

export const { savingTemplate, copyBlock, updateActiveLeftbarTab } = editorSettingsSlice.actions;

export default editorSettingsSlice.reducer;

export const selectEditorSettings = (state) => state.editorSettings;
