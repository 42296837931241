import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { selectEmailTemplates } from 'redux/features/email_templates/emailTemplatesSlice';
import styled from 'styled-components';
import { loadMyTemplates, deleteTemplate, duplicateTemplate } from 'redux/actions/templatesAction';
import { STYLE_CONSTANTS } from 'app/constants/styles.constants';
import * as STRINGS from 'app/language/en.lang';
import i18next from 'i18next';

const {
    DELETE_TEMPLATE,
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE,
    CANCEL,
    DELETE,
    WHAT_WOULD_YOU_LIKE_TO_NAME_NEW_TEMPLATE,
    CLONE,
} = STRINGS.SHOW_NOTIFICATION;

const TemplatesWrapper = styled.ul`
    width: 70%;
    margin: 0 auto;
    padding: 2rem 0 2rem 0;

    li {
        margin-bottom: 2rem;
        padding: 1rem;
        border: 1px solid #000;
        display: flex;
        justify-content: space-between;
    }
`;

const EditLink = styled(Link)`
    padding: 3px 10px;
    margin-right: 10px;
    border: 1px solid ${STYLE_CONSTANTS.primaryColor};
`;

const MyTemplates = () => {
    const dispatch = useDispatch();
    const { myTemplates, isTemplateListLoading } = useSelector(selectEmailTemplates);
    const [t] = useTranslation('translation');
    useEffect(() => {
        dispatch(loadMyTemplates());
    }, []);
    const handleDeleteTemplate = (templateId) => {
        swal({
            title: i18next.t(DELETE_TEMPLATE),
            text: i18next.t(ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE) + '?',
            icon: 'warning',
            buttons: {
                cancel: i18next.t(CANCEL),
                confirm: {
                    text: i18next.t(DELETE),
                    closeModal: false,
                    cancel: false,
                },
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const callback = ({ message, status }) => {
                    swal('', message, status);
                    swal.stopLoading();
                    swal.close();
                };
                dispatch(deleteTemplate({ templateId, callback }));
            } else {
                return null;
            }
        });
    };
    const handleCopyTemplate = (templateId) => {
        let newTemplateName = document.createElement('input');
        newTemplateName.type = 'text';
        swal({
            text: i18next.t(WHAT_WOULD_YOU_LIKE_TO_NAME_NEW_TEMPLATE) + '?',
            content: newTemplateName,
            buttons: {
                confirm: {
                    text: i18next.t(CLONE),
                    value: true,
                    closeModal: false,
                },
                cancel: i18next.t(CANCEL),
            },
            showCancelButton: true,
        }).then((value) => {
            if (value) {
                const callback = ({ status, message }) => {
                    swal('', message, status);
                };
                dispatch(duplicateTemplate({ templateId, name: newTemplateName.value, callback }));
            }
        });
    };
    if (isTemplateListLoading) {
        return <h2>Loading...</h2>;
    }
    return (
        <div>
            <TemplatesWrapper>
                {Array.isArray(myTemplates) && myTemplates.length > 0 ? (
                    myTemplates.map((template) => (
                        <li key={template.templateId}>
                            <p>{template.name}</p>
                            <div>
                                <EditLink to={`/email/edit/${template.templateId}`}>
                                    {t(STRINGS.EMAIL_TEMPLATES.EDIT)}
                                </EditLink>
                                <button onClick={() => handleCopyTemplate(template.templateId)}>
                                    {t(STRINGS.EMAIL_TEMPLATES.CLONE)}
                                </button>
                                <button onClick={() => handleDeleteTemplate(template.templateId)}>
                                    {t(STRINGS.EMAIL_TEMPLATES.DELETE)}
                                </button>
                            </div>
                        </li>
                    ))
                ) : (
                    <h2>Email Templates Not Found</h2>
                )}
            </TemplatesWrapper>
        </div>
    );
};

export default MyTemplates;
