// const inProduction = process.env.NODE_ENV === 'production';

// export const firebaseConfig = inProduction
//     ? {
//           apiKey: 'AIzaSyAxduFYKRXAcOkLJeGvRi-z80fgA71Bfjo',
//           authDomain: 'makeemails-86bbd.firebaseapp.com',
//           projectId: 'makeemails-86bbd',
//           storageBucket: 'makeemails-86bbd.appspot.com',
//           messagingSenderId: '751349390911',
//           appId: '1:751349390911:web:916d753c3b427f056ce28a',
//           measurementId: 'G-WGJMPH1G5E',
//       }
//     : {
//           apiKey: 'AIzaSyAxduFYKRXAcOkLJeGvRi-z80fgA71Bfjo',
//           authDomain: 'makeemails-86bbd.firebaseapp.com',
//           projectId: 'makeemails-86bbd',
//           storageBucket: 'makeemails-86bbd.appspot.com',
//           messagingSenderId: '751349390911',
//           appId: '1:751349390911:web:e90cf68813fb0dc76ce28a',
//           measurementId: 'G-94C8S2LV4J',
//       };

// const inProduction = true;
const inProduction = process.env.REACT_APP_DEPLOY_TO === 'live';

export const firebaseConfig = inProduction
    ? {
          apiKey: 'AIzaSyBmjkqXXeAEX1A_qnINtRywO4xUt70Hxc8',
          authDomain: 'makestories-202705.firebaseapp.com',
          databaseURL: 'https://makestories-202705.firebaseio.com',
          projectId: 'makestories-202705',
          storageBucket: 'cdn-storyasset-link',
          messagingSenderId: '450976295561',
          clientId: '450976295561-urpqcifn766lu9bvoagkc9srq4ovkeia.apps.googleusercontent.com',
      }
    : {
          apiKey: 'AIzaSyCgLvHS1-XzwACnhUZc7yHsOW40H68F-JY',
          authDomain: 'makestories-staging.firebaseapp.com',
          databaseURL: 'https://makestories-staging.firebaseio.com',
          projectId: 'makestories-staging',
          storageBucket: 'makestories-staging.appspot.com',
          messagingSenderId: '390904467783',
          appId: '1:390904467783:web:728c5b31e1cf9eaf',
          clientId: '390904467783-d1nl8b1uqf4rr1okj0e73pl3c7jtroac.apps.googleusercontent.com',
      };
