import { notification } from 'antd';
import firebase from 'config/firebase';
import get from 'lodash/get';
import blockOptions from 'app/constants/blockOptions.json';

const getIdToken = () =>
    new Promise((res) => {
        if (firebase.auth().currentUser) {
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(res)
                .catch((err) => res(false));
        } else {
            res(false);
        }
    });

const generateId = (maxLength = 10) => {
    let text = '';
    let possible = 'abcdefghijklmnopqrstuvwxyz';

    for (let i = 0; i < maxLength; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

const hasOwnProperty = (object, property) => {
    return object && Object.prototype.hasOwnProperty.call(object, property);
};

let notificationsActive = {};
const showNotification = (
    id,
    message,
    description = '',
    type = 'info',
    duration = 10,
    icon = false,
) => {
    if (hasOwnProperty(notificationsActive, id)) {
        if (notificationsActive[id] >= Date.now()) {
            return false; // already exists and time has not passed
        }
    }
    notificationsActive[id] = Date.now() + duration * 1000;
    return notification.open({
        message,
        description,
        type,
        duration,
        icon,
    });
};

const capitalizeString = (str, splitBy = ' ') => {
    return str && typeof str.split === 'function'
        ? str
              .split(splitBy)
              .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
              .join(' ')
        : '';
};

const sanitizeStylesForReact = (styles) => {
    let newStyles = {};
    for (let i in styles) {
        newStyles[
            i
                .split('-')
                .map((s, i) => (i ? capitalizeString(s) : s))
                .join('')
        ] = styles[i];
    }
    return newStyles;
};

const getOuterSection = (outerSections = [], outerSectionId = false) => {
    if (Array.isArray(outerSections) && outerSectionId) {
        const index = outerSections.findIndex((outerSection) => outerSectionId === outerSection.id);
        if (index > -1) {
            return outerSections[index];
        }
    }
    return false;
};

const getSection = (outerSections = [], sectionId = false, outerSectionId = false) => {
    if (sectionId && outerSectionId) {
        const { sections } = getOuterSection(outerSections, outerSectionId);
        if (Array.isArray(sections)) {
            const index = sections.findIndex((section) => sectionId === section.id);
            if (index > -1) {
                return sections[index];
            }
        }
        return false;
    }
    return false;
};

const getBlock = (
    outerSections = [],
    outerSectionId = false,
    sectionId = false,
    blockId = false,
) => {
    if (outerSectionId && sectionId && blockId) {
        const { blocks } = getSection(outerSections, sectionId, outerSectionId);
        if (Array.isArray(blocks)) {
            const index = blocks.findIndex((block) => blockId === block.id);
            if (index > -1) {
                return blocks[index];
            }
        }
        return false;
    }
    return false;
};

const gradientStyling = (gradient, forApplication = 'background') => {
    const validApplications = {
        background: 'background',
        backgroundGradient: 'backgroundImage',
    };
    const validGradientTypes = ['linear', 'radial'];
    let $for = validApplications.background;
    if (hasOwnProperty(validApplications, forApplication)) {
        $for = validApplications[forApplication];
    }
    // let stylesArray = [];
    let type = 'linear'; //Default gradient type
    if (hasOwnProperty(gradient, 'type') && validGradientTypes.includes(gradient.type)) {
        type = gradient.type;
    }
    // if (hasOwnProperty(gradient,"fallback")) {
    // stylesArray.push(gradient.fallback);
    // }
    if (hasOwnProperty(gradient, 'stops')) {
        const gradientTypesFunc = {
            linear: () => {
                let direction = 'left';
                if (hasOwnProperty(gradient, 'direction')) {
                    const oppDir = { left: 'right', right: 'left', top: 'bottom', bottom: 'top' };
                    direction =
                        hasOwnProperty(gradient, 'direction') &&
                        hasOwnProperty(oppDir, gradient.direction) &&
                        gradient.direction
                            ? 'to ' + oppDir[gradient.direction]
                            : gradient.direction.indexOf('deg') >= 0
                            ? gradient.direction
                            : 'to right';
                }
                let repeat = '';
                if (hasOwnProperty(gradient, 'repeat') && gradient.repeat !== 'no-repeat') {
                    repeat = 'repeating-';
                }
                let stops = gradientTypesFunc.makeStops();
                // if (stops.length) {
                //     stops = stops.join();
                //     // stylesArray.push(`${repeat}linear-gradient(${direction},${stops})`);
                //     // stylesArray.push(`-webkit-${repeat}linear-gradient(${direction},${stops})`);
                //     // stylesArray.push(`-moz-${repeat}linear-gradient(${direction},${stops})`);
                //     // stylesArray.push(`-ms-${repeat}linear-gradient(${direction},${stops})`);
                //     // stylesArray.push(`-o-${repeat}linear-gradient(${direction},${stops})`);
                // }
                return `${repeat}linear-gradient(${direction},${stops})`;
                // return stylesArray;
            },
            radial: () => {
                const validShapes = ['circle', 'ellipse'];
                const validSizes = [
                    'closest-side',
                    'farthest-side',
                    'closest-corner',
                    'farthest-corner',
                ];
                let shape = 'ellipse';
                if (hasOwnProperty(gradient, 'shape') && validShapes.includes(gradient.shape)) {
                    shape = gradient.shape;
                }
                let size = 'farthest-corner';
                if (hasOwnProperty(gradient, 'size') && validSizes.includes(gradient.size)) {
                    size = gradient.size;
                }
                let position = '';
                if (hasOwnProperty(gradient, 'position')) {
                    position += `${size} at `;
                    if (typeof gradient.position === 'string') {
                        position += gradient.position;
                    } else {
                        if (hasOwnProperty(gradient.position, 'left')) {
                            position += ` ${gradient.position.left}`;
                        } else {
                            position += ` 50%`;
                        }
                        if (hasOwnProperty(gradient.position, 'top')) {
                            position += ` ${gradient.position.top}`;
                        } else {
                            position += ` 50%`;
                        }
                    }
                }
                // let repeat = "";
                // if (hasOwnProperty(gradient,"position") && gradient.repeat !== 'no-repeat') {
                //     repeat = 'repeating-';
                // }
                let stops = gradientTypesFunc.makeStops();
                if (stops.length) {
                    stops = stops.join();
                    // stylesArray.push(`-webkit-${repeat}radial-gradient(${shape} ${position},${stops})`);
                    // stylesArray.push(`-moz-${repeat}radial-gradient(${shape} ${position},${stops})`);
                    // stylesArray.push(`-o-${repeat}radial-gradient(${shape} ${position},${stops})`);
                    // stylesArray.push(`-ms-${repeat}radial-gradient(${shape} ${position},${stops})`);
                    // stylesArray.push(`${repeat}radial-gradient(${shape} ${position},${stops})`);
                }
                return `radial-gradient(${shape} ${position},${stops})`;
                // return stylesArray;
            },
            makeStops: () => {
                let stops = [];
                for (let stop of gradient.stops) {
                    if (hasOwnProperty(stop, 'color')) {
                        let tempStop = stop.color;
                        if (stop.position && hasOwnProperty(stop, 'position')) {
                            // stop.position = String(stop.position);
                            // made some changes here. please check makeStories if some issue.
                            tempStop +=
                                ` ${String(stop.position)}` +
                                (String(stop.position).indexOf('%') === -1 &&
                                String(stop.position).indexOf('px') === -1
                                    ? '%'
                                    : '');
                        }
                        stops.push(tempStop);
                    }
                }
                return stops;
            },
        };
        if (hasOwnProperty(gradientTypesFunc, type)) {
            let tmpObj = {};
            tmpObj[$for] = gradientTypesFunc[type]();
            return tmpObj;
        }
    }
    return '';
};

const cloneObject = (obj) => {
    try {
        let cloneJson = JSON.stringify(obj);
        return JSON.parse(cloneJson);
    } catch (err) {
        return obj;
    }
};

const rgbObjectToString = (color) => {
    let rgba = {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
        ...(typeof color === 'object' ? color : {}),
    };
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
};

const pixelToNumber = (px, defaultValue = 0) => {
    let number = parseFloat(px);
    if (!isNaN(number)) {
        return Number(number.toFixed(2));
    }
    return defaultValue;
};

const getElementBorderDirectionIndexes = (elementStyle, el) => {
    let directionIndex = [];
    if (elementStyle) {
        let borderWidth = elementStyle['border-width'];
        let border = elementStyle['border'];
        if (el && el.style) {
            border = el.style['border'];
            borderWidth = el.style['border-width'];
        }
        if (borderWidth) {
            //directions are left, right, bottom, full
            let borderWidthArr = borderWidth.split(' ');
            borderWidthArr.forEach((v, i) => {
                //check selected border
                if (pixelToNumber(v) > 0) {
                    directionIndex.push(i);
                }
            });
            if (directionIndex.length) {
                if (borderWidthArr.length === 1 && directionIndex.length === 1) {
                    return [0, 1, 2, 3];
                } else if (borderWidthArr.length === 2) {
                    if (directionIndex.includes(0)) {
                        directionIndex.push(2);
                    }
                    if (directionIndex.includes(1)) {
                        directionIndex.push(3);
                    }
                } else if (borderWidthArr.length === 3) {
                    if (directionIndex.includes(1)) {
                        directionIndex.push(3);
                    }
                }
                return directionIndex;
            }
        }
        if (border) {
            border = border.split(' ');
            if (border.length && pixelToNumber(border[0]) > 0) {
                return [0, 1, 2, 3];
            }
        }
    }
    return directionIndex;
};

const getElementBorderWidth = (elementStyle, el) => {
    let width = 0;
    if (elementStyle) {
        let borderWidth = elementStyle['border-width'];
        let border = elementStyle['border'];
        if (el && el.style) {
            borderWidth = el.style['border-width'];
        }
        if (borderWidth) {
            borderWidth.split(' ').forEach((v) => {
                //check selected border
                var number = pixelToNumber(v);
                if (number > 0) {
                    width = number;
                }
            });
            if (width > 0) {
                return width;
            }
        }
        if (border) {
            border = border.split(' ');
            if (border.length) {
                borderWidth = border[0];
                return pixelToNumber(borderWidth, width);
            }
        }
        return width;
    }
    return width;
};

const BORDER = {
    TOP: 0,
    RIGHT: 1,
    BOTTOM: 2,
    LEFT: 3,
    FULL: 4,
    DEFAULT_WIDTH: '1px 1px 1px 1px',
    DEFAULT_COLOR: 'rgba(0,0,0,0)',
    DEFAULT_STYLE: 'solid',
    STYLES: ['dotted', 'solid', 'dashed', 'double', 'groove', 'ridge', 'inset', 'outset', 'none'],
};

const getElementBorderStyle = (elementStyle, el) => {
    let style = BORDER.DEFAULT_STYLE;
    if (el && el.style) {
        return el.style['border-style'];
    } else if (elementStyle) {
        let borderStyle = elementStyle['border-style'];
        let border = elementStyle['border'];
        if (border) {
            border = border.split(' ');
            if (border.length >= 2) {
                //&& BLOCKS.BORDER.STYLES.includes(border[1]) (up)
                return border[1];
            }
        }
        if (borderStyle) {
            return borderStyle;
        }
    }
    return style;
};

const getElementFilterProps = (str) => {
    if (str && typeof str === 'string') {
        let filters = [
            'blur',
            'brightness',
            'contrast',
            'drop-shadow',
            'grayscale',
            'hue-rotate',
            'invert',
            'opacity',
            'saturate',
            'sepia',
            'url',
        ];
        let ry = [];
        filters.forEach((f, i) => {
            let oF = str.match(f);
            if (oF) {
                ry.push({ prop: oF[0], index: oF.index });
            }
        });
        let sortedry = ry.sort((a, b) => {
            return a.index - b.index;
        });
        let oFilters = {};
        for (let i = 0; i < sortedry.length; i++) {
            let sbstr =
                i + 1 < sortedry.length
                    ? str.substring(sortedry[i].index, sortedry[i + 1].index).trim()
                    : str.substring(sortedry[i].index).trim();
            let value = sbstr.substring(sbstr.indexOf('(') + 1, sbstr.length - 1);
            oFilters[sortedry[i].prop] = value;
        }
        return oFilters;
    }
    return {};
};

const isUserVerified = (user) => {
    return new Promise(function (res, rej) {
        if (user.emailVerified) {
            res();
        } else {
            user.sendEmailVerification()
                .then(function () {
                    rej({
                        error: 'UNVERIFIED_EMAIL',
                        message:
                            'Please verify your email address to continue using makestories. We just sent a mail to your email address : ',
                        email: user.email,
                    });
                })
                .catch(function () {
                    user.sendEmailVerification()
                        .then(function () {
                            rej({
                                error: 'UNVERIFIED_EMAIL',
                                message:
                                    'Please verify your email address to continue using makestories. We just sent a mail to your email address : ',
                                email: user.email,
                            });
                        })
                        .catch(function () {
                            // swal("Error","Some error occurred while sending email verification mail. Please check your email address : ("+user.email+") or try again in some time.","warning").then(val => {
                            rej({
                                error: 'OTHER_ERROR',
                                message:
                                    'Please verify your email address to continue using makestories. We just sent a mail to your email address : ',
                                email: user.email,
                            });
                            // })
                        });
                    // An error happened.
                });
        }
    });
};

const getBackgroundStyle = (background, origin) => {
    const { type = 'solid' } = background;
    let style = {};
    switch (type) {
        case 'solid':
            if (origin) {
                if (origin === 'outer-section') {
                    style['background-color'] = get(background, 'color', 'rgba(255, 255, 255, 1)');
                }
                if (origin === 'section') {
                    style['background-color'] = get(background, 'color', 'rgba(255, 255, 255, 0)');
                }
            }
            break;
        case 'image':
            // const placeholderImage =
            //     'https://mmstorageprod1.blob.core.windows.net/userdata/119e24f7-d87f-4827-b546-b60d0a05594d%2Ftemplates%2F1610367418838_Uploader%403x.png';
            // const src = get(background, 'src', placeholderImage);
            // style['background-image'] = `url(${src})`;
            // style['background-size'] = 'cover';
            // style['background-repeat'] = 'no-repeat';
            // style['background-color'] = '#fff';
            const placeholderImage =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==';
            const url = get(background, 'src', placeholderImage);
            //IMPORTANT: remove below code if link from the media library is fixed.
            const src = url.replaceAll(' ', '%20');
            style['background-image'] = `url(${src})`;
            style['background-size'] = 'cover';
            style['background-repeat'] = 'no-repeat';
            style['background-color'] = '#fff';
            style['background-position'] = '50% 50%';
            if (!get(background, 'src')) {
                delete style['background-size'];
                delete style['background-repeat'];
                delete style['background-position'];
            }
            break;
        case 'gradient':
            break;
    }
    return style;
};

const getNewBlock = (type) => {
    if (type && hasOwnProperty(blockOptions, 'blocks') && Array.isArray(blockOptions.blocks)) {
        const block = blockOptions.blocks.find((block) => block.type === type);
        if (block) {
            let newBlock = {
                id: generateId(),
                ...block,
            };
            return newBlock;
        } else {
            return null;
        }
    } else {
        return null;
    }
};

let ImageCache = {};

const verifyImageUrl = (url, details = {}, retries = 0) => {
    return new Promise((resolve, reject) => {
        if (!url) {
            resolve(false);
        }
        url.replace(
            new RegExp('cdn.storyasset.link', 'g'),
            'storage.googleapis.com/makestories-202705.appspot.com',
        );
        if (hasOwnProperty(ImageCache, url)) {
            resolve(ImageCache[url]);
        } else {
            let image = document.createElement('img');
            image.width = 0;
            image.height = 0;
            image.addEventListener('load', function () {
                let imageDetails = {
                    ...(typeof details === 'object' ? details : {}),
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                };
                ImageCache[url] = imageDetails;
                resolve(imageDetails);
            });
            image.addEventListener('error', () => {
                if (retries < 10) {
                    setTimeout(() => {
                        verifyImageUrl(url, details, ++retries)
                            .then(resolve)
                            .catch(resolve(false));
                    }, 1000);
                } else {
                    resolve(false);
                }
            });
            image.src = url;
        }
    });
};

const makeElement = (tag, content) => {
    if (tag) {
        const acceptedTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'li'];
        if (acceptedTags.includes(tag)) {
            return `<${tag}>${content}</${tag}>`;
        }
    }
    return `<p> Edit your text Here </p>`;
};

export {
    getIdToken,
    generateId,
    showNotification,
    hasOwnProperty,
    sanitizeStylesForReact,
    getBlock,
    getOuterSection,
    getSection,
    gradientStyling,
    cloneObject,
    rgbObjectToString,
    pixelToNumber,
    getElementBorderDirectionIndexes,
    getElementBorderWidth,
    getElementBorderStyle,
    getElementFilterProps,
    capitalizeString,
    isUserVerified,
    getBackgroundStyle,
    getNewBlock,
    verifyImageUrl,
    makeElement,
};
