import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import { store } from 'redux/store/store';
import { baseApiUrlMakestories } from 'app/constants/apiUrl';
import { getIdToken } from '_helpers';
import { waitForUserLoad } from 'redux/sagas/login.saga';
import { loadWorkSpaceList } from 'redux/actions/workSpaceAction';
import { loadWorkSpaceListData } from 'redux/features/workspace/workspaceSlice';

function* handleLoadWorkspaceList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const {
        currentUser: { uid },
    } = store.getState();
    const { callback } = action.payload;
    try {
        if (userToken && uid) {
            const response = yield axios(`${baseApiUrlMakestories}/team/list`, {
                params: {
                    token: userToken,
                    Id: uid,
                },
            });
            if (response.data && response.status == 200) {
                const data = response.data;
                yield put(loadWorkSpaceListData(data));
                callback();
            } else {
                console.log('response data is different as expected');
                callback();
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
        callback();
    }
}

function* workspaceSaga() {
    yield takeLatest(loadWorkSpaceList, handleLoadWorkspaceList);
}

export default workspaceSaga;
