import { createSlice } from '@reduxjs/toolkit';
import {
    updateTextContent,
    addBlockHelper,
    updateStyleHelper,
    updateTextElementHelper,
    updateImageUrlHelper,
    updateOuterSectionStyleHelper,
    updateSectionStyleHelper,
    duplicateOuterSectionHelper,
    moveOuterSectionHelper,
    updateCtaUrlHelper,
    removeBlockHelper,
    updateImageAltTagHelper,
    updateOuterSectionBackgroundHelper,
    updateSectionBackgroundHelper,
    removeSocialBlockHelper,
    addSocialBlockHelper,
    updateSocialBlockHelper,
    updateTitleHelper,
    updateFormHelper,
    moveBlockHelper,
    duplicateBlockHelper,
    pasteBlockHelper,
} from '_helpers/emailHelper';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

/*
const DEFAULT_PAGE = {
    id: generateId(),
    background: {
        type: 'color',
        color: 'rgb(255,255,255)',
    },
    layers: [
        {
            id: generateId(),
            type: 'vertical',
            elements: [
                {
                    id: generateId(),
                    type: 'text',
                    tag: 'p',
                    name: 'Your Page!',
                    content: 'Your Page!',
                    styling: {
                        position: 'absolute',
                        left: '30px',
                        top: '250px',
                        width: '300px',
                        'text-align': 'center',
                        color: 'rgb(51, 51, 51)',
                        'font-family': 'Roboto',
                        'font-weight': '500',
                        'font-size': '16px',
                        'line-height': '1.4em',
                    },
                    responsive: {
                        '600x360': {
                            left: '200px',
                        },
                    },
                },
            ],
        },
    ],
};

const DEFAULT_STORY = {
    owner: 'asd',
    name: 'New Story',
    onlineUsers: {},
    pages: [
        {
            ...DEFAULT_PAGE,
            id: generateId(),
        },
    ],
    thumbnail: false,
};
*/

/*
const DEFAULT_SECTION = {
    id: generateId(),
    background: {
        type: 'solid',
        color: 'rgba(0,0,0,0)',
    },
    blocks: [],
    styling: {
        padding: '10px',
    },
};

const DEFAULT_OUTER_SECTION = {
    id: generateId(),
    background: {
        type: 'solid',
        color: 'rgb(255,255,255)',
    },
    sections: [{ ...DEFAULT_SECTION }],
    styling: {
        padding: '10px',
    },
};
*/

const initialState = {
    id: false, //this will be updated from load Email action. id will be coming from backend.
    outerSections: [], //[{ ...DEFAULT_OUTER_SECTION }],
    title: 'New Template',
};

const emailEditorSlice = createSlice({
    name: 'emailEditor',
    initialState: initialState,
    reducers: {
        templateLoaded(state, action) {
            // add condition
            if (typeof action.payload == 'object') {
                return action.payload;
            }
            return state;
        },
        addOuterSection(state, action) {
            const {
                outerSection = {},
                activeOuterSectionId = false,
                selectedButton = false,
                position = false,
            } = action.payload;
            if (activeOuterSectionId && Array.isArray(state.outerSections)) {
                if (activeOuterSectionId == 'qshyudn_wert') {
                    if (position === 'top') {
                        state.outerSections.splice(0, 0, outerSection);
                    }
                    if (position === 'bottom') {
                        state.outerSections.push(outerSection);
                    }
                    return;
                }
            }
            let outerSectionIndex = state.outerSections.findIndex(
                (outerSection) => outerSection.id === activeOuterSectionId,
            );
            if (selectedButton === 'next') {
                outerSectionIndex++;
            }
            if (outerSectionIndex !== -1) {
                state.outerSections.splice(outerSectionIndex, 0, outerSection);
            }
        },
        removeOuterSection(state, action) {
            const { outerSectionId } = action.payload;
            let outerSectionIndex = state.outerSections.findIndex(
                (outerSection) => outerSection.id === outerSectionId,
            );
            if (outerSectionIndex !== -1 && state.outerSections.length > 0) {
                state.outerSections.splice(outerSectionIndex, 1);
            }
        },
        updateText(state, action) {
            updateTextContent(state, action.payload);
        },
        addBlock(state, action) {
            addBlockHelper(state, action.payload);
        },
        updateStyle(state, action) {
            updateStyleHelper(state, action.payload);
        },
        updateTextElement(state, action) {
            updateTextElementHelper(state, action.payload);
        },
        updateImageUrl(state, action) {
            updateImageUrlHelper(state, action.payload);
        },
        updateOuterSectionStyle(state, action) {
            updateOuterSectionStyleHelper(state, action.payload);
        },
        updateSectionStyle(state, action) {
            updateSectionStyleHelper(state, action.payload);
        },
        duplicateOuterSection(state, action) {
            duplicateOuterSectionHelper(state, action.payload);
        },
        moveOuterSection(state, action) {
            moveOuterSectionHelper(state, action.payload);
        },
        updateCtaUrl(state, action) {
            updateCtaUrlHelper(state, action.payload);
        },
        removeBlock(state, action) {
            removeBlockHelper(state, action.payload);
        },
        updateImageAltTag(state, action) {
            updateImageAltTagHelper(state, action.payload);
        },
        updateOuterSectionBackground(state, action) {
            updateOuterSectionBackgroundHelper(state, action.payload);
        },
        updateSectionBackground(state, action) {
            updateSectionBackgroundHelper(state, action.payload);
        },
        removeSocialBlock(state, action) {
            removeSocialBlockHelper(state, action.payload);
        },
        addSocialBlock(state, action) {
            addSocialBlockHelper(state, action.payload);
        },
        updateSocialBlock(state, action) {
            updateSocialBlockHelper(state, action.payload);
        },
        updateTitle(state, action) {
            updateTitleHelper(state, action.payload);
        },
        updateForm(state, action) {
            updateFormHelper(state, action.payload);
        },
        moveBlock(state, action) {
            moveBlockHelper(state, action.payload);
        },
        duplicateBlock(state, action) {
            duplicateBlockHelper(state, action.payload);
        },
        pasteBlock(state, action) {
            pasteBlockHelper(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(closeTemplate, () => {
            return initialState;
        });
    },
});

export const {
    templateLoaded,
    addOuterSection,
    removeOuterSection,
    updateText,
    addBlock,
    updateStyle,
    updateTextElement,
    updateImageUrl,
    updateOuterSectionStyle,
    updateSectionStyle,
    duplicateOuterSection,
    moveOuterSection,
    updateCtaUrl,
    removeBlock,
    updateImageAltTag,
    updateOuterSectionBackground,
    updateSectionBackground,
    removeSocialBlock,
    addSocialBlock,
    updateSocialBlock,
    updateTitle,
    updateForm,
    moveBlock,
    duplicateBlock,
    pasteBlock,
} = emailEditorSlice.actions;
//Note: whatever you are adding in actions, also update it in undoConfig.

export default emailEditorSlice.reducer;

export const selectEmailEditor = (state) => state.emailEditor.present;

export const selectPastEmailEditor = (state) => state.emailEditor.past;

export const selectFutureEmailEditor = (state) => state.emailEditor.future;
