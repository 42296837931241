import React from 'react';
import { SocialBtn } from 'app/Pages/Login/loginCommonStyle';
import { useDispatch } from 'react-redux';
import { AppleIcon } from 'app/constants/icons';
import { LOGIN_PAGE } from 'app/language/en.lang';
import { generateId } from '_helpers';
import { signinWithApple } from 'app/services/auth.service';
import { login } from 'redux/actions/loginAction';

const { SIGN_IN_WITH_APPLE } = LOGIN_PAGE;

function SigninWithAppleC(props) {
    const dispatch = useDispatch();

    const signInWithApple = () => {
        let generatedId = generateId();
        signinWithApple()
            .then((data) => {
                console.log(data, 'data-google');
                dispatch(login({ user: data.user }));
                localStorage.setItem('user', data.user.uid);
                localStorage.setItem('generatedId', generatedId);
            })
            .catch((error) => {
                console.log(error, 'google-error');
                let errorMessage = error.message;
                props.parentCallback(errorMessage);
            });
    };

    return (
        <SocialBtn type="button" onClick={signInWithApple}>
            <AppleIcon />
            {SIGN_IN_WITH_APPLE}
        </SocialBtn>
    );
}

export default SigninWithAppleC;
