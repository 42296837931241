import { createAction } from '@reduxjs/toolkit';
import { CONTACT_LIST } from '../../app/constants/reduxActionConstant';

const loadContactList = createAction(CONTACT_LIST.LOAD_CONTACT_LIST);

const loadSingleContact = createAction(CONTACT_LIST.LOAD_SINGLE_CONTACT_LIST);

const loadMoreContactList = createAction(CONTACT_LIST.LOAD_MORE_CONTACT_LIST);

const loadSegmentsList = createAction(CONTACT_LIST.LOAD_SEGMENTS_LIST);

const searchContactList = createAction(CONTACT_LIST.SEARCH_CONTACT_LIST);

const loadDeletedList = createAction(CONTACT_LIST.LOAD_DELETED_LIST);

const createContactList = createAction(CONTACT_LIST.CREATE_CONTACT_LIST);

const softDelContactList = createAction(CONTACT_LIST.SOFT_DELETE_CONTACT_LIST);

const deleteContactList = createAction(CONTACT_LIST.DELETE_CONTACT_LIST);

const updateContactList = createAction(CONTACT_LIST.UPDATE_CONTACT_LIST);

const restoreContactList = createAction(CONTACT_LIST.RESTORE_CONTACT_LIST);

const loadSingleContactList = createAction(CONTACT_LIST.LOAD_SINGLE_CONTACT_TYPE);

export {
    loadContactList,
    loadSingleContact,
    loadMoreContactList,
    loadSegmentsList,
    searchContactList,
    loadDeletedList,
    createContactList,
    deleteContactList,
    softDelContactList,
    updateContactList,
    restoreContactList,
    loadSingleContactList,
};
