import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { PROFILE_SETTINGS } from 'app/language/en.lang';
import { DownArrowIcon } from 'app/constants/icons';
import { currentUserState } from 'redux/features/app_login/loginSlice';
import Logout from 'app/Pages/Logout';

const { PROFILE } = PROFILE_SETTINGS;

function UserProfile() {
    const currentUser = useSelector(currentUserState);
    const { displayName, email, photoURL } = currentUser;

    const gmailProfileImg = `https://ui-avatars.com/api/?length=1&name=${displayName || email}`;
    const profileImg = photoURL ? photoURL : gmailProfileImg;

    return (
        <Uyp
            className="uyp"
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Popover
                overlayClassName="uypout"
                content={
                    <ul className="uypin">
                        <li>
                            <Link to="/profile">{PROFILE}</Link>
                        </li>
                        <li>
                            <Logout />
                        </li>
                    </ul>
                }
                placement="bottomRight"
                trigger="click"
            >
                <Avatar src={profileImg} />
                <span>
                    <DownArrowIcon />
                </span>
            </Popover>
        </Uyp>
    );
}

export default UserProfile;

// Styled components
const Uyp = styled.div`
    border-radius: 3.125rem;
    margin-left: 1.25rem;
`;
