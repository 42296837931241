import arrayMove from 'array-move';
import { hasOwnProperty, generateId } from '_helpers';

const getActiveOuterSection = (object, activeOuterSectionId = false) => {
    const { outerSections } = object;
    if (Array.isArray(outerSections) && activeOuterSectionId) {
        const index = outerSections.findIndex(
            (outerSection) => activeOuterSectionId === outerSection.id,
        );
        if (index > -1) {
            return outerSections[index];
        }
    }
    return false;
};

const getActiveSection = (object, activeSectionId = false, activeOuterSectionId = false) => {
    if (activeSectionId && activeOuterSectionId) {
        const { sections } = getActiveOuterSection(object, activeOuterSectionId);
        if (Array.isArray(sections)) {
            const index = sections.findIndex((section) => activeSectionId === section.id);
            if (index > -1) {
                return sections[index];
            }
        }
        return false;
    }
    return false;
};

const getActiveBlock = (
    object,
    activeOuterSectionId = false,
    activeSectionId = false,
    activeBlockId = false,
) => {
    if (activeOuterSectionId && activeSectionId && activeBlockId) {
        const { blocks } = getActiveSection(object, activeSectionId, activeOuterSectionId);
        if (Array.isArray(blocks)) {
            const index = blocks.findIndex((block) => activeBlockId === block.id);
            if (index > -1) {
                return blocks[index];
            }
        }
        return false;
    }
    return false;
};

const updateTextContent = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, updatedText },
) => {
    /*
    let outerSectionIndex = object.outerSections.findIndex(
        (outerSection) => outerSection.id === activeOuterSectionId,
    );
    let sectionIndex = object.outerSections[outerSectionIndex].sections.findIndex(
        (section) => section.id === activeSectionId,
    );
    let blockIndex = object.outerSections[outerSectionIndex].sections[
        sectionIndex
    ].blocks.findIndex((block) => block.id === activeBlockId);
    object.outerSections[outerSectionIndex].sections[sectionIndex].blocks[
        blockIndex
    ].content = updatedText;
    */
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block) {
        block.content = updatedText;
    }
};

const addBlockHelper = (
    object,
    { activeOuterSectionId, activeSectionId, newBlock = {}, blockId = false, direction = false },
) => {
    const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
    if (section && hasOwnProperty(section, 'blocks') && Array.isArray(section.blocks)) {
        if (blockId) {
            let blockIndex = section.blocks.findIndex((block) => block.id === blockId);
            if (blockIndex > -1) {
                if (direction === 'upwards') {
                    section.blocks.splice(blockIndex, 0, newBlock);
                }
                if (direction === 'downwards') {
                    section.blocks.splice(blockIndex + 1, 0, newBlock);
                }
            }
        } else {
            section.blocks.push(newBlock);
        }
    }
};

/*
// from story
const updateStyle = (object,{elementId,pageId,prop,to}) => {
    console.log({prop, to});
    if(!hasOwnProperty(object, "pages")){
        object.pages = [];
    }
    let pageIndex = object.pages.findIndex(page => page.id === pageId);
    if( pageIndex !== -1 ){
        let updatedStyle = {};
        if(to ){
            let toggleStyles = {
                "font-weight": ["400", "700"],
                "font-style": ["normal", "italic"],
                "text-decoration": ["none", "underline"],
                "text-transform":["none","uppercase"],
            };
            let state={
                "story":{
                    ...object
                },
                "activePage": pageIndex

            }
            if(to === "toggle" && hasOwnProperty(toggleStyles,prop)){
                let element=getElementById(elementId, {}, false, state);
                let elementStyle=getElementStyle(element)
               if(elementStyle){
                   let styleIndex = elementStyle[prop] ? toggleStyles[prop].indexOf(elementStyle[prop]) : 0;
                   styleIndex++;
                   if(styleIndex >= toggleStyles[prop].length){
                       styleIndex = 0;
                   }
                   updatedStyle[prop] = { $set:toggleStyles[prop][styleIndex] };
               }
            }else{
                updatedStyle[prop] = { $set:to };
            }
        }
        return updateStyling(object, elementId, pageId, updatedStyle);
    }
    return object;
};
*/

const updateStyleHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, prop, updatedValue },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block) {
        let toggleStyles = {
            'font-weight': ['400', '700'],
            'font-style': ['normal', 'italic'],
            'text-decoration': ['none', 'underline'],
            'text-transform': ['none', 'uppercase'],
        };
        if (updatedValue === 'toggle' && hasOwnProperty(toggleStyles, prop)) {
            let blockStyle = block.styling;
            if (blockStyle) {
                let styleIndex = blockStyle[prop]
                    ? toggleStyles[prop].indexOf(blockStyle[prop])
                    : 0;
                styleIndex++;
                if (styleIndex >= toggleStyles[prop].length) {
                    styleIndex = 0;
                }
                block.styling[prop] = toggleStyles[prop][styleIndex];
            }
        } else {
            block.styling[prop] = updatedValue;
        }
    }
};

const updateTextElementHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, updatedElement },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block) {
        block.element = updatedElement;
    }
};

const updateImageUrlHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, updatedUrl = '' },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block) {
        block.url = updatedUrl;
    }
};

const updateOuterSectionStyleHelper = (object, { activeOuterSectionId, prop, updatedValue }) => {
    const outerSection = getActiveOuterSection(object, activeOuterSectionId);
    if (outerSection) {
        if (!hasOwnProperty(outerSection, 'styling')) {
            outerSection.styling = {};
        }
        outerSection.styling[prop] = updatedValue;
    }
};

const updateSectionStyleHelper = (
    object,
    { activeSectionId, activeOuterSectionId, prop, updatedValue },
) => {
    const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
    if (section) {
        if (!hasOwnProperty(section, 'styling')) {
            section.styling = {};
        }
        section.styling[prop] = updatedValue;
    }
};

const duplicateOuterSectionHelper = (object, { outerSectionId }) => {
    const outerSection = getActiveOuterSection(object, outerSectionId);
    if (outerSection) {
        // copying the element
        let newOuterSection = JSON.parse(JSON.stringify(outerSection));

        if (newOuterSection) {
            // updating the element with different Id
            newOuterSection.id = generateId();
            if (Array.isArray(newOuterSection.sections)) {
                newOuterSection.sections.map((section) => {
                    section.id = generateId();
                    if (Array.isArray(section.blocks)) {
                        section.blocks.map((block) => (block.id = generateId()));
                    }
                });
            }
        }

        //pushing the new data
        let outerSectionIndex = object.outerSections.findIndex(
            (outerSection) => outerSection.id === outerSectionId,
        );
        outerSectionIndex++;
        object.outerSections.splice(outerSectionIndex, 0, newOuterSection);
    }
};

const moveOuterSectionHelper = (object, { outerSectionId, moveDirection }) => {
    //array check
    let outerSectionIndex = object.outerSections.findIndex(
        (outerSection) => outerSection.id === outerSectionId,
    );
    let newOuterSectionIndex;
    if (moveDirection === 'up') {
        newOuterSectionIndex = outerSectionIndex--;
    }
    if (moveDirection === 'down') {
        newOuterSectionIndex = outerSectionIndex++;
    }
    const newArray = arrayMove(object.outerSections, outerSectionIndex, newOuterSectionIndex);
    object.outerSections = newArray;
};

const updateCtaUrlHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, updatedUrl = '' },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block) {
        block.url = updatedUrl;
    }
};

const removeBlockHelper = (object, { activeOuterSectionId, activeSectionId, activeBlockId }) => {
    const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
    if (section && hasOwnProperty(section, 'blocks') && Array.isArray(section.blocks)) {
        let blockIndex = section.blocks.findIndex((block) => block.id === activeBlockId);
        if (blockIndex !== -1) {
            section.blocks.splice(blockIndex, 1);
        }
    }
};

const updateImageAltTagHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, updatedText = '' },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block) {
        block.altTag = updatedText;
    }
};

const updateOuterSectionBackgroundHelper = (object, { activeOuterSectionId, updatedValue }) => {
    const outerSection = getActiveOuterSection(object, activeOuterSectionId);
    if (outerSection) {
        outerSection.background = updatedValue;
    }
};

const updateSectionBackgroundHelper = (
    object,
    { activeSectionId, activeOuterSectionId, updatedValue },
) => {
    const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
    if (section) {
        section.background = updatedValue;
    }
};

const removeSocialBlockHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, socialBlockId },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block && hasOwnProperty(block, 'socialBlocks') && Array.isArray(block.socialBlocks)) {
        let blockIndex = block.socialBlocks.findIndex((block) => block.id === socialBlockId);
        if (blockIndex !== -1) {
            block.socialBlocks.splice(blockIndex, 1);
        }
    }
};

const addSocialBlockHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, newSocialBlock },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block && hasOwnProperty(block, 'socialBlocks') && Array.isArray(block.socialBlocks)) {
        block.socialBlocks.push(newSocialBlock);
    }
};

const updateSocialBlockHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, socialBlockId, prop, value },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (block && hasOwnProperty(block, 'socialBlocks') && Array.isArray(block.socialBlocks)) {
        let blockIndex = block.socialBlocks.findIndex((block) => block.id === socialBlockId);
        if (blockIndex !== -1) {
            block.socialBlocks[blockIndex][prop] = value;
        }
    }
};

const updateTitleHelper = (object, { updatedTitle }) => {
    object.title = updatedTitle;
};

const updateFormHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, formData, formSettings },
) => {
    const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
    if (formData && formSettings) {
        if (block && hasOwnProperty(block, 'formData') && hasOwnProperty(block, 'formSettings')) {
            block.formData = formData;
            block.formSettings = formSettings;
        }
    }
};

const moveBlockHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, moveDirection },
) => {
    if (activeOuterSectionId && activeSectionId && activeBlockId) {
        const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
        if (hasOwnProperty(section, 'blocks') && Array.isArray(section.blocks)) {
            let blockIndex = section.blocks.findIndex((block) => block.id === activeBlockId);
            if (blockIndex !== -1) {
                let newBlockIndex;
                if (moveDirection === 'up') {
                    newBlockIndex = blockIndex--;
                }
                if (moveDirection === 'down') {
                    newBlockIndex = blockIndex++;
                }
                const newArray = arrayMove(section.blocks, blockIndex, newBlockIndex);
                section.blocks = newArray;
            }
        }
    }
};

const duplicateBlockHelper = (object, { activeOuterSectionId, activeSectionId, activeBlockId }) => {
    if (activeBlockId && activeSectionId && activeOuterSectionId) {
        const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
        const block = getActiveBlock(object, activeOuterSectionId, activeSectionId, activeBlockId);
        if (section && block) {
            let newBlock = JSON.parse(JSON.stringify(block));
            if (newBlock) {
                newBlock.id = generateId();
                if (hasOwnProperty(section, 'blocks') && Array.isArray(section.blocks)) {
                    let blockIndex = section.blocks.findIndex(
                        (block) => block.id === activeBlockId,
                    );
                    if (blockIndex !== -1) {
                        section.blocks.splice(blockIndex + 1, 0, newBlock);
                    }
                }
            }
        }
    }
};

const pasteBlockHelper = (
    object,
    { activeOuterSectionId, activeSectionId, activeBlockId, copiedBlock, from = false },
) => {
    if (activeOuterSectionId && activeSectionId && copiedBlock) {
        const section = getActiveSection(object, activeSectionId, activeOuterSectionId);
        if (section && hasOwnProperty(section, 'blocks') && Array.isArray(section.blocks)) {
            const newBlock = { ...copiedBlock };
            newBlock.id = generateId();
            if (from === 'section_context_menu') {
                section.blocks.push(newBlock);
                return;
            }
            if (activeBlockId) {
                let index = section.blocks.findIndex((block) => block.id === activeBlockId);
                if (index !== -1) {
                    section.blocks.splice(index + 1, 0, newBlock);
                }
            }
        }
    }
};

export {
    updateTextContent,
    addBlockHelper,
    updateStyleHelper,
    updateTextElementHelper,
    updateImageUrlHelper,
    updateOuterSectionStyleHelper,
    updateSectionStyleHelper,
    duplicateOuterSectionHelper,
    moveOuterSectionHelper,
    updateCtaUrlHelper,
    removeBlockHelper,
    updateImageAltTagHelper,
    updateOuterSectionBackgroundHelper,
    updateSectionBackgroundHelper,
    removeSocialBlockHelper,
    addSocialBlockHelper,
    updateSocialBlockHelper,
    updateTitleHelper,
    updateFormHelper,
    moveBlockHelper,
    duplicateBlockHelper,
    pasteBlockHelper,
};
