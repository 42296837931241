import React, { useRef } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeRouter from './HomeRouter';
import PageNotFound from 'app/Pages/PageNotFound';
import { CreateNewEmail, EmailEditor } from 'app/Pages';
import MediaPortal from '_helpers/MediaPortal';
import { MediaMainArea, WithFileDropzone } from 'media-library';
import { showMediaLibrary } from 'redux/features/media_library/mediaLibrary.slice';
import AddContact from 'app/Pages/ContactList/AddContact';

const ProtectedRoutes = () => {
    const portalRef = useRef(null);
    const mode = 'all';

    const newLibrary = useSelector(showMediaLibrary);

    let classList = [];

    classList.push('media-portal-div');
    classList.push('outside-editor');

    return (
        <div>
            <Router>
                <Switch>
                    <Route path={'/email/new'} component={CreateNewEmail} />
                    <Route path={'/email/edit/:id'} component={EmailEditor} />
                    <Route path={'/contact/add-contact'} component={AddContact} />
                    <Route path={'/'} component={HomeRouter} />
                    <Route component={PageNotFound} />
                </Switch>
            </Router>
            <MediaPortal portalRoot={portalRef}>
                {newLibrary ? (
                    <div>
                        <MediaMainArea mode={mode} className={classList.join(' ')} />
                    </div>
                ) : null}
            </MediaPortal>
            <div id={'media-portal'} ref={portalRef} />
        </div>
    );
};

export default ProtectedRoutes;
