import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Workspacewrapper, WhiteBlock } from './Styles';
import { Avatar } from 'make-ui-v2';
import { changeWorkspaceId } from 'redux/features/workspace/workspaceSlice';
import * as STRINGS from 'app/language/en.lang';

const { BACK_TO_DASHBOARD, CREATE_WORKSPACE, CREATE, GET_STARTED, ALL_WORKSPACE } =
    STRINGS.WORKSPACE_PAGE;

const Workspace = () => {
    let { t } = useTranslation('translation');
    let dispatch = useDispatch();
    const history = useHistory();

    const workSpaceList = useSelector((state) => state?.workSpaceReducer?.workSpaceList ?? []);
    const workspaceId = useSelector((state) => state?.workSpaceReducer?.workspaceId ?? '');

    const setActiveWorkspace = ({ activeWorkSpace, dontRedirect = false, to = '/' }) => {
        if (activeWorkSpace !== workspaceId) {
            console.log('active');
            dispatch(changeWorkspaceId(activeWorkSpace));

            // redirect home route
            if (history.location.pathname !== '/' && !dontRedirect) history.push(to);
        }
    };
    return (
        <Workspacewrapper>
            <div className="db-area-main">
                <div className="db-container">
                    <Link to="/" className="db-back-link button-chevron-left">
                        {t(BACK_TO_DASHBOARD)}
                    </Link>
                    <WhiteBlock style={{ marginBottom: 60 }}>
                        <div className="create-workspace padding-30">
                            <div className="workspace-title-row">
                                <div className="workspace-text-block">
                                    <h1>{t(CREATE_WORKSPACE)}</h1>
                                    <p>{t(GET_STARTED)}</p>
                                </div>
                                <div className="workspace-btn-block">
                                    <div>
                                        <Link
                                            to="/create-workspace"
                                            className="button margin-top-responsive"
                                        >
                                            {t(CREATE)}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WhiteBlock>
                    <div className="flex-row">
                        <div className="col col-6">
                            <h2 className="db-heading">{t(ALL_WORKSPACE)}</h2>
                        </div>
                    </div>
                    <div className="workspace-cards-container">
                        {Array.isArray(workSpaceList) &&
                            workSpaceList?.map((workspace) => (
                                <button
                                    key={workspace.id}
                                    type="button"
                                    className="single-workspace-tile"
                                    onClick={() => {
                                        setActiveWorkspace({ activeWorkSpace: workspace.id });
                                    }}
                                >
                                    <WhiteBlock className="padding-30">
                                        <div className="workspace-logo">
                                            <Avatar
                                                img={workspace.logo !== '0' ? workspace.logo : ''}
                                                initials={workspace.name.charAt(0)}
                                            />
                                        </div>
                                        <div className="worksapce-desc">
                                            <h4>{workspace.name}</h4>

                                            <div className="workspace-people">
                                                {Array.isArray(workspace.teamMembers) &&
                                                    workspace.teamMembers
                                                        ?.slice(0, 4)
                                                        .map((member) => (
                                                            <div
                                                                key={member.member_id}
                                                                className="single-person"
                                                                style={{ marginLeft: '-13px' }}
                                                            >
                                                                <span className="person-photo">
                                                                    <Link
                                                                        to="/members"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setActiveWorkspace({
                                                                                activeWorkSpace:
                                                                                    workspace.id,
                                                                                dontRedirect: true,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            img={
                                                                                member.photo_url
                                                                                    ? member.photo_url
                                                                                    : ''
                                                                            }
                                                                            initials={member.name}
                                                                        />
                                                                    </Link>
                                                                </span>
                                                                <span className="person-name">
                                                                    {member.name || 'User'}
                                                                </span>
                                                            </div>
                                                        ))}
                                                {Array.isArray(workspace.teamMembers) &&
                                                    workspace.teamMembers?.length > 4 && (
                                                        <Link
                                                            to="/members"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setActiveWorkspace({
                                                                    activeWorkSpace: workspace.id,
                                                                    dontRedirect: true,
                                                                });
                                                            }}
                                                            className="total-persons"
                                                        >
                                                            +
                                                            {Array.isArray(workspace.teamMembers) &&
                                                                workspace.teamMembers.length - 4}
                                                        </Link>
                                                    )}
                                            </div>
                                        </div>
                                    </WhiteBlock>
                                </button>
                            ))}
                    </div>
                </div>
            </div>
        </Workspacewrapper>
    );
};

export default Workspace;
