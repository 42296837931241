import React from 'react';
import styled from 'styled-components';

export const FormWrapper = styled.div`
    margin-right: 7.5rem;
    width: 24.5625rem;
    background: #ffffff;
    text-align: center;
    border-radius: 0.3125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const FormMainIn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1.875rem;
`;

export const Heading = styled.h4`
    font-size: 1.125rem;
    color: #202124;
    font-weight: 700;
    display: flex;
    padding-bottom: 0.625rem;
    justify-content: space-between;
`;

export const Paragraph = styled.p`
    color: #757575;
    font-size: 0.8rem;
    padding-bottom: 1.875rem;
    word-spacing: -0.125rem;
`;

export const LoginSeprator = styled.div`
    position: relative;
    text-align: center;
    display: block;
`;

export const SocialBtn = styled.button`
    cursor: pointer;
    position: relative;
    padding: 0;
    height: 36px;
    line-height: 36px;
    border: none;
    background: #fff;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: 0px 3px 6px #22222214;
    border-radius: 5px;
    display: block;
    width: 100%;
    margin: 10px 0;

    & > svg {
        width: 12px;
        margin-right: 5px;
        position: absolute;
        top: 8px;
        left: 6px;
        width: 18px;
    }
`;

export const LoginSocial = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1.875rem 0;
    flex-wrap: wrap;
`;

export const LoginSeparator = styled.div`
    position: relative;
    text-align: center;
    display: block;

    &:before {
        position: absolute;
        top: 0.5rem;
        left: 0;
        content: '';
        width: 100%;
        height: 0.0625rem;
        background: #f2f2f2;
    }
`;

export const LoginSeparatorIn = styled.span`
    background: #ffffff;
    padding: 0 1.25rem;
    display: inline-block;
    position: relative;
    font-size: 0.6875rem;
    color: #2a2930;
    letter-spacing: 0.125rem;
    font-weight: 700;
`;

export const DualLinks = styled.div`
    display: flex;
    height: 2.125rem;
    margin: 1.5625rem 0;
    align-items: center;
    justify-content: space-between;
`;

export const InputTag = styled.input`
    display: block;
    width: 100%;
    color: #9f9ea2;
    cursor: auto;
    font-size: 0.8rem;
    height: 1.875rem;
    border: 0.0625rem solid #f2f2f2;
    border-radius: 0.3125rem;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    background-color: #ffffff;
    padding: 0 1rem;
    margin-bottom: 1rem;
    line-height: 1.875rem;
`;

export const ButtonTag = styled.button`
    height: 2.125rem;
    display: block;
    font-size: 0.6875rem;
    color: #ffffff;
    background: #1657cd;
    outline: none;
    border: none;
    border-radius: 0.25rem;
    letter-spacing: 0.125rem;
    cursor: pointer;
    padding: 0 1rem;
    text-transform: uppercase;
    word-spacing: -0.125rem;
`;

export const LabelTag = styled.label`
    font-size: 0.8rem;
    color: #2a2930;
    margin-bottom: 0.6875rem;
    display: block;
`;

export const ErrorMsg = styled.p`
    font-size: 0.875rem;
    line-height: 18px;
    color: #cd1616;
`;

export const VerifyEmail = styled.p`
    font-size: 0.875rem;
    line-height: 18px;
    color: #04b023;
`;

export const BackButtonWrap = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
`;

export const BackButton = styled.button`
    background: #fff;
    border: none;
    padding: 0;
    font-size: 0.875rem;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #757575;
    cursor: pointer;

    & > svg {
        width: 6px;
        margin-right: 8px;
        display: inline-block;
        stroke: #757575;
        fill: none;
    }
`;
