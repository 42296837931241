import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { saveEmail } from 'redux/actions/emailAction';
import { selectEditorSettings } from 'redux/features/email_editor/editorSettingsSlice';
import * as STRINGS from 'app/language/en.lang';
import {
    selectPastEmailEditor,
    selectFutureEmailEditor,
} from 'redux/features/email_editor/emailEditorSlice';
import { AllSaved } from 'app/constants/icons';

const SaveTemplate = () => {
    const { savingTemplate } = useSelector(selectEditorSettings);
    const pastEmailEditor = useSelector(selectPastEmailEditor);
    const futureEmailEditor = useSelector(selectFutureEmailEditor);
    const [isSaved, setIsSaved] = useState(false);
    const dispatch = useDispatch();
    const [t] = useTranslation('translation');
    const handleSave = () => {
        dispatch(
            saveEmail({
                saveTemplateCb: () => {
                    setIsSaved(true);
                },
            }),
        );
    };

    useEffect(() => {
        const canUndo = pastEmailEditor.length > 0;
        const canRedo = futureEmailEditor.length > 0;
        if ((canUndo || canRedo) && isSaved) {
            setIsSaved(false);
        }
    }, [pastEmailEditor, futureEmailEditor]);

    if (isSaved) {
        return (
            <SavedStatus>
                <span className="icon">
                    <AllSaved />
                </span>
                {t(STRINGS.EDITOR.SAVED)}
            </SavedStatus>
        );
    }
    return (
        <>
            {savingTemplate ? (
                <SavedStatus>{t(STRINGS.EDITOR.SAVING) + '...'}</SavedStatus>
            ) : (
                <UnsavedBtn onClick={handleSave}>{t(STRINGS.EDITOR.UN_SAVE)}</UnsavedBtn>
            )}
        </>
    );
};

export default SaveTemplate;

const SavedStatus = styled.h4`
    padding: 0px 15px;
    font-size: 15px;
    font-family: 'Circular Std Book';
    color: #202124;

    .icon {
        display: inline-block;
        margin-right: 5px;

        svg {
            width: 14px;
            height: 14px;
        }
    }
`;

const UnsavedBtn = styled.button`
    border: none;
    background: none;
    padding: 0px 15px;
    font-size: 15px;
    font-family: 'Circular Std Book';
    color: #202124;
    cursor: pointer;
`;
