import React from 'react';
import './App.css';
import { useSelector } from 'react-redux';
import Routes from '../../router';
import { showMediaLibrary } from 'redux/features/media_library/mediaLibrary.slice';

const App = () => {
    const displayMediaLibrary = useSelector(showMediaLibrary);

    return (
        <div className={displayMediaLibrary ? 'App' : ''}>
            <Routes />
        </div>
    );
};

export default App;
