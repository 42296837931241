import { createSlice } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { generateId } from '_helpers';

const getCurrentUser = () => localStorage.getItem('user') || false;
const INITIAL_AUTH_STATE = getCurrentUser() ? true : false;

const isAuthenticated = createSlice({
    name: 'isAuthenticated',
    initialState: INITIAL_AUTH_STATE,
    reducers: {
        loginSuccess() {
            return true;
        },
        logout() {
            localforage.getItem('persist:root').then((val) => {
                if (val) {
                    localforage.clear();
                }
            });
            return false;
        },
    },
});

export const { loginSuccess, logout } = isAuthenticated.actions;

export default isAuthenticated.reducer;

// with Redux Thunk
/*
export const loginUser = (user) => (dispatch) => {
    console.log(user, 'ACTION');
    if (user) {
        dispatch(loginSuccess());
    } else {
        dispatch(logout());
    }
};
*/

export const isUserAuthenticated = (state) => state.isAuthenticated;

const INITIAL_CURRENT_USER = getCurrentUser();

const currentUser = createSlice({
    name: 'currenUser',
    initialState: INITIAL_CURRENT_USER,
    extraReducers: (builder) => {
        builder
            .addCase(loginSuccess, (state, action) => {
                // return action.payload;
                const { uid, displayName, email, photoURL, phoneNumber, emailVerified } =
                    action.payload;
                return {
                    uid,
                    displayName,
                    email,
                    photoURL,
                    phoneNumber,
                    emailVerified,
                };
            })
            .addCase(logout, () => {
                return false;
            });
    },
});

export const { currentUserAction } = currentUser.actions;
export const currentUserReducer = currentUser.reducer;

export const currentUserState = (state) => state.currentUser;

const currentUserIdSlice = createSlice({
    name: 'currentUserId',
    initialState: INITIAL_CURRENT_USER,
    extraReducers: (builder) => {
        let generatedId = localStorage.getItem('generatedId') || generateId();
        builder
            .addCase(loginSuccess, (state, action) => {
                const { uid } = action.payload;
                localStorage.setItem('user', uid || false);
                localStorage.setItem('generatedId', generatedId);
                return uid || false;
            })
            .addCase(logout, () => {
                localStorage.clear();
                return false;
            });
    },
});

export const currentUserIdReducer = currentUserIdSlice.reducer;
