import * as STRINGS from './en.lang.js';
import { german } from './de.lang.js';
import { spanish } from './es.lang.js';
import { italian } from './it.lang.js';
import { french } from './fr.lang.js';
import { russian } from './ru.lang.js';

export default {
    en: {
        translation: { ...STRINGS },
    },
    de: {
        translation: { ...german },
    },
    es: {
        translation: { ...spanish },
    },
    it: {
        translation: { ...italian },
    },
    fr: {
        translation: { ...french },
    },
    ru: {
        translation: { ...russian },
    },
};
