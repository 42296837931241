const AppleIcon = () => (
    <svg viewBox="0 0 512 512">
        <path d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734   c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z" />
        <path d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z" />
    </svg>
);

const GoogleIcon = () => (
    <svg viewBox="0 0 23.666 23.666">
        <g id="Google-Button" transform="translate(-17.463 -17.463)">
            <g id="btn_google_dark_pressed" transform="translate(3 3)">
                <g id="logo_googleg_48dp" transform="translate(14.463 14.463)">
                    <path
                        id="Shape"
                        d="M20.36,9.784a13.6,13.6,0,0,0-.215-2.42H9v4.577h6.368a5.444,5.444,0,0,1-2.361,3.571v2.969h3.824a11.541,11.541,0,0,0,3.528-8.7Z"
                        transform="translate(2.833 2.318)"
                        fill="#4285f4"
                        fillRule="evenodd"
                    />
                    <path
                        id="Shape-2"
                        data-name="Shape"
                        d="M11.532,20.295a11.3,11.3,0,0,0,7.831-2.867l-3.824-2.969A7.139,7.139,0,0,1,4.911,10.71H.957v3.066a11.829,11.829,0,0,0,10.574,6.519Z"
                        transform="translate(0.301 3.371)"
                        fill="#34a853"
                        fillRule="evenodd"
                    />
                    <path
                        id="Shape-3"
                        data-name="Shape"
                        d="M5.212,12.521a7,7,0,0,1,0-4.5V4.958H1.259a11.848,11.848,0,0,0,0,10.628l3.953-3.066Z"
                        transform="translate(0 1.561)"
                        fill="#fbbc05"
                        fillRule="evenodd"
                    />
                    <path
                        id="Shape-4"
                        data-name="Shape"
                        d="M11.532,4.706a6.392,6.392,0,0,1,4.523,1.77l3.394-3.394A11.372,11.372,0,0,0,11.532,0,11.829,11.829,0,0,0,.957,6.519L4.911,9.585a7.052,7.052,0,0,1,6.621-4.878Z"
                        transform="translate(0.301)"
                        fill="#ea4335"
                        fillRule="evenodd"
                    />
                    <path
                        id="Shape-5"
                        data-name="Shape"
                        d="M0,0H23.666V23.666H0Z"
                        fill="none"
                        fillRule="evenodd"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const PrevIcon = () => (
    <svg viewBox="0 0 7.743 14.121">
        <path
            id="Path_2163"
            data-name="Path 2163"
            d="M0,5.932,6,0l6,5.932"
            transform="translate(0.75 13.061) rotate(-90)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

const DownArrowIcon = () => (
    <svg viewBox="0 0 240.811 240.811">
        <g>
            <path
                d="M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0
            c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859
            c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z"
            />
        </g>
    </svg>
);

const PlusIcon = (props) => (
    <svg {...props} viewBox="0 0 13.5 13.5">
        <g id="Group_4848" data-name="Group 4848" transform="translate(-1407.25 -427.25)">
            <g id="plus" transform="translate(1403 423)">
                <line
                    id="Line_182"
                    data-name="Line 182"
                    y2="12"
                    transform="translate(11 5)"
                    fill="none"
                    stroke="#666"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    opacity="0"
                />
                <line
                    id="Line_183"
                    data-name="Line 183"
                    x2="12"
                    transform="translate(5 11)"
                    fill="none"
                    stroke="#666"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
            <g id="plus-2" data-name="plus" transform="translate(1425 423) rotate(90)">
                <line
                    id="Line_182-2"
                    data-name="Line 182"
                    y2="12"
                    transform="translate(11 5)"
                    fill="none"
                    stroke="#666"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    opacity="0"
                />
                <line
                    id="Line_183-2"
                    data-name="Line 183"
                    x2="12"
                    transform="translate(5 11)"
                    fill="none"
                    stroke="#666"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const MinusIcon = (props) => (
    <svg {...props} viewBox="0 0 13.5 13.5">
        <g id="plus" transform="translate(-4.25 -4.25)">
            <line
                id="Line_182"
                data-name="Line 182"
                y2="12"
                transform="translate(11 5)"
                fill="none"
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                opacity="0"
            />
            <line
                id="Line_183"
                data-name="Line 183"
                x2="12"
                transform="translate(5 11)"
                fill="none"
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const BoldIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Bold" transform="translate(-1595 697)">
            <rect
                id="Rectangle_1465"
                data-name="Rectangle 1465"
                width="25"
                height="25"
                transform="translate(1595 -697)"
                fill="#fff"
                opacity="0"
            />
            <g
                id="Icon_feather-bold"
                data-name="Icon feather-bold"
                transform="translate(1592 -698)"
            >
                <path
                    id="Path_4529"
                    data-name="Path 4529"
                    d="M9,6h7.5a3.75,3.75,0,0,1,0,7.5H9Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4530"
                    data-name="Path 4530"
                    d="M9,18h8.438a3.75,3.75,0,0,1,0,7.5H9Z"
                    transform="translate(0 -4.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const ItalicIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Italics" transform="translate(-1658 697)">
            <rect
                id="Rectangle_1467"
                data-name="Rectangle 1467"
                width="25"
                height="25"
                transform="translate(1658 -697)"
                fill="#fff"
                opacity="0"
            />
            <g
                id="Icon_feather-italic"
                data-name="Icon feather-italic"
                transform="translate(1656.5 -698)"
            >
                <path
                    id="Path_4533"
                    data-name="Path 4533"
                    d="M23.438,6H15"
                    transform="translate(-2.813)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4534"
                    data-name="Path 4534"
                    d="M15.938,30H7.5"
                    transform="translate(0 -9)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4535"
                    data-name="Path 4535"
                    d="M19.125,6,13.5,21"
                    transform="translate(-2.25)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const UnderlineIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Underline" transform="translate(-1626 697)">
            <rect
                id="Rectangle_1466"
                data-name="Rectangle 1466"
                width="25"
                height="25"
                transform="translate(1626 -697)"
                fill="#fff"
                opacity="0"
            />
            <g
                id="Icon_feather-underline"
                data-name="Icon feather-underline"
                transform="translate(1626 -696.5)"
            >
                <path
                    id="Path_4531"
                    data-name="Path 4531"
                    d="M9,4.5v5.833a5,5,0,1,0,10,0V4.5"
                    transform="translate(-1.333)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4532"
                    data-name="Path 4532"
                    d="M6,31.5H19.333"
                    transform="translate(0 -12)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const UppercaseIcon = () => (
    <svg viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M8.77 19l-.29-1.37h-.07c-.48.6-.96 1.01-1.44 1.22-.47.22-1.07.33-1.79.33-.95 0-1.7-.25-2.24-.74-.54-.5-.81-1.2-.81-2.1 0-1.95 1.55-2.97 4.66-3.06l1.64-.05v-.6c0-.76-.17-1.32-.5-1.68-.32-.36-.84-.54-1.55-.54-.8 0-1.71.25-2.73.74l-.44-1.11a6.86 6.86 0 0 1 3.26-.83c1.15 0 2 .25 2.55.76.55.51.83 1.33.83 2.46V19H8.77zm-3.3-1.03c.91 0 1.63-.25 2.14-.75.52-.5.78-1.2.78-2.09v-.87l-1.46.06a5.3 5.3 0 0 0-2.5.54c-.52.32-.78.82-.78 1.5 0 .52.16.92.48 1.2.32.27.77.41 1.34.41zM21.15 19l-1.6-4.09H14.4L12.82 19h-1.51l5.08-12.9h1.26L22.7 19h-1.55zm-2.06-5.43l-1.5-3.98c-.19-.5-.39-1.13-.6-1.86-.12.56-.3 1.18-.55 1.86l-1.5 3.98h4.15z"
        ></path>
    </svg>
);

const TextAlignIcon = () => (
    <svg viewBox="0 0 24 24">
        <path
            id="_3466062334__a"
            d="M3.8 17.3h16.6c.9 0 .9 1.6-.1 1.5H3.8c-.8 0-.9-1.4 0-1.5zm0-8h16.6c.9 0 .9 1.6-.1 1.5H3.8c-.8 0-.9-1.4 0-1.5zm0 4h16.6c.9 0 .9 1.6-.1 1.5H3.8c-.8 0-.9-1.4 0-1.5zm0-8c5.5 0 11-.1 16.5 0 .9 0 .9 1.5-.1 1.5-5.5-.1-11 0-16.5-.1-.9 0-.9-1.4-.1-1.4z"
        ></path>
    </svg>
);

const ImageUploadIcon = ({ color }) => (
    <svg viewBox="0 0 13.757 11.137">
        <g id="Group_2047" data-name="Group 2047" transform="translate(735 -3539)">
            <circle
                id="Ellipse_55"
                data-name="Ellipse 55"
                cx="1.824"
                cy="1.824"
                r="1.824"
                transform="translate(-724.891 3539)"
                fill={color || '#000'}
            />
            <g id="Group_18" data-name="Group 18" transform="translate(-735 3544.72)">
                <path
                    id="Polygon_2"
                    data-name="Polygon 2"
                    d="M3.141,0,6.282,5.417H0Z"
                    transform="translate(0 0)"
                    fill={color || '#000'}
                />
                <path
                    id="Polygon_3"
                    data-name="Polygon 3"
                    d="M3.141,0,6.282,5.417H0Z"
                    transform="translate(3.824 0)"
                    fill={color || '#000'}
                />
            </g>
        </g>
    </svg>
);

const CenterAligmentIcon = () => (
    <svg viewBox="0 0 16.5 16.5">
        <g id="Align_Center" data-name="Align Center" transform="translate(-4.25 -8.25)">
            <path
                id="Path_4512"
                data-name="Path 4512"
                d="M17,15H9"
                transform="translate(0 -1)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4513"
                data-name="Path 4513"
                d="M19.5,9H4.5"
                transform="translate(0.5)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4514"
                data-name="Path 4514"
                d="M19.5,21H4.5"
                transform="translate(0.5 -2)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4515"
                data-name="Path 4515"
                d="M17,27H9"
                transform="translate(0 -3)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const LeftAligmentIcon = () => (
    <svg viewBox="0 0 16.5 16.5">
        <g id="Align_Left" data-name="Align Left" transform="translate(-3.75 -8.25)">
            <path
                id="Path_4520"
                data-name="Path 4520"
                d="M12.5,15h-8"
                transform="translate(0 -1)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4521"
                data-name="Path 4521"
                d="M19.5,9H4.5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4522"
                data-name="Path 4522"
                d="M19.5,21H4.5"
                transform="translate(0 -2)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4523"
                data-name="Path 4523"
                d="M12.5,27h-8"
                transform="translate(0 -3)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const RightAligmentIcon = () => (
    <svg viewBox="0 0 16.5 16.5">
        <g id="Align_Right" data-name="Align Right" transform="translate(-4.25 -8.25)">
            <path
                id="Path_4524"
                data-name="Path 4524"
                d="M18.5,15h-8"
                transform="translate(1.5 -1)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4525"
                data-name="Path 4525"
                d="M19.5,9H4.5"
                transform="translate(0.5)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4526"
                data-name="Path 4526"
                d="M19.5,21H4.5"
                transform="translate(0.5 -2)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4527"
                data-name="Path 4527"
                d="M18.5,27h-8"
                transform="translate(1.5 -3)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const MoveIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Drag" transform="translate(-1562 382)">
            <g id="H4" transform="translate(-186)">
                <rect
                    id="Rectangle_1498"
                    data-name="Rectangle 1498"
                    width="25"
                    height="25"
                    transform="translate(1748 -382)"
                    fill="#fff"
                    opacity="0"
                />
            </g>
            <g
                id="Icon_feather-move"
                data-name="Icon feather-move"
                transform="translate(1564 -380)"
            >
                <path
                    id="Path_4561"
                    data-name="Path 4561"
                    d="M5.25,13.5,3,15.75,5.25,18"
                    transform="translate(0 -5.25)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4562"
                    data-name="Path 4562"
                    d="M13.5,5.25,15.75,3,18,5.25"
                    transform="translate(-5.25)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4563"
                    data-name="Path 4563"
                    d="M18,28.5l-2.25,2.25L13.5,28.5"
                    transform="translate(-5.25 -12.75)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4564"
                    data-name="Path 4564"
                    d="M28.5,13.5l2.25,2.25L28.5,18"
                    transform="translate(-12.75 -5.25)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4565"
                    data-name="Path 4565"
                    d="M3,18H18"
                    transform="translate(0 -7.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4566"
                    data-name="Path 4566"
                    d="M18,3V18"
                    transform="translate(-7.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const PaddingAll = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4899" data-name="Group 4899" transform="translate(-1185 -2787)">
            <g id="Group_4890" data-name="Group 4890" transform="translate(-420 2034)">
                <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                    <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 0.7) rotate(180)"
                            fill="none"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 14) rotate(180)"
                            fill="none"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                        fill="none"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                        fill="none"
                    />
                </g>
                <g
                    id="Group_2289-2"
                    data-name="Group 2289"
                    transform="translate(1619 756) rotate(90)"
                >
                    <rect
                        id="Rectangle_767-2"
                        data-name="Rectangle 767"
                        width="8"
                        height="0.7"
                        rx="0.35"
                        transform="translate(8 3.7) rotate(180)"
                        fill="none"
                    />
                    <rect
                        id="Rectangle_768-2"
                        data-name="Rectangle 768"
                        width="8"
                        height="0.7"
                        rx="0.35"
                        transform="translate(8 11) rotate(180)"
                        fill="none"
                    ></rect>
                </g>
                <rect
                    id="Rectangle_769-2"
                    data-name="Rectangle 769"
                    width="8"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1616 756.7) rotate(180)"
                    fill="none"
                ></rect>
                <rect
                    id="Rectangle_770-2"
                    data-name="Rectangle 770"
                    width="8"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1616 764) rotate(180)"
                    fill="none"
                ></rect>
            </g>
        </g>
    </svg>
);

const PaddingSingle = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4900" data-name="Group 4900" transform="translate(-1605 -753)">
            <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                    <rect
                        id="Rectangle_767"
                        data-name="Rectangle 767"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                    ></rect>
                    <rect
                        id="Rectangle_768"
                        data-name="Rectangle 768"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                    ></rect>
                </g>
                <rect
                    id="Rectangle_769"
                    data-name="Rectangle 769"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 0.7) rotate(180)"
                ></rect>
                <rect
                    id="Rectangle_770"
                    data-name="Rectangle 770"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 14) rotate(180)"
                ></rect>
            </g>
            <g id="Group_2289-2" data-name="Group 2289" transform="translate(1619 753) rotate(90)">
                <rect
                    id="Rectangle_767-2"
                    data-name="Rectangle 767"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 3.7) rotate(180)"
                ></rect>
                <rect
                    id="Rectangle_768-2"
                    data-name="Rectangle 768"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 11) rotate(180)"
                ></rect>
            </g>
            <rect
                id="Rectangle_769-2"
                data-name="Rectangle 769"
                width="14"
                height="0.7"
                rx="0.35"
                transform="translate(1619 756.7) rotate(180)"
            ></rect>
            <rect
                id="Rectangle_770-2"
                data-name="Rectangle 770"
                width="14"
                height="0.7"
                rx="0.35"
                transform="translate(1619 764) rotate(180)"
            ></rect>
        </g>
    </svg>
);

const DelIcon = (props) => (
    <svg viewBox="0 0 15 16.5" {...props}>
        <g id="Delete" transform="translate(0.75 0.75)">
            <path
                id="Path_216"
                data-name="Path 216"
                d="M3,6H16.5"
                transform="translate(-3 -3)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_217"
                data-name="Path 217"
                d="M15.5,5V15.5A1.5,1.5,0,0,1,14,17H6.5A1.5,1.5,0,0,1,5,15.5V5M7.25,5V3.5A1.5,1.5,0,0,1,8.75,2h3a1.5,1.5,0,0,1,1.5,1.5V5"
                transform="translate(-3.5 -2)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_238"
                data-name="Line 238"
                y2="4.5"
                transform="translate(5.25 6.75)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_239"
                data-name="Line 239"
                y2="4.5"
                transform="translate(8.25 6.75)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const CopyIcon = (props) => (
    <svg viewBox="0 0 16.5 16.5" {...props}>
        <g id="Group_1472" data-name="Group 1472" transform="translate(0.75 0.75)">
            <rect
                id="Rectangle_6"
                data-name="Rectangle 6"
                width="9.75"
                height="9.75"
                rx="2"
                transform="translate(5.25 5.25)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_70"
                data-name="Path 70"
                d="M4.25,11.75H3.5A1.5,1.5,0,0,1,2,10.25V3.5A1.5,1.5,0,0,1,3.5,2h6.75a1.5,1.5,0,0,1,1.5,1.5v.75"
                transform="translate(-2 -2)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const UpArrow = () => (
    <svg width="15.511" height="16.5" viewBox="0 0 15.511 16.5">
        <g id="Group_7997" data-name="Group 7997" transform="translate(16.593 -3.036) rotate(90)">
            <g id="Group_7770" data-name="Group 7770" transform="translate(3.786 2.143)">
                <path
                    id="Path_4694"
                    data-name="Path 4694"
                    d="M13.389,6.695,6.695,0,0,6.695"
                    transform="translate(0 13.389) rotate(-90)"
                    fill="none"
                    stroke="#474747"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4695"
                    data-name="Path 4695"
                    d="M0,0V15"
                    transform="translate(0 6.695) rotate(-90)"
                    fill="none"
                    stroke="#474747"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const DownArrow = () => (
    <svg width="15.51" height="16.5" viewBox="0 0 15.51 16.5">
        <g id="Group_7998" data-name="Group 7998" transform="translate(-1.082 19.536) rotate(-90)">
            <g id="Group_7770" data-name="Group 7770" transform="translate(3.786 2.143)">
                <path
                    id="Path_4694"
                    data-name="Path 4694"
                    d="M13.389,0,6.695,6.695,0,0"
                    transform="translate(6.695) rotate(90)"
                    fill="none"
                    stroke="#474747"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_4695"
                    data-name="Path 4695"
                    d="M0,15V0"
                    transform="translate(15 6.695) rotate(90)"
                    fill="none"
                    stroke="#474747"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const PaddingAllSidesIcon = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4899" data-name="Group 4899" transform="translate(-1185 -2787)">
            <g id="Group_4890" data-name="Group 4890" transform="translate(-420 2034)">
                <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                    <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 0.7) rotate(180)"
                            fill="none"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 14) rotate(180)"
                            fill="none"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                        fill="none"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                        fill="none"
                    />
                </g>
                <g
                    id="Group_2289-2"
                    data-name="Group 2289"
                    transform="translate(1619 756) rotate(90)"
                >
                    <rect
                        id="Rectangle_767-2"
                        data-name="Rectangle 767"
                        width="8"
                        height="0.7"
                        rx="0.35"
                        transform="translate(8 3.7) rotate(180)"
                        fill="none"
                    />
                    <rect
                        id="Rectangle_768-2"
                        data-name="Rectangle 768"
                        width="8"
                        height="0.7"
                        rx="0.35"
                        transform="translate(8 11) rotate(180)"
                        fill="none"
                    />
                </g>
                <rect
                    id="Rectangle_769-2"
                    data-name="Rectangle 769"
                    width="8"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1616 756.7) rotate(180)"
                    fill="none"
                />
                <rect
                    id="Rectangle_770-2"
                    data-name="Rectangle 770"
                    width="8"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1616 764) rotate(180)"
                    fill="none"
                />
            </g>
        </g>
    </svg>
);

const PaddingEachSideIcon = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4900" data-name="Group 4900" transform="translate(-1605 -753)">
            <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                    <rect
                        id="Rectangle_767"
                        data-name="Rectangle 767"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                    />
                    <rect
                        id="Rectangle_768"
                        data-name="Rectangle 768"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                    />
                </g>
                <rect
                    id="Rectangle_769"
                    data-name="Rectangle 769"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 0.7) rotate(180)"
                />
                <rect
                    id="Rectangle_770"
                    data-name="Rectangle 770"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 14) rotate(180)"
                />
            </g>
            <g id="Group_2289-2" data-name="Group 2289" transform="translate(1619 753) rotate(90)">
                <rect
                    id="Rectangle_767-2"
                    data-name="Rectangle 767"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 3.7) rotate(180)"
                />
                <rect
                    id="Rectangle_768-2"
                    data-name="Rectangle 768"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 11) rotate(180)"
                />
            </g>
            <rect
                id="Rectangle_769-2"
                data-name="Rectangle 769"
                width="14"
                height="0.7"
                rx="0.35"
                transform="translate(1619 756.7) rotate(180)"
            />
            <rect
                id="Rectangle_770-2"
                data-name="Rectangle 770"
                width="14"
                height="0.7"
                rx="0.35"
                transform="translate(1619 764) rotate(180)"
            />
        </g>
    </svg>
);

const PaddingLeftIcon = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4903" data-name="Group 4903" transform="translate(-2839 1088) rotate(-90)">
            <g id="Group_4902" data-name="Group 4902" transform="translate(-531 2086)">
                <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                    <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 0.7) rotate(180)"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 14) rotate(180)"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                    />
                </g>
                <rect
                    id="Rectangle_769-2"
                    data-name="Rectangle 769"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1619 756.7) rotate(180)"
                />
                <rect
                    id="Rectangle_1433"
                    data-name="Rectangle 1433"
                    width="14"
                    height="3"
                    transform="translate(1605 753)"
                />
            </g>
        </g>
    </svg>
);

const PaddingRightIcon = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4905" data-name="Group 4905" transform="translate(2853 -1074) rotate(90)">
            <g id="Group_4902" data-name="Group 4902" transform="translate(-531 2086)">
                <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                    <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 0.7) rotate(180)"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 14) rotate(180)"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                    />
                </g>
                <rect
                    id="Rectangle_769-2"
                    data-name="Rectangle 769"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1619 756.7) rotate(180)"
                />
                <rect
                    id="Rectangle_1433"
                    data-name="Rectangle 1433"
                    width="14"
                    height="3"
                    transform="translate(1605 753)"
                />
            </g>
        </g>
    </svg>
);

const PaddingBottomIcon = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4904" data-name="Group 4904" transform="translate(1088 2853) rotate(180)">
            <g id="Group_4902" data-name="Group 4902" transform="translate(-531 2086)">
                <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                    <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 0.7) rotate(180)"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="14"
                            height="0.7"
                            rx="0.35"
                            transform="translate(14 14) rotate(180)"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                    />
                </g>
                <rect
                    id="Rectangle_769-2"
                    data-name="Rectangle 769"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(1619 756.7) rotate(180)"
                />
                <rect
                    id="Rectangle_1433"
                    data-name="Rectangle 1433"
                    width="14"
                    height="3"
                    transform="translate(1605 753)"
                />
            </g>
        </g>
    </svg>
);

const PaddingTopIcon = () => (
    <svg viewBox="0 0 14 14">
        <g id="Group_4901" data-name="Group 4901" transform="translate(-1605 -753)">
            <g id="Group_2290" data-name="Group 2290" transform="translate(1605 753)">
                <g id="Group_2289" data-name="Group 2289" transform="translate(14) rotate(90)">
                    <rect
                        id="Rectangle_767"
                        data-name="Rectangle 767"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 0.7) rotate(180)"
                    />
                    <rect
                        id="Rectangle_768"
                        data-name="Rectangle 768"
                        width="14"
                        height="0.7"
                        rx="0.35"
                        transform="translate(14 14) rotate(180)"
                    />
                </g>
                <rect
                    id="Rectangle_769"
                    data-name="Rectangle 769"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 0.7) rotate(180)"
                />
                <rect
                    id="Rectangle_770"
                    data-name="Rectangle 770"
                    width="14"
                    height="0.7"
                    rx="0.35"
                    transform="translate(14 14) rotate(180)"
                />
            </g>
            <rect
                id="Rectangle_769-2"
                data-name="Rectangle 769"
                width="14"
                height="0.7"
                rx="0.35"
                transform="translate(1619 756.7) rotate(180)"
            />
            <rect
                id="Rectangle_1433"
                data-name="Rectangle 1433"
                width="14"
                height="3"
                transform="translate(1605 753)"
            />
        </g>
    </svg>
);

const CornerRadiusSelected = () => (
    <svg viewBox="0 0 14 14">
        <g
            id="Corner_Radius_Selected"
            data-name="Corner Radius Selected"
            transform="translate(0 0.375)"
        >
            <g
                id="Rectangle_743"
                data-name="Rectangle 743"
                transform="translate(0 -0.375)"
                fill="none"
                strokeWidth="1.5"
            >
                <rect width="14" height="14" rx="5" stroke="none" />
                <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
            </g>
        </g>
    </svg>
);

const CornerRadiusCurves = () => (
    <svg viewBox="0 0 16.333 16.001">
        <g id="_Corners_Curves" data-name=" Corners Curves" transform="translate(-1816.25 448.75)">
            <g id="Replace_Image" data-name="Replace Image" transform="translate(776.833 -876)">
                <g
                    id="Icon_feather-check-square"
                    data-name="Icon feather-check-square"
                    transform="translate(1035.5 423.5)"
                >
                    <path
                        id="Path_3076"
                        data-name="Path 3076"
                        d="M19.5,10V6.167A1.667,1.667,0,0,0,17.833,4.5H13.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
            <g id="Replace_Image-2" data-name="Replace Image" transform="translate(1826 -439)">
                <g
                    id="Icon_feather-check-square-2"
                    data-name="Icon feather-check-square"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_3076-2"
                        data-name="Path 3076"
                        d="M19.5,4.5V8.334A1.667,1.667,0,0,1,17.833,10H13.667"
                        transform="translate(-13.667 -4.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
            <g id="Replace_Image-3" data-name="Replace Image" transform="translate(1817 -448)">
                <g id="Icon_feather-check-square-3" data-name="Icon feather-check-square">
                    <path
                        id="Path_3076-3"
                        data-name="Path 3076"
                        d="M13.667,10V6.167A1.667,1.667,0,0,1,15.333,4.5H19.5"
                        transform="translate(-13.667 -4.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
            <g id="Replace_Image-4" data-name="Replace Image" transform="translate(1817 -439)">
                <g id="Icon_feather-check-square-4" data-name="Icon feather-check-square">
                    <path
                        id="Path_3076-4"
                        data-name="Path 3076"
                        d="M13.667,4.5V8.334A1.667,1.667,0,0,0,15.333,10H19.5"
                        transform="translate(-13.667 -4.5)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const CornerRadiusleftTop = () => (
    <svg viewBox="0 0 14 14">
        <g
            id="Corner_Radius_2_left_top"
            data-name="Corner Radius 2 left top"
            transform="translate(-1037 -673)"
        >
            <g id="Group_4944" data-name="Group 4944" transform="translate(-1910 1872) rotate(-90)">
                <g id="Group_4939" data-name="Group 4939" transform="translate(1184.764 2947.139)">
                    <g
                        id="Rectangle_743"
                        data-name="Rectangle 743"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#757575"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1445"
                        data-name="Rectangle 1445"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#757575"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Path_3053"
                        data-name="Path 3053"
                        transform="translate(0.236 -0.139)"
                        fill="#1657cc"
                    >
                        <path
                            d="M 13.47240543365479 4.5 L 9.5 4.5 L 9.5 0.527594268321991 C 11.58260631561279 0.7589008808135986 13.24109935760498 2.417393445968628 13.47240543365479 4.5 Z"
                            stroke="none"
                        />
                        <path
                            d="M 10 1.126540184020996 L 10 4 L 12.873459815979 4 C 12.51089000701904 2.596415519714355 11.40358448028564 1.489109754562378 10 1.126540184020996 M 9 0 C 11.76142024993896 0 14 2.238579988479614 14 5 L 9 5 L 9 0 Z"
                            stroke="none"
                            fill="#1657CD"
                        />
                    </g>
                </g>
                <g id="Group_4941" data-name="Group 4941">
                    <g
                        id="Rectangle_1442"
                        data-name="Rectangle 1442"
                        transform="translate(1198 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1439"
                        data-name="Rectangle 1439"
                        transform="translate(1185 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
                <g id="Group_4940" data-name="Group 4940" transform="translate(0 -1)">
                    <g
                        id="Rectangle_1444"
                        data-name="Rectangle 1444"
                        transform="translate(1190 2949) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1443"
                        data-name="Rectangle 1443"
                        transform="translate(1190 2962) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const CornerRadiusRightTop = () => (
    <svg viewBox="0 0 14 14">
        <g
            id="Corner_Radius_2_right_top"
            data-name="Corner Radius 2 right top"
            transform="translate(-1063 -673)"
        >
            <g id="Group_4943" data-name="Group 4943" transform="translate(-122 -2274)">
                <g id="Group_4939" data-name="Group 4939" transform="translate(1184.764 2947.139)">
                    <g
                        id="Rectangle_743"
                        data-name="Rectangle 743"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#e5e5e5"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1445"
                        data-name="Rectangle 1445"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#757575"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Path_3053"
                        data-name="Path 3053"
                        transform="translate(0.236 -0.139)"
                        fill="#1657cc"
                    >
                        <path
                            d="M 13.47240543365479 4.5 L 9.5 4.5 L 9.5 0.527594268321991 C 11.58260631561279 0.7589008808135986 13.24109935760498 2.417393445968628 13.47240543365479 4.5 Z"
                            stroke="none"
                        />
                        <path
                            d="M 10 1.126540184020996 L 10 4 L 12.873459815979 4 C 12.51089000701904 2.596415519714355 11.40358448028564 1.489109754562378 10 1.126540184020996 M 9 0 C 11.76142024993896 0 14 2.238579988479614 14 5 L 9 5 L 9 0 Z"
                            stroke="none"
                            fill="#1657CD"
                        />
                    </g>
                </g>
                <g id="Group_4941" data-name="Group 4941">
                    <g
                        id="Rectangle_1442"
                        data-name="Rectangle 1442"
                        transform="translate(1198 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1439"
                        data-name="Rectangle 1439"
                        transform="translate(1185 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
                <g id="Group_4940" data-name="Group 4940" transform="translate(0 -1)">
                    <g
                        id="Rectangle_1444"
                        data-name="Rectangle 1444"
                        transform="translate(1190 2949) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1443"
                        data-name="Rectangle 1443"
                        transform="translate(1190 2962) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const CornerRadiusRightBottom = () => (
    <svg viewBox="0 0 14 14">
        <g
            id="Corner_Radius_2_left_bottom"
            data-name="Corner Radius 2 left bottom"
            transform="translate(-985 -673)"
        >
            <g id="Group_4946" data-name="Group 4946" transform="translate(3946 -512) rotate(90)">
                <g id="Group_4939" data-name="Group 4939" transform="translate(1184.764 2947.139)">
                    <g
                        id="Rectangle_743"
                        data-name="Rectangle 743"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#e5e5e5"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1445"
                        data-name="Rectangle 1445"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#757575"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Path_3053"
                        data-name="Path 3053"
                        transform="translate(0.236 -0.139)"
                        fill="#1657cc"
                    >
                        <path
                            d="M 13.47240543365479 4.5 L 9.5 4.5 L 9.5 0.527594268321991 C 11.58260631561279 0.7589008808135986 13.24109935760498 2.417393445968628 13.47240543365479 4.5 Z"
                            stroke="none"
                        />
                        <path
                            d="M 10 1.126540184020996 L 10 4 L 12.873459815979 4 C 12.51089000701904 2.596415519714355 11.40358448028564 1.489109754562378 10 1.126540184020996 M 9 0 C 11.76142024993896 0 14 2.238579988479614 14 5 L 9 5 L 9 0 Z"
                            stroke="none"
                            fill="#1657CD"
                        />
                    </g>
                </g>
                <g id="Group_4941" data-name="Group 4941">
                    <g
                        id="Rectangle_1442"
                        data-name="Rectangle 1442"
                        transform="translate(1198 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1439"
                        data-name="Rectangle 1439"
                        transform="translate(1185 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
                <g id="Group_4940" data-name="Group 4940" transform="translate(0 -1)">
                    <g
                        id="Rectangle_1444"
                        data-name="Rectangle 1444"
                        transform="translate(1190 2949) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1443"
                        data-name="Rectangle 1443"
                        transform="translate(1190 2962) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const CornerRadiusLeftBottom = () => (
    <svg viewBox="0 0 14 14">
        <g
            id="Corner_Radius_2_right_bottom"
            data-name="Corner Radius 2 right bottom"
            transform="translate(-1011 -673)"
        >
            <g id="Group_4945" data-name="Group 4945" transform="translate(2210 3634) rotate(180)">
                <g id="Group_4939" data-name="Group 4939" transform="translate(1184.764 2947.139)">
                    <g
                        id="Rectangle_743"
                        data-name="Rectangle 743"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#e5e5e5"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1445"
                        data-name="Rectangle 1445"
                        transform="translate(0.236 -0.139)"
                        fill="none"
                        stroke="#757575"
                        strokeWidth="1.5"
                    >
                        <rect width="14" height="14" rx="5" stroke="none" />
                        <rect x="0.5" y="0.5" width="13" height="13" rx="4.5" fill="none" />
                    </g>
                    <g
                        id="Path_3053"
                        data-name="Path 3053"
                        transform="translate(0.236 -0.139)"
                        fill="#1657cc"
                    >
                        <path
                            d="M 13.47240543365479 4.5 L 9.5 4.5 L 9.5 0.527594268321991 C 11.58260631561279 0.7589008808135986 13.24109935760498 2.417393445968628 13.47240543365479 4.5 Z"
                            stroke="none"
                        />
                        <path
                            d="M 10 1.126540184020996 L 10 4 L 12.873459815979 4 C 12.51089000701904 2.596415519714355 11.40358448028564 1.489109754562378 10 1.126540184020996 M 9 0 C 11.76142024993896 0 14 2.238579988479614 14 5 L 9 5 L 9 0 Z"
                            stroke="none"
                            fill="#1657CD"
                        />
                    </g>
                </g>
                <g id="Group_4941" data-name="Group 4941">
                    <g
                        id="Rectangle_1442"
                        data-name="Rectangle 1442"
                        transform="translate(1198 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1439"
                        data-name="Rectangle 1439"
                        transform="translate(1185 2952)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
                <g id="Group_4940" data-name="Group 4940" transform="translate(0 -1)">
                    <g
                        id="Rectangle_1444"
                        data-name="Rectangle 1444"
                        transform="translate(1190 2949) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                    <g
                        id="Rectangle_1443"
                        data-name="Rectangle 1443"
                        transform="translate(1190 2962) rotate(-90)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.5"
                    >
                        <rect width="1" height="4" stroke="none" />
                        <rect x="0.5" y="0.5" height="3" fill="none" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const AllBorders = () => (
    <svg viewBox="0 0 30 30">
        <g id="Group_2284" data-name="Group 2284" transform="translate(-1600 -748)">
            <g id="Group_2276" data-name="Group 2276" transform="translate(1590 583)" opacity="0">
                <rect
                    id="Rectangle_658"
                    data-name="Rectangle 658"
                    width="30"
                    height="30"
                    rx="5"
                    transform="translate(10 195) rotate(-90)"
                />
            </g>
            <g id="Group_2292" data-name="Group 2292">
                <rect
                    id="Rectangle_199"
                    data-name="Rectangle 199"
                    width="14"
                    height="14"
                    rx="1.5"
                    transform="translate(1608 770) rotate(-90)"
                />
                <g id="Group_2290" data-name="Group 2290">
                    <g
                        id="Group_2289"
                        data-name="Group 2289"
                        transform="translate(1764 -847) rotate(90)"
                    >
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 140) rotate(180)"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 159) rotate(180)"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 754) rotate(180)"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 773) rotate(180)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const BorderBottom = () => (
    <svg viewBox="0 0 30 30">
        <g id="Group_2297" data-name="Group 2297" transform="translate(-1790 -758)">
            <g id="Group_2286" data-name="Group 2286" transform="translate(119 10)">
                <g
                    id="Group_2278"
                    data-name="Group 2278"
                    transform="translate(1661 583)"
                    opacity="0"
                >
                    <rect
                        id="Rectangle_658"
                        data-name="Rectangle 658"
                        width="30"
                        height="30"
                        rx="5"
                        transform="translate(10 195) rotate(-90)"
                    />
                </g>
            </g>
            <g id="Group_2293" data-name="Group 2293" transform="translate(190 10)">
                <rect
                    id="Rectangle_199"
                    data-name="Rectangle 199"
                    width="14"
                    height="14"
                    rx="1.5"
                    transform="translate(1608 770) rotate(-90)"
                />
                <g id="Group_2290" data-name="Group 2290">
                    <g
                        id="Group_2289"
                        data-name="Group 2289"
                        transform="translate(1764 -847) rotate(90)"
                        opacity="0"
                    >
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 140) rotate(180)"
                            opacity="0"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 159) rotate(180)"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 754) rotate(180)"
                        opacity="0"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 773) rotate(180)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const BorderLeft = () => (
    <svg viewBox="0 0 30 30">
        <g id="Group_2287" data-name="Group 2287" transform="translate(-1707 -748)">
            <g id="Group_2279" data-name="Group 2279" transform="translate(1697 583)" opacity="0">
                <rect
                    id="Rectangle_658"
                    data-name="Rectangle 658"
                    width="30"
                    height="30"
                    rx="5"
                    transform="translate(10 195) rotate(-90)"
                />
            </g>
            <g id="Group_2294" data-name="Group 2294" transform="translate(107)">
                <rect
                    id="Rectangle_199"
                    data-name="Rectangle 199"
                    width="14"
                    height="14"
                    rx="1.5"
                    transform="translate(1608 770) rotate(-90)"
                />
                <g id="Group_2290" data-name="Group 2290">
                    <g
                        id="Group_2289"
                        data-name="Group 2289"
                        transform="translate(1764 -847) rotate(90)"
                    >
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 140) rotate(180)"
                            opacity="0"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 159) rotate(180)"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 754) rotate(180)"
                        opacity="0"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 773) rotate(180)"
                        opacity="0"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const BorderRight = () => (
    <svg viewBox="0 0 30 30">
        <g id="Group_2295" data-name="Group 2295" transform="translate(-1600 -748)">
            <g id="Group_2276" data-name="Group 2276" transform="translate(1590 583)" opacity="0">
                <rect
                    id="Rectangle_658"
                    data-name="Rectangle 658"
                    width="30"
                    height="30"
                    rx="5"
                    transform="translate(10 195) rotate(-90)"
                />
            </g>
            <g id="Group_2292" data-name="Group 2292">
                <rect
                    id="Rectangle_199"
                    data-name="Rectangle 199"
                    width="14"
                    height="14"
                    rx="1.5"
                    transform="translate(1608 770) rotate(-90)"
                />
                <g id="Group_2290" data-name="Group 2290">
                    <g
                        id="Group_2289"
                        data-name="Group 2289"
                        transform="translate(1764 -847) rotate(90)"
                    >
                        <rect
                            id="Rectangle_767"
                            data-name="Rectangle 767"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 140) rotate(180)"
                        />
                        <rect
                            id="Rectangle_768"
                            data-name="Rectangle 768"
                            width="20"
                            height="1"
                            rx="0.5"
                            transform="translate(1620 159) rotate(180)"
                            opacity="0"
                        />
                    </g>
                    <rect
                        id="Rectangle_769"
                        data-name="Rectangle 769"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 754) rotate(180)"
                        opacity="0"
                    />
                    <rect
                        id="Rectangle_770"
                        data-name="Rectangle 770"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1625 773) rotate(180)"
                        opacity="0"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const BorderTop = () => (
    <svg viewBox="0 0 30 30">
        <g id="Group_2285" data-name="Group 2285" transform="translate(-1636 -748)">
            <g id="Group_2277" data-name="Group 2277" transform="translate(1626 583)" opacity="0">
                <rect
                    id="Rectangle_658"
                    data-name="Rectangle 658"
                    width="30"
                    height="30"
                    rx="5"
                    transform="translate(10 195) rotate(-90)"
                />
            </g>
            <path
                id="Path_2447"
                data-name="Path 2447"
                d="M1.5,0h11A1.5,1.5,0,0,1,14,1.5v11A1.5,1.5,0,0,1,12.5,14H1.5A1.5,1.5,0,0,1,0,12.5V1.5A1.5,1.5,0,0,1,1.5,0Z"
                transform="translate(1644 770) rotate(-90)"
            />
            <g id="Group_2291" data-name="Group 2291" transform="translate(36)">
                <g
                    id="Group_2289"
                    data-name="Group 2289"
                    transform="translate(1764 -847) rotate(90)"
                >
                    <rect
                        id="Rectangle_767"
                        data-name="Rectangle 767"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1620 140) rotate(180)"
                        opacity="0"
                    />
                    <rect
                        id="Rectangle_768"
                        data-name="Rectangle 768"
                        width="20"
                        height="1"
                        rx="0.5"
                        transform="translate(1620 159) rotate(180)"
                        opacity="0"
                    />
                </g>
                <rect
                    id="Rectangle_769"
                    data-name="Rectangle 769"
                    width="20"
                    height="1"
                    rx="0.5"
                    transform="translate(1625 754) rotate(180)"
                />
                <rect
                    id="Rectangle_770"
                    data-name="Rectangle 770"
                    width="20"
                    height="1"
                    rx="0.5"
                    transform="translate(1625 773) rotate(180)"
                    opacity="0"
                />
            </g>
        </g>
    </svg>
);

const LeftAlign = () => (
    <svg viewBox="0 0 20 20">
        <g id="Group_2023" data-name="Group 2023" transform="translate(-1769 -139)">
            <rect
                id="Rectangle_199"
                data-name="Rectangle 199"
                width="20"
                height="1"
                rx="0.5"
                transform="translate(1770 139) rotate(90)"
            />
            <g id="Group_396" data-name="Group 396" transform="translate(178 -17)">
                <rect
                    id="Rectangle_197"
                    data-name="Rectangle 197"
                    width="16"
                    height="6"
                    rx="1.5"
                    transform="translate(1595 168)"
                />
                <rect
                    id="Rectangle_198"
                    data-name="Rectangle 198"
                    width="9"
                    height="6"
                    rx="1.5"
                    transform="translate(1595 159)"
                />
            </g>
        </g>
    </svg>
);

const AlignCenter = () => (
    <svg viewBox="0 0 20 20">
        <g id="Group_2027" data-name="Group 2027" transform="translate(-1807 -139)">
            <rect
                id="Rectangle_199"
                data-name="Rectangle 199"
                width="20"
                height="1"
                rx="0.5"
                transform="translate(1816 139) rotate(90)"
            />
            <rect
                id="Rectangle_197"
                data-name="Rectangle 197"
                width="16"
                height="6"
                rx="1.5"
                transform="translate(1807 151)"
            />
            <rect
                id="Rectangle_198"
                data-name="Rectangle 198"
                width="9"
                height="6"
                rx="1.5"
                transform="translate(1811 142)"
            />
        </g>
    </svg>
);

const RightAlign = () => (
    <svg viewBox="0 0 20 20">
        <g id="Group_2025" data-name="Group 2025" transform="translate(-1842 -139)">
            <rect
                id="Rectangle_199"
                data-name="Rectangle 199"
                width="20"
                height="1"
                rx="0.5"
                transform="translate(1862 139) rotate(90)"
            />
            <g id="Group_396" data-name="Group 396" transform="translate(1858 157) rotate(180)">
                <rect id="Rectangle_197" data-name="Rectangle 197" width="16" height="6" rx="1.5" />
                <rect
                    id="Rectangle_198"
                    data-name="Rectangle 198"
                    width="9"
                    height="6"
                    rx="1.5"
                    transform="translate(0 9)"
                />
            </g>
        </g>
    </svg>
);

const TitleCaseIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="All_caps" data-name="All caps" transform="translate(-1598 730)">
            <rect
                id="Rectangle_1470"
                data-name="Rectangle 1470"
                width="25"
                height="25"
                transform="translate(1598 -730)"
                fill="#fff"
                opacity="0"
            />
            <path
                id="Path_4536"
                data-name="Path 4536"
                d="M9.045-8.67H5.685V0H3.6V-8.67H.24v-1.965H9.045Zm9.585,0H15.27V0H13.185V-8.67H9.825v-1.965H18.63Z"
                transform="translate(1600.76 -712.365)"
            />
        </g>
    </svg>
);

const SmallCapsIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Lower_Case" data-name="Lower Case" transform="translate(-1629 730)">
            <rect
                id="Rectangle_1469"
                data-name="Rectangle 1469"
                width="25"
                height="25"
                transform="translate(1629 -730)"
                fill="#fff"
                opacity="0"
            />
            <path
                id="Path_4537"
                data-name="Path 4537"
                d="M3.525-9.585v2.2H5.01v1.77H3.525v3.09a.871.871,0,0,0,.21.66.947.947,0,0,0,.66.2,2.381,2.381,0,0,0,.615-.06v1.65a2.976,2.976,0,0,1-1.125.18A2.36,2.36,0,0,1,2.175-.5a2.214,2.214,0,0,1-.63-1.672V-5.61H.21V-7.38H.585A1.086,1.086,0,0,0,1.433-7.7a1.213,1.213,0,0,0,.293-.848V-9.585Zm5.595,0v2.2H10.6v1.77H9.12v3.09a.871.871,0,0,0,.21.66.947.947,0,0,0,.66.2,2.381,2.381,0,0,0,.615-.06v1.65A2.976,2.976,0,0,1,9.48.105,2.36,2.36,0,0,1,7.77-.5a2.214,2.214,0,0,1-.63-1.672V-5.61H5.8V-7.38H6.18A1.086,1.086,0,0,0,7.027-7.7,1.213,1.213,0,0,0,7.32-8.55V-9.585Z"
                transform="translate(1635.79 -712.415)"
            />
        </g>
    </svg>
);

const SentenceCaseIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Sentence_Case" data-name="Sentence Case" transform="translate(-1661 730)">
            <rect
                id="Rectangle_1468"
                data-name="Rectangle 1468"
                width="25"
                height="25"
                transform="translate(1661 -730)"
                fill="#fff"
                opacity="0"
            />
            <path
                id="Path_4538"
                data-name="Path 4538"
                d="M9.045-8.67H5.685V0H3.6V-8.67H.24v-1.965H9.045Zm3.435-.915v2.2h1.485v1.77H12.48v3.09a.871.871,0,0,0,.21.66.947.947,0,0,0,.66.2,2.381,2.381,0,0,0,.615-.06v1.65a2.976,2.976,0,0,1-1.125.18A2.36,2.36,0,0,1,11.13-.5a2.214,2.214,0,0,1-.63-1.672V-5.61H9.165V-7.38H9.54a1.086,1.086,0,0,0,.847-.322,1.213,1.213,0,0,0,.293-.848V-9.585Z"
                transform="translate(1666.76 -712.365)"
            />
        </g>
    </svg>
);

const SaveButton = () => (
    <svg viewBox="0 0 24 24">
        <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z" />
    </svg>
);

const Edit = () => (
    <svg viewBox="0 0 25 25">
        <g id="Edit" transform="translate(-2019 -131)">
            <g id="Group_7356" data-name="Group 7356">
                <g
                    id="Share_with_Me_Share"
                    data-name="Share with Me / Share"
                    transform="translate(-1 40)"
                >
                    <g id="My_Library" data-name="My Library" transform="translate(0 68)">
                        <g
                            id="Upload_Files"
                            data-name="Upload Files"
                            transform="translate(-31 120)"
                        >
                            <g id="Trash" transform="translate(450 439)">
                                <rect
                                    id="Rectangle_1486"
                                    data-name="Rectangle 1486"
                                    width="25"
                                    height="25"
                                    transform="translate(1601 -536)"
                                    fill="#fff"
                                    opacity="0"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g
                    id="Icon_feather-edit"
                    data-name="Icon feather-edit"
                    transform="translate(2021 133.182)"
                >
                    <path
                        id="Path_4602"
                        data-name="Path 4602"
                        d="M9.709,6H4.491A1.491,1.491,0,0,0,3,7.491V17.928a1.491,1.491,0,0,0,1.491,1.491H14.928a1.491,1.491,0,0,0,1.491-1.491V12.709"
                        transform="translate(0 -1.601)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Path_4603"
                        data-name="Path 4603"
                        d="M19.828,3.281a1.581,1.581,0,0,1,2.236,2.236L14.982,12.6,12,13.345l.745-2.982Z"
                        transform="translate(-4.527)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const AddMediaIcon = () => (
    <svg viewBox="0 0 18.477 18.477">
        <g id="Media" transform="translate(0.75 0.75)">
            <rect
                id="Rectangle_15"
                data-name="Rectangle 15"
                width="16.667"
                height="16.667"
                rx="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <circle
                id="Ellipse_29"
                data-name="Ellipse 29"
                cx="0.556"
                cy="0.556"
                r="0.556"
                transform="translate(4.537 4.537)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_137"
                data-name="Path 137"
                d="M19.815,14.63,15.185,10,5,20.185"
                transform="translate(-3.148 -3.519)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const MoreIcon = () => (
    <svg viewBox="0 0 426.667 426.667">
        <circle cx="42.667" cy="213.333" r="42.667" />
        <circle cx="213.333" cy="213.333" r="42.667" />
        <circle cx="384" cy="213.333" r="42.667" />
    </svg>
);

const GifsMediaIcon = () => (
    <svg viewBox="0 0 25 25">
        <g id="Gif" transform="translate(-2065 494)">
            <rect
                id="Rectangle_1515"
                data-name="Rectangle 1515"
                width="25"
                height="25"
                transform="translate(2065 -494)"
                fill="#fff"
                opacity="0"
            />
            <path
                id="Icon_material-gif"
                data-name="Icon material-gif"
                d="M14.929,13.5h1.714v6.857H14.929Zm-2.857,0H8.643A1.145,1.145,0,0,0,7.5,14.643v4.571a1.145,1.145,0,0,0,1.143,1.143h3.429a1.145,1.145,0,0,0,1.143-1.143V16.929H11.5v1.714H9.214V15.214h4v-.571A1.145,1.145,0,0,0,12.071,13.5ZM23.5,15.214V13.5H18.357v6.857h1.714V18.071h2.286V16.357H20.071V15.214Z"
                transform="translate(2062.5 -498.5)"
            />
        </g>
    </svg>
);

const CheckIcon = ({ className = '' }) => (
    <svg viewBox="0 0 512 512" className={className}>
        <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" />
        <path
            d="m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0"
            fill="#fff"
        />
    </svg>
);

const SearchIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="search"
        width="20"
        height="20"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
    </svg>
);

const VerticalDots = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="more"
        width="16"
        height="16"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M456 231a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0z"></path>
    </svg>
);

const EnvelopeIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="mail"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0068.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path>
    </svg>
);

const UserIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="user"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
    </svg>
);

const BackArrow = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="left"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
    </svg>
);

const FilterIcon = () => (
    <svg width="30" height="30" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
        <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>
);

const NoDataIcon = () => (
    <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41">
        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
            <ellipse
                className="ant-empty-img-simple-ellipse"
                cx="32"
                cy="33"
                rx="32"
                ry="7"
            ></ellipse>
            <g className="ant-empty-img-simple-g" fillRule="nonzero">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    className="ant-empty-img-simple-path"
                ></path>
            </g>
        </g>
    </svg>
);

const LeftArrowIcon = () => (
    <svg width="20" height="18" fill="none" viewBox="0 0 20 18">
        <path
            fill="#000"
            d="M.293 8.293l4-4a1 1 0 111.414 1.414L3.414 8H15a1 1 0 110 2H3.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414zM18 1v16a1 1 0 002 0V1a1 1 0 00-2 0z"
        />
    </svg>
);

const LeftBarPlusIcon = () => (
    <svg width="12" height="12" fill="none" viewBox="0 0 12 12">
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M5.111 5.111V1.556a.889.889 0 011.778 0V5.11h3.555a.889.889 0 010 1.778H6.89v3.555a.889.889 0 01-1.778 0V6.89H1.556a.889.889 0 010-1.778H5.11z"
            clipRule="evenodd"
        />
    </svg>
);

const Dashboard = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="dashboard"
        width="22"
        height="22"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M924.8 385.6a446.7 446.7 0 00-96-142.4 446.7 446.7 0 00-142.4-96C631.1 123.8 572.5 112 512 112s-119.1 11.8-174.4 35.2a446.7 446.7 0 00-142.4 96 446.7 446.7 0 00-96 142.4C75.8 440.9 64 499.5 64 560c0 132.7 58.3 257.7 159.9 343.1l1.7 1.4c5.8 4.8 13.1 7.5 20.6 7.5h531.7c7.5 0 14.8-2.7 20.6-7.5l1.7-1.4C901.7 817.7 960 692.7 960 560c0-60.5-11.9-119.1-35.2-174.4zM761.4 836H262.6A371.12 371.12 0 01140 560c0-99.4 38.7-192.8 109-263 70.3-70.3 163.7-109 263-109 99.4 0 192.8 38.7 263 109 70.3 70.3 109 163.7 109 263 0 105.6-44.5 205.5-122.6 276zM623.5 421.5a8.03 8.03 0 00-11.3 0L527.7 506c-18.7-5-39.4-.2-54.1 14.5a55.95 55.95 0 000 79.2 55.95 55.95 0 0079.2 0 55.87 55.87 0 0014.5-54.1l84.5-84.5c3.1-3.1 3.1-8.2 0-11.3l-28.3-28.3zM490 320h44c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8h-44c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8zm260 218v44c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8v-44c0-4.4-3.6-8-8-8h-80c-4.4 0-8 3.6-8 8zm12.7-197.2l-31.1-31.1a8.03 8.03 0 00-11.3 0l-56.6 56.6a8.03 8.03 0 000 11.3l31.1 31.1c3.1 3.1 8.2 3.1 11.3 0l56.6-56.6c3.1-3.1 3.1-8.2 0-11.3zm-458.6-31.1a8.03 8.03 0 00-11.3 0l-31.1 31.1a8.03 8.03 0 000 11.3l56.6 56.6c3.1 3.1 8.2 3.1 11.3 0l31.1-31.1c3.1-3.1 3.1-8.2 0-11.3l-56.6-56.6zM262 530h-80c-4.4 0-8 3.6-8 8v44c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8v-44c0-4.4-3.6-8-8-8z"></path>
    </svg>
);

const Contact = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="contacts"
        width="22"
        height="22"
        fill="none"
        aria-hidden="true"
    >
        <path d="M594.3 601.5a111.8 111.8 0 0029.1-75.5c0-61.9-49.9-112-111.4-112s-111.4 50.1-111.4 112c0 29.1 11 55.5 29.1 75.5a158.09 158.09 0 00-74.6 126.1 8 8 0 008 8.4H407c4.2 0 7.6-3.3 7.9-7.5 3.8-50.6 46-90.5 97.2-90.5s93.4 40 97.2 90.5c.3 4.2 3.7 7.5 7.9 7.5H661a8 8 0 008-8.4c-2.8-53.3-32-99.7-74.7-126.1zM512 578c-28.5 0-51.7-23.3-51.7-52s23.2-52 51.7-52 51.7 23.3 51.7 52-23.2 52-51.7 52zm416-354H768v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H548v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H328v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H96c-17.7 0-32 14.3-32 32v576c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32zm-40 568H136V296h120v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h120v496z"></path>
    </svg>
);

const EmailTemplate = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="group"
        width="22"
        height="22"
        fill="currentColor"
        aria-hidden="true"
    >
        <defs>
            <style></style>
        </defs>
        <path d="M912 820.1V203.9c28-9.9 48-36.6 48-67.9 0-39.8-32.2-72-72-72-31.3 0-58 20-67.9 48H203.9C194 84 167.3 64 136 64c-39.8 0-72 32.2-72 72 0 31.3 20 58 48 67.9v616.2C84 830 64 856.7 64 888c0 39.8 32.2 72 72 72 31.3 0 58-20 67.9-48h616.2c9.9 28 36.6 48 67.9 48 39.8 0 72-32.2 72-72 0-31.3-20-58-48-67.9zM888 112c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zM136 912c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-752c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm704 680H184V184h656v656zm48 72c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24z"></path>
        <path d="M288 474h448c8.8 0 16-7.2 16-16V282c0-8.8-7.2-16-16-16H288c-8.8 0-16 7.2-16 16v176c0 8.8 7.2 16 16 16zm56-136h336v64H344v-64zm-56 420h448c8.8 0 16-7.2 16-16V566c0-8.8-7.2-16-16-16H288c-8.8 0-16 7.2-16 16v176c0 8.8 7.2 16 16 16zm56-136h336v64H344v-64z"></path>
    </svg>
);

const CampaignIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="line-chart"
        width="22"
        height="22"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 00-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 00-11.3 0L266.3 586.7a8.03 8.03 0 000 11.3l39.5 39.7z"></path>
    </svg>
);

const IntegrationIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="api"
        width="22"
        height="22"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M917.7 148.8l-42.4-42.4c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-76.1 76.1a199.27 199.27 0 00-112.1-34.3c-51.2 0-102.4 19.5-141.5 58.6L432.3 308.7a8.03 8.03 0 000 11.3L704 591.7c1.6 1.6 3.6 2.3 5.7 2.3 2 0 4.1-.8 5.7-2.3l101.9-101.9c68.9-69 77-175.7 24.3-253.5l76.1-76.1c3.1-3.2 3.1-8.3 0-11.4zM769.1 441.7l-59.4 59.4-186.8-186.8 59.4-59.4c24.9-24.9 58.1-38.7 93.4-38.7 35.3 0 68.4 13.7 93.4 38.7 24.9 24.9 38.7 58.1 38.7 93.4 0 35.3-13.8 68.4-38.7 93.4zm-190.2 105a8.03 8.03 0 00-11.3 0L501 613.3 410.7 523l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3L441 408.6a8.03 8.03 0 00-11.3 0L363 475.3l-43-43a7.85 7.85 0 00-5.7-2.3c-2 0-4.1.8-5.7 2.3L206.8 534.2c-68.9 69-77 175.7-24.3 253.5l-76.1 76.1a8.03 8.03 0 000 11.3l42.4 42.4c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l76.1-76.1c33.7 22.9 72.9 34.3 112.1 34.3 51.2 0 102.4-19.5 141.5-58.6l101.9-101.9c3.1-3.1 3.1-8.2 0-11.3l-43-43 66.7-66.7c3.1-3.1 3.1-8.2 0-11.3l-36.6-36.2zM441.7 769.1a131.32 131.32 0 01-93.4 38.7c-35.3 0-68.4-13.7-93.4-38.7a131.32 131.32 0 01-38.7-93.4c0-35.3 13.7-68.4 38.7-93.4l59.4-59.4 186.8 186.8-59.4 59.4z"></path>
    </svg>
);

const SettingIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="setting"
        width="22"
        height="22"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 009.3-35.2l-.9-2.6a443.74 443.74 0 00-79.7-137.9l-1.8-2.1a32.12 32.12 0 00-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 00-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 00-25.8 25.7l-15.8 85.4a351.86 351.86 0 00-99 57.4l-81.9-29.1a32 32 0 00-35.1 9.5l-1.8 2.1a446.02 446.02 0 00-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 00-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0035.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0025.8 25.7l2.7.5a449.4 449.4 0 00159 0l2.7-.5a32.05 32.05 0 0025.8-25.7l15.7-85a350 350 0 0099.7-57.6l81.3 28.9a32 32 0 0035.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 01-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 01-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 01512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 01400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 01624 502c0 29.9-11.7 58-32.8 79.2z"></path>
    </svg>
);

const TextElementIcon = () => (
    <svg viewBox="0 0 30 30">
        <g id="Group_4848" data-name="Group 4848" transform="translate(-15 -146)">
            <rect
                id="Rectangle_659"
                data-name="Rectangle 659"
                width="30"
                height="30"
                rx="5"
                transform="translate(15 176) rotate(-90)"
                fill="none"
                stroke="none"
            />
            <g id="Text" transform="translate(21 152)">
                <path
                    id="Path_226"
                    data-name="Path 226"
                    d="M4,7.466V4H22.75V7.466"
                    transform="translate(-4 -4)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_240"
                    data-name="Line 240"
                    x2="10.519"
                    transform="translate(4.115 18.75)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_241"
                    data-name="Line 241"
                    y2="18.75"
                    transform="translate(8.75 0)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const MediaElementIcon = () => (
    // <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path
    //         d="M4.51905 20.0927L4.25338 20.3504H4.62348H20.9598H21.1098V20.2004V15.2187V15.1552L21.0642 15.111L17.7042 11.8521L17.5998 11.7508L17.4954 11.8521L12.6872 16.5155C12.6872 16.5155 12.6872 16.5156 12.6872 16.5156C12.5056 16.6916 12.2585 16.7913 11.9998 16.7913C11.7411 16.7913 11.494 16.6916 11.3124 16.5156C11.3124 16.5156 11.3124 16.5155 11.3124 16.5155L9.86424 15.111L9.7598 15.0097L9.65537 15.111L4.51905 20.0927ZM21.1098 2.81979V2.66979H20.9598H3.0398H2.8898V2.81979V18.6644V19.0188L3.14424 18.772L9.07238 13.0223C9.07239 13.0223 9.07239 13.0223 9.0724 13.0223C9.25397 12.8463 9.50115 12.7466 9.7598 12.7466C10.0185 12.7466 10.2656 12.8463 10.4472 13.0223C10.4472 13.0223 10.4472 13.0223 10.4472 13.0223L11.8954 14.4269L11.9998 14.5282L12.1042 14.4269L16.9124 9.76348C16.9124 9.76348 16.9124 9.76347 16.9124 9.76347C17.094 9.58742 17.3411 9.48774 17.5998 9.48774C17.8585 9.48774 18.1056 9.58742 18.2872 9.76347C18.2872 9.76347 18.2872 9.76348 18.2872 9.76348L20.8554 12.2543L21.1098 12.5011V12.1466V2.81979ZM22.0798 22.2229H1.9198C1.66109 22.2229 1.41386 22.1232 1.23228 21.9471C1.05085 21.7711 0.949805 21.5335 0.949805 21.2867V1.7335C0.949805 1.48669 1.05085 1.24902 1.23228 1.07306C1.41386 0.89694 1.66109 0.797217 1.9198 0.797217H22.0798C22.3385 0.797217 22.5857 0.89694 22.7673 1.07306C22.9488 1.24902 23.0498 1.48669 23.0498 1.7335V21.2867C23.0498 21.5335 22.9488 21.7711 22.7673 21.9471C22.5857 22.1232 22.3385 22.2229 22.0798 22.2229ZM5.4298 7.70808C5.4298 7.20167 5.58461 6.70623 5.87521 6.28441C6.16586 5.86252 6.57945 5.53302 7.06421 5.33827C7.549 5.14351 8.0827 5.09248 8.59764 5.19183C9.11256 5.29117 9.58494 5.53627 9.95527 5.89545C10.3256 6.25459 10.5772 6.71159 10.6791 7.2084C10.7809 7.70519 10.7287 8.22017 10.5287 8.68843C10.3287 9.15674 9.9897 9.55776 9.55382 9.84024C9.1179 10.1227 8.60493 10.2738 8.0798 10.2738C7.37553 10.2738 6.70098 10.0024 6.20434 9.52071C5.70785 9.03916 5.4298 8.38704 5.4298 7.70808Z"
    //         // fill=""
    //         stroke="#ECF3FF"
    //         stroke-width="0.3"
    //     />
    // </svg>
    <svg viewBox="0 0 30 30">
        <g id="Group_4850" data-name="Group 4850" transform="translate(-15 -285)">
            <rect
                id="Rectangle_661"
                data-name="Rectangle 661"
                width="30"
                height="30"
                rx="5"
                transform="translate(15 315) rotate(-90)"
                fill="none"
                stroke="none"
            />
            <g id="Media" transform="translate(22 292)">
                <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    width="16.667"
                    height="16.667"
                    rx="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <circle
                    id="Ellipse_29"
                    data-name="Ellipse 29"
                    cx="0.556"
                    cy="0.556"
                    r="0.556"
                    transform="translate(4.537 4.537)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M19.815,14.63,15.185,10,5,20.185"
                    transform="translate(-3.148 -3.519)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

const CtaElementIcon = () => (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.4 0C21.4872 0 24 3.141 24 7C24 10.859 21.4872 14 18.4 14H5.6C2.5128 14 0 10.859 0 7C0 3.141 2.5128 0 5.6 0H18.4ZM18.4 2H5.6C3.3944 2 1.6 4.243 1.6 7C1.6 9.757 3.3944 12 5.6 12H18.4C20.6056 12 22.4 9.757 22.4 7C22.4 4.243 20.6056 2 18.4 2ZM17.6 6V8H6.4V6H17.6Z"
            fill="#737374"
        />
    </svg>
);

const AddElementIcon = () => (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none">
        <path
            d="M20.7747 0.5H6.64434C4.8605 0.5 3.92729 1.41959 3.92729 3.20078V7.84016C4.19982 7.79873 4.47235 7.77388 4.74488 7.77388C5.02567 7.77388 5.2982 7.79873 5.57074 7.84844V3.3499C5.57074 2.5463 5.99192 2.14864 6.75171 2.14864H20.6673C21.4271 2.14864 21.8483 2.5463 21.8483 3.3499V13.134C21.8483 13.9376 21.4271 14.3353 20.6673 14.3353H10.0303C9.93124 14.9235 9.70826 15.4868 9.40269 15.9839H20.7747C22.5668 15.9839 23.5 15.056 23.5 13.2831V3.20078C23.5 1.42788 22.5668 0.5 20.7747 0.5ZM4.75314 17.5C7.06553 17.5 9.00628 15.5614 9.00628 13.2251C9.00628 10.8889 7.08205 8.95858 4.75314 8.95858C2.42424 8.95858 0.5 10.8889 0.5 13.2251C0.5 15.5697 2.42424 17.5 4.75314 17.5ZM2.0526 13.2251C2.0526 12.8855 2.28384 12.6535 2.62244 12.6535H4.1833V11.096C4.1833 10.7563 4.40628 10.5244 4.75314 10.5244C5.1 10.5244 5.32298 10.7481 5.32298 11.096V12.6535H6.88384C7.22244 12.6535 7.44542 12.8855 7.44542 13.2251C7.44542 13.5731 7.22244 13.7968 6.88384 13.7968H5.32298V15.3709C5.32298 15.7105 5.1 15.9342 4.75314 15.9342C4.40628 15.9342 4.1833 15.7105 4.1833 15.3709V13.7968H2.62244C2.28384 13.7968 2.0526 13.5731 2.0526 13.2251Z"
            fill="#737374"
        />
    </svg>
);

const Undo = ({ stroke = '#202124' }) => (
    <svg viewBox="0 0 15.5 15.811">
        <g id="corner-down-left" transform="translate(0.75 1.061)">
            <path
                id="Path_71"
                data-name="Path 71"
                d="M8.375,18.75,4,14.375,8.375,10"
                transform="translate(-4 -10)"
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_72"
                data-name="Path 72"
                d="M18,13.625V7.5A3.5,3.5,0,0,0,14.5,4H4"
                transform="translate(-4 0.375)"
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const Redo = ({ stroke = '#202124' }) => (
    <svg viewBox="0 0 15.5 15.811">
        <g id="corner-down-right" transform="translate(0.75 1.061)">
            <path
                id="Path_73"
                data-name="Path 73"
                d="M15,18.75l4.375-4.375L15,10"
                transform="translate(-5.375 -10)"
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_74"
                data-name="Path 74"
                d="M4,13.625V7.5A3.5,3.5,0,0,1,7.5,4H18"
                transform="translate(-4 0.375)"
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

const AllSaved = () => (
    <svg viewBox="0 0 17.009 16.762">
        <g
            id="Icon_feather-check-circle"
            data-name="Icon feather-check-circle"
            transform="translate(-2.051 -2.081)"
        >
            <path
                id="Path_4716"
                data-name="Path 4716"
                d="M18,9.8v.69A7.5,7.5,0,1,1,13.552,3.64"
                fill="none"
                stroke="#1657cd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                id="Path_4717"
                data-name="Path 4717"
                d="M23.249,6l-7.5,7.507-2.25-2.25"
                transform="translate(-5.25 -1.505)"
                fill="none"
                stroke="#1657cd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export {
    AppleIcon,
    GoogleIcon,
    PrevIcon,
    DownArrowIcon,
    PlusIcon,
    MinusIcon,
    BoldIcon,
    ItalicIcon,
    UnderlineIcon,
    UppercaseIcon,
    TextAlignIcon,
    CenterAligmentIcon,
    LeftAligmentIcon,
    RightAligmentIcon,
    MoveIcon,
    PaddingAll,
    PaddingSingle,
    DelIcon,
    CopyIcon,
    UpArrow,
    DownArrow,
    PaddingAllSidesIcon,
    PaddingEachSideIcon,
    PaddingLeftIcon,
    PaddingRightIcon,
    PaddingBottomIcon,
    PaddingTopIcon,
    CornerRadiusSelected,
    CornerRadiusCurves,
    CornerRadiusleftTop,
    CornerRadiusRightTop,
    CornerRadiusRightBottom,
    CornerRadiusLeftBottom,
    AllBorders,
    BorderBottom,
    BorderLeft,
    BorderRight,
    BorderTop,
    LeftAlign,
    AlignCenter,
    RightAlign,
    TitleCaseIcon,
    SmallCapsIcon,
    SentenceCaseIcon,
    SaveButton,
    Edit,
    ImageUploadIcon,
    AddMediaIcon,
    MoreIcon,
    GifsMediaIcon,
    CheckIcon,
    SearchIcon,
    VerticalDots,
    EnvelopeIcon,
    UserIcon,
    BackArrow,
    FilterIcon,
    NoDataIcon,
    LeftArrowIcon,
    LeftBarPlusIcon,
    Dashboard,
    Contact,
    EmailTemplate,
    CampaignIcon,
    IntegrationIcon,
    SettingIcon,
    TextElementIcon,
    MediaElementIcon,
    CtaElementIcon,
    AddElementIcon,
    Undo,
    Redo,
    AllSaved,
};
