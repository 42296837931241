import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadSingleContact, loadSingleContactList } from 'redux/actions/contactListAction';
import {
    ParentWrapper,
    Title,
    TitleWrapper,
    HeaderWrapper,
    ListWrapper,
    LinkWrapper,
    ModalWrapper,
} from './Styles';
import { Spin, Menu, Dropdown, Modal, Input } from 'antd';
import {
    LoadingOutlined,
    UnorderedListOutlined,
    NodeIndexOutlined,
    DownOutlined,
} from '@ant-design/icons';
import { singleContactTabBtns, singleContactTableColumn } from '../../constants/ContactListTable';
import * as STRINGS from 'app/language/en.lang';
import { SearchIcon, VerticalDots, BackArrow, FilterIcon, NoDataIcon } from 'app/constants/icons';

const {
    LIST,
    CREATE,
    CREATE_CONTACT,
    CREATE_FIELDS,
    CONTACTS,
    BACK_TO_ALL_LIST,
    CUSTOM_FIELDS,
    PERCENT_OF_MEMBERS,
    NO_DATA,
    LIST_NAME,
    UPDATE_CONTACT_LIST,
    DELETE_CONTACT_LIST,
    DEFAULT_ERROR_MESSAGE,
} = STRINGS.CONTACT_LIST_DASHBOARD;

const { SETTINGS } = STRINGS.LEFT_NAVBAR;

const SingleContactList = (props) => {
    const { id } = props.match.params;
    let { t } = useTranslation('translation');

    const history = useHistory();
    // const location = useLocation();

    const dispatch = useDispatch();
    const contactData = useSelector((state) => state?.contactList?.singleContact ?? {});
    const contactDataList = useSelector((state) => state?.contactList?.singleContactList ?? []);

    const updateInput = useRef();

    const [listType, setListType] = useState('ALL');
    const [listTypeError, setListTypeError] = useState(false);
    const [loadingContacts, setLoadingContacts] = useState(true);
    const [tableLoadingState, setTableLoadingState] = useState(true);
    const [listName, setListName] = useState('');
    const [activeTab, setActiveTab] = useState('all');
    const [confirmCreateLoading, setConfirmCreateLoading] = useState(false);
    const [isCreateModelOpen, setIsCreateModelOpen] = useState(false);
    const [contactCategoryCount, setContactCategoryCount] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [hiddenColumn, setHiddenColumn] = useState([]);

    const handelAddContactEvent = (e) => {
        if (e.key === 'newContact') {
            // setIsCreateModelOpen(true);
        }
    };

    const handelEditContactEvent = (key) => {
        // console.log(key);
    };

    const handleCheckBoxChange = (e, column) => {
        let val = [];
        if (!e.target.checked) {
            val.push(column);
            setHiddenColumn((hiddenColumn) => [...hiddenColumn, ...val]);
        } else {
            const removeNumber = (hiddenColumn, column) => {
                let temp = hiddenColumn.filter((el) => el !== column);
                return temp;
            };
            let newArr = removeNumber(hiddenColumn, column);
            setHiddenColumn(newArr);
        }
    };

    // create list drop down
    const createContactMenu = (
        <Menu onClick={handelAddContactEvent}>
            <Menu.Item key="newContact" icon={<UnorderedListOutlined />}>
                <Link to={'/contact/add-contact'}>{t(CREATE_CONTACT)}</Link>
            </Menu.Item>
            <Menu.Item key="newFields" icon={<NodeIndexOutlined />}>
                {t(CREATE_FIELDS)}
            </Menu.Item>
        </Menu>
    );

    // create list drop down
    const editContactMenu = (
        <Menu onClick={handelEditContactEvent}>
            <Menu.Item key="deletecontact" icon={<UnorderedListOutlined />}>
                {t(UPDATE_CONTACT_LIST)}
            </Menu.Item>
            <Menu.Item key="updatecontact" icon={<NodeIndexOutlined />}>
                {t(DELETE_CONTACT_LIST)}
            </Menu.Item>
        </Menu>
    );

    const tableColumns = (
        <Menu>
            {singleContactTableColumn.map((Column, index) => (
                <Menu.Item key={Column.key}>
                    <div
                        className={'column-sort-options'}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            type="checkbox"
                            name="columns"
                            style={{ marginRight: '10px', marginBottom: '0' }}
                            disabled={index == 0 ? true : false}
                            defaultChecked={Column?.checked ? true : false}
                            onChange={(e) => {
                                handleCheckBoxChange(e, Column.key);
                            }}
                        />
                        <span>{Column.title}</span>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    const loadContactData = (value) => {
        setActiveTab(value);
    };

    //close create model function
    const handleCreateCancel = () => {
        setIsCreateModelOpen(false);
        setConfirmCreateLoading(false);
    };

    //Submit Update Model
    const handleCreateOk = () => {
        const newlist = updateInput.current.state.value;

        if (newlist != '') {
            setConfirmCreateLoading(true);
            const finishCreate = () => {
                setIsCreateModelOpen(false);
                setConfirmCreateLoading(false);
            };
        }
    };

    const changeListType = (id, listType) => {
        const callback = () => {
            setLoadingContacts(false);
            setTableLoadingState(false);
        };
        dispatch(loadSingleContactList({ id, listType, callback }));
    };

    const fetchSingleContact = () => {
        const callback = (error = null) => {
            if (error) {
                setListTypeError(true);
            }
            setLoadingContacts(false);
            changeListType(id, listType);
        };
        dispatch(loadSingleContact({ id, callback }));
    };

    useEffect(() => {
        setLoadingContacts(true);
        setTableLoadingState(true);
        fetchSingleContact();
    }, []);

    useEffect(() => {
        if (contactData && contactData.name) {
            let tempArr = [];
            let arr = [
                'contactCount',
                'subscriberCount',
                'unSubscribedCount',
                'bouncedCount',
                'archivedCount',
            ];
            arr.forEach((item) => tempArr.push(contactData[item]));
            setContactCategoryCount(tempArr);
            setListName(contactData.name);
        }
    }, [contactData]);

    useEffect(() => {
        if (
            Array.isArray(contactDataList.list) &&
            contactDataList?.list.length > 0 &&
            !tableLoadingState
        ) {
            let listData = contactDataList.list.map((data) => {
                return (
                    <tr key={data._id}>
                        <td
                            key={singleContactTableColumn[0].key}
                            style={{
                                display: hiddenColumn.includes(singleContactTableColumn[0].key)
                                    ? 'none'
                                    : 'table-cell',
                            }}
                        >
                            <div className={'list-name action-btn-block'}>
                                <Dropdown overlay={editContactMenu}>
                                    <span className={'contact-action-btn'}>
                                        <VerticalDots />
                                    </span>
                                </Dropdown>
                                <span className={'contact-email'}>{data.emailId}</span>
                            </div>
                        </td>
                        <td
                            key={singleContactTableColumn[1].key}
                            style={{
                                display: hiddenColumn.includes(singleContactTableColumn[1].key)
                                    ? 'none'
                                    : 'table-cell',
                            }}
                        >
                            {data.firstName}
                        </td>
                        <td
                            key={singleContactTableColumn[2].key}
                            style={{
                                display: hiddenColumn.includes(singleContactTableColumn[2].key)
                                    ? 'none'
                                    : 'table-cell',
                            }}
                        >
                            {data.lastName}
                        </td>
                        <td
                            key={singleContactTableColumn[3].key}
                            style={{
                                display: hiddenColumn.includes(singleContactTableColumn[3].key)
                                    ? 'none'
                                    : 'table-cell',
                            }}
                        >
                            {data.phoneNumber}
                        </td>
                        <td
                            key={singleContactTableColumn[4].key}
                            style={{
                                display: hiddenColumn.includes(singleContactTableColumn[4].key)
                                    ? 'none'
                                    : 'table-cell',
                            }}
                        >
                            {data.createdAt}
                        </td>
                    </tr>
                );
            });

            setContactList(listData);
            setLoadingContacts(false);
            setTableLoadingState(false);
        }

        if (
            Array.isArray(contactDataList?.list) &&
            contactDataList?.list.length == 0 &&
            !tableLoadingState
        ) {
            setLoadingContacts(false);
            setTableLoadingState(false);
        }
    }, [contactDataList, hiddenColumn, tableLoadingState]);

    return (
        <>
            {loadingContacts && (
                <Spin
                    indicator={<LoadingOutlined type={'loading'} style={{ fontSize: '50px' }} />}
                />
            )}
            {!loadingContacts && listTypeError && <p>{t(DEFAULT_ERROR_MESSAGE)}</p>}
            {!loadingContacts && !listTypeError && (
                <ParentWrapper>
                    <div className={'breadcrumb-block'}>
                        <Link to="/contact/lists" className="db-back-link button-chevron-left">
                            <span className={'back-icon'}>
                                <BackArrow />
                            </span>
                            {t(BACK_TO_ALL_LIST)}
                        </Link>
                        <span> / </span>
                        <span>{listName ? listName : LIST}</span>
                    </div>
                    <HeaderWrapper>
                        <TitleWrapper className={'flex-title-row'}>
                            <Title>{t(CONTACTS)}</Title>
                            <div className={'edit-name-block'}>
                                <span className={'search-icon-btn'}>{<SearchIcon />}</span>
                                <input type="search" name="search" placeholder="Search.." />
                            </div>
                        </TitleWrapper>
                        <LinkWrapper>
                            <Link to={'#'}>{t(SETTINGS)}</Link>
                            <Link to={'/contact/custom-fields'}>{t(CUSTOM_FIELDS)}</Link>
                            <Link to={history.location.pathname}>{t(CONTACTS)}</Link>
                            <Dropdown overlay={createContactMenu}>
                                <button>
                                    {t(CREATE)} <DownOutlined />
                                </button>
                            </Dropdown>
                        </LinkWrapper>
                    </HeaderWrapper>
                    <div>
                        <div>
                            <div>
                                <ListWrapper>
                                    {singleContactTabBtns.map((listValue, index) => {
                                        let calc =
                                            (contactCategoryCount[index] /
                                                contactCategoryCount[0]) *
                                            100;
                                        return (
                                            <li
                                                key={listValue.key}
                                                className={
                                                    listValue.key == activeTab
                                                        ? 'tab-btn active-tab-btn ' + listValue.key
                                                        : 'tab-btn ' + listValue.key
                                                }
                                                onClick={() => {
                                                    setTableLoadingState(true);
                                                    loadContactData(listValue.key);
                                                    setListType(listValue.type);
                                                    changeListType(id, listValue.type);
                                                }}
                                            >
                                                <span>{listValue.title}</span>
                                                <span className={'count'}>
                                                    {contactCategoryCount[index]
                                                        ? contactCategoryCount[index]
                                                        : 0}
                                                </span>
                                                <span className={'count-ratio'}>
                                                    {contactCategoryCount[0] !== 0 ? calc : 0}
                                                    {t(PERCENT_OF_MEMBERS)}
                                                </span>
                                            </li>
                                        );
                                    })}
                                    <li className={'tab-btn setting-btn'}>
                                        <Dropdown overlay={tableColumns}>
                                            <button>
                                                <FilterIcon />
                                            </button>
                                        </Dropdown>
                                    </li>
                                </ListWrapper>
                                <div className={'table-content'}>
                                    <table>
                                        <thead>
                                            <tr>
                                                {singleContactTableColumn.map((Column) => (
                                                    <th
                                                        key={Column.key}
                                                        style={{
                                                            display: hiddenColumn.includes(
                                                                Column.key,
                                                            )
                                                                ? 'none'
                                                                : 'table-cell',
                                                        }}
                                                    >
                                                        {Column.title}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableLoadingState ? (
                                                <tr>
                                                    <td colSpan="5">
                                                        <Spin
                                                            indicator={
                                                                <LoadingOutlined
                                                                    type={'loading'}
                                                                    style={{ fontSize: '50px' }}
                                                                />
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ) : null}
                                            {Array.isArray(contactDataList?.list) &&
                                            contactDataList?.list.length > 0 &&
                                            !tableLoadingState
                                                ? contactList
                                                : null}
                                            {Array.isArray(contactDataList?.list) &&
                                            contactDataList?.list.length == 0 &&
                                            !tableLoadingState ? (
                                                <tr className={'empty-table'}>
                                                    <td colSpan="5">
                                                        <div className="ant-empty ant-empty-normal">
                                                            <div className="ant-empty-image">
                                                                <NoDataIcon />
                                                            </div>
                                                            <div className="ant-empty-description">
                                                                {t(NO_DATA)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    </table>
                                    <Modal
                                        title={'Create Contact'}
                                        visible={isCreateModelOpen}
                                        onOk={handleCreateOk}
                                        confirmLoading={confirmCreateLoading}
                                        onCancel={handleCreateCancel}
                                    >
                                        {/* <ModalWrapper> */}
                                        <Input ref={updateInput} placeholder={t(LIST_NAME)} />
                                        {/* {errorState ? (
                                            <p className={'error-message'}>{errorMessage}</p>
                                        ) : null} */}
                                        {/* </ModalWrapper> */}
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParentWrapper>
            )}
        </>
    );
};

export default withRouter(SingleContactList);
