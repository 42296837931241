import React, { Suspense } from 'react';
import Loader from './Loader';

const LazyComponent = (Component) => () =>
    (
        <div>
            <Suspense fallback={<Loader />}>
                <Component />
            </Suspense>
        </div>
    );

export default LazyComponent;
