import { changePassword } from 'app/services/auth.service';
import React, { useState } from 'react';
import { showNotification } from '_helpers';
import { useInput } from 'app/hooks/useInput';
import { Heading, ProfileSettingIn, Label, Input, SaveChanges } from './ProfileCommonStyle';
import { USER_PROFILE, PROFILE_NOTIFICATIONS } from 'app/language/en.lang';
const {
    PASSWORD_UPDATED,
    PASSWORD_SUCCESSFULLY_UPDATED,
    ERROR,
    ERR_UPDATING_PROFILE,
    DIFFERENT_PASSWORD,
    INCOMPLETE_DETAILS,
} = PROFILE_NOTIFICATIONS;
const { CHANGE_PASSWORD, CURRENT_PASSWORD, CONFIRM_PASSWORD, NEW_PASSWORD, UPDATE_PASSWORD } =
    USER_PROFILE;

const ChangePassword = () => {
    const [userOldPwd, setOldPwd, resetOldPwd] = useInput('');
    const [userNewPwd, setNewPwd, resetNewPwd] = useInput('');
    const [userConfirmPwd, setConfirmPwd, resetConfirmPwd] = useInput('');

    const handleUpdatePassword = () => {
        let err = false;
        const oldPwd = userOldPwd.value;
        const newPwd = userNewPwd.value;
        const confirmPwd = userConfirmPwd.value;
        let comparePwd = newPwd.localeCompare(confirmPwd);

        if ((oldPwd, newPwd, confirmPwd)) {
            if (comparePwd === 0) {
                changePassword(oldPwd, newPwd)
                    .then((response) => {
                        showNotification(
                            'PasswordNameUpdated',
                            PASSWORD_UPDATED,
                            PASSWORD_SUCCESSFULLY_UPDATED,
                            'success',
                        );
                    })
                    .catch((error) => {
                        if (error) {
                            showNotification(
                                error,
                                ERROR + '!',
                                ERR_UPDATING_PROFILE + '!',
                                'error',
                            );
                        }
                    });
            } else {
                err = { DIFFERENT_PASSWORD };
            }
        } else {
            err = { INCOMPLETE_DETAILS };
        }
        if (err) {
            showNotification(err, { ERROR } + '!', err, 'error');
        }
        resetOldPwd();
        resetNewPwd();
        resetConfirmPwd();
    };

    return (
        <ProfileSettingIn>
            <Heading>{CHANGE_PASSWORD}</Heading>
            <div>
                <Label>{CURRENT_PASSWORD}</Label>
                <Input type="password" id="oldPwd" {...userOldPwd}></Input>
            </div>
            <div>
                <Label>{NEW_PASSWORD}</Label>
                <Input type="password" id="newPwd" {...userNewPwd}></Input>
            </div>
            <div>
                <Label>{CONFIRM_PASSWORD}</Label>
                <Input type="password" id="confirmPwd" {...userConfirmPwd}></Input>
            </div>
            <SaveChanges onClick={handleUpdatePassword}>{UPDATE_PASSWORD}</SaveChanges>
        </ProfileSettingIn>
    );
};

export default ChangePassword;
