import { createSlice } from '@reduxjs/toolkit';
import { loadSavedSectionTemplates } from 'redux/actions/emailAction';

const initialState = {
    savedSectionTemplates: [],
    isLoading: false,
};

const sectionTemplatesSlice = createSlice({
    name: 'sectionTemplates',
    initialState,
    reducers: {
        savedSectionsList(state, action) {
            if (Array.isArray(action.payload)) {
                state.savedSectionTemplates = action.payload;
                state.isLoading = false;
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadSavedSectionTemplates, (state) => {
            state.isLoading = true;
        });
    },
});

export const { savedSectionsList } = sectionTemplatesSlice.actions;

export default sectionTemplatesSlice.reducer;

export const selectSectionTemplates = (state) => state.sectionTemplates;
