import { createSlice } from '@reduxjs/toolkit';
import { removeOuterSection } from './emailEditorSlice';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

const activeOuterSectionIdSlice = createSlice({
    name: 'activeOuterSectionId',
    initialState: false,
    reducers: {
        makeOuterSectionActive(state, action) {
            return action.payload;
        },
        makeOuterSectionInActive() {
            return false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(removeOuterSection, () => {
                return false;
            })
            .addCase(closeTemplate, () => {
                return false;
            });
    },
});

export const { makeOuterSectionActive, makeOuterSectionInActive } =
    activeOuterSectionIdSlice.actions;

export default activeOuterSectionIdSlice.reducer;

export const selectActiveOuterSectionId = (state) => state.activeOuterSectionId;
