//All style variables
const STYLE_CONSTANTS = {
    primaryColor: '#1890ff',
    colorWhite: '#FFFFFF',
    borderHandleHeight: '6px',
    borderHandleWidth: '18px',
    borderCornerWidth: '10px',

    /*  line-height  */
    lineHeight: '0.75rem',

    /*  font-size  */
    fontSize: '1rem',
    fontSize1: '0.5rem',
    fontSize2: '0.75rem',
    fontSize3: '0.6875rem',
    fontSize4: '0.875rem',
    fontSize5: '0.9375rem',
    fontSize6: '0.9rem',
    fontSize8: '1.125rem',
    fontSize9: '1.5rem',
    fontSize10: '0.8125rem',

    /*  font family  */
    fontFamily: 'Arial',
    fontFamilyBook: 'Circular Std Book',
    fontFamilyMedium: 'Circular Std Medium',
    fontFamilyBold: 'Circular Std Bold',
    fontFamilyBlack: 'Circular Std Black',
    fontFamilyTwo: 'Playfair Display',
    fontFamilyInter: 'Inter',

    // Letter spacing
    letterSpacing: '0.125rem',

    activeFontColor: '#202124',
    generalFontColor: '#888c95',
    inlineElementColor: '#E5E5E5',

    greyOne: '#F2F2F2',
    greyDark: '#fff',
    greyTwo: '#757575',
    greyThree: '#f9f9f9',
    greyFour: '#e9e9e9',
    greyFive: '#A0A6AF',
    greySix: '#AEB2BB',
    greySeven: '#F5F5F5',
    greyEight: '#8D8D8D',
    greyNine: '#4D5669',
    greyFaint: '#566177',
    modalHeight: '65vh',
    modalFontColor: '#ABB0B9',
    searchInputBG: '#FCFCFD',
    mainInputBG: '#606879',
    bottomBorderColor: '#E5E5E5',
    placeholder: '#b5bbc7',
    red: '#d9534f',
    greybtn: '#F0F3F4',
    pureBlack: '#000',

    /*  border-radius  */
    borderRadius: '0.3125rem',
    borderRadiusOne: '0.25rem',

    /*  font-weight */
    fontWeight: '900',
    fontWeight2: '500',
    fontWeight3: '700',

    /*  font-color  */
    fontcolorblue: '#1657cd',
    fontcolorblack: '#202124',
    fontcolorwhite: '#fff',
    fontcolorgrey: '#757575',
    fontcolorlightgrey: '#5F6369',
    fontcolordarkgrey: '#474747',
    inputFontColor: '#9F9EA2',
    greyDarker: '#2A2930',
    fontcolorlightblue: '#4285f4',
    fontcolorerror: '#CD1616',
    inputFontColorGrey: '#6F7070',

    /*  background-color  */
    bgwhite: '#ffffff',
    bgblack: '#202124',
    bgmain: '#F2F2F2',
    bgwhitewrap: 'rgba(255, 255, 255, 0.85)',
    bglightblack: 'rgba(0, 0, 0, 0.5)',
    bgBlue: '#1657CD',
    bgWhiteLight: '#F0F3F4',

    /*  button  */
    bgblue: '#1657CD',
    bglightgrey: '#F0F3F4',
    bguploadleft: '#DCE6F8',
    bgdarkgrey: '#F4F4F9',
    bggreen: '#20c656',
    bgyellow: '#FFFF00',
    bglightgreen: '#58C322',

    /*  stroke  */
    svgstroke: '#757575',
    svgstrokegrey: '#e5e5e5',
    svgstrokeblue: '#1657CD',
    svgstrokeactive: '#1657CD',
    svgstrokewhite: '#ffffff',
    svgstrokeblack: '#202124',

    /*  fill */
    svgfillbg: '#F0F3F4',
    svgfillbgactive: '#0E76A8',
    svgfill: '#757575',
    svgfilllightgrey: '#e5e5e5',
    svgfillactive: '#ffffff',
    svgfillwhite: '#ffffff',
    svgfillBlack: '#202124',
    svgfillblue: '#1657CD',

    /*  padding  */
    allpadding: '1.25rem',
    allpaddingondensed: '1rem',
    allpaddingOne: '1.875rem',
    allpaddingTwo: '0.625rem',
    allpaddingThree: '0.5rem',

    /*  search  */
    searchbg: '#F0F3F4',
    searchtext: '#6F7070',
    searchplaceholder: '#6F7070',

    /*  border-color  */
    borderColor: '#E5E5E5',
    bottomBorderColor: '#E5E5E5',
    borderColorGrey: '#757575',
    borderblue: '#1657CD',
    borderLightColor: '#f2f2f2',
    borderWhiteColor: '#ffffff',
    borderlightblue: '#2863cf',

    /*  line  */
    bgline: '#1657CD',

    /*  button-background-color  */
    btnblue: '#1657CD',
    btngrey: '#F0F3F4',
    btnGoogle: '#DE5246',
    btnFB: '#3B5998',
    btnblueactive: '#1657CD',
    btnwhite: '#ffffff',
    btngreyactive: '#e5e5e5',

    /* input */
    bginput: '#F0F3F4',
    bginputblack: 'rgba(0, 0, 0, 1)',
    bginputwhite: '#ffffff',
    bginputlightblue: '#f0f3f4',

    /* select  */
    bgselect: '#F0F3F4',
    bgselectactive: '#ffffff',

    /* slider handle  */
    handle: '#1657CD',

    /*  box-shadow  */
    boxshadow: '3px 3px 6px rgba(244,244,244,0.8)',
    boxshadow1: '0 0 20px #e0e0ef',
    boxshadow2: '0 0 4px -1px rgba(0,0,0,0.5)',
    boxshadow3: '0 2px 8px rgba(0,0,0,.15)',
    boxshadow4: '0px 0px 81px -19px rgba(0,0,0,0.75)',
    boxshadow5: '6px 6px 20px #00000014',

    /*  slide navigation  */
    slidenavigation: '#757576',

    /*  progress bar  */
    progessbar: '#f0f3f4',
    progressbarhandler: '#1657CD',

    /*Leftbar Elements and Layouts */
    dodgerBlue: '#4889ff',
    zircon: '#f9fbff',
    periwinkle: '#cfe0ff',
    Zumthor: '#ecf3ff',
    Denim: '#1657cd',
    blueBayoux: '#546b81',

    //Main Canvas style
    leftBarWidth: '360px',
    rightBarWidth: '300px',
    topBarHeight: '56px',
    bottomBarHeight: '56px',
    inputHeight: '2.8125rem',

    //Education Workflow variables
    ewfBgColor: '#1657cd',
    ewfFontColor: '#fff',

    //Dashboard
    globalLeftBarWidth: '18%',
    dashboardLeftBarWidth: '75px',

    //Editor
    editorLeftbarWidth: '360px',
    editorRightarWidth: '300px',
    tinyLeftbarWidth: '56px',
    topNavbarHeight: '56px',
    editorCanvasTobarHright: '59px',

    //RightBar
    rightbarTabHeaderHeight: '40px',
};

export { STYLE_CONSTANTS };

// ****NOTE: get the name of the color from here => https://chir.ag/projects/name-that-color/#6195ED
