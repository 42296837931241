import React from 'react';
import NavBar from './NavBar';

function TopBar() {
    return (
        <div>
            <NavBar />
        </div>
    );
}

export default TopBar;
