import React from 'react';
import styled from 'styled-components';
import msLogo from 'app/assets/images/logo192.png';
import UserProfile from './UserProfile';
import { LanguageDropdown } from 'app/Components/LanguageDropdown/LanguageDropdown';
import SaveTemplate from 'app/Pages/Editor/Topbar/Components/SaveTemplate';
import EmailTitle from 'app/Pages/Editor/Topbar/Components/EmailTitle';
import UndoRedoButton from 'app/Pages/Editor/Topbar/Components/UndoRedoButton';

function NavBar() {
    return (
        <StyledNavbar>
            <LeftNav>
                <NavbarLogo>
                    <img src={msLogo} alt="MakeStories-logo-image" />
                </NavbarLogo>
                <div className="leftnav-element">
                    <EmailTitle />
                </div>
                <div className="leftnav-element">
                    <UndoRedoButton />
                </div>
                <div className="leftnav-element">
                    <SaveTemplate />
                </div>
            </LeftNav>
            <RightNav>
                <div>
                    <LanguageDropdown />
                </div>
                <UserProfile />
            </RightNav>
        </StyledNavbar>
    );
}

export default NavBar;

// Styled Components
const StyledNavbar = styled.header`
    background: #ffffff;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-bottom: 0.0625rem solid #e5e5e5;
`;

const LeftNav = styled.div`
    display: flex;
    align-items: center;

    .leftnav-element {
        padding: 1px 0;
    }

    .leftnav-element:not(:last-child) {
        padding: 0 5px;
    }

    .leftnav-element:last-child {
        padding-left: 5px;
    }
`;

const NavbarLogo = styled.div`
    width: 50px;
    height: 50px;
    padding-right: 5px;

    & > img {
        width: 100%;
        height: 100%;
    }
`;

const RightNav = styled.div`
    display: flex;
    align-items: center;
`;
