import { createSlice } from '@reduxjs/toolkit';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

const INITIAL_STATE = {
    loading: false,
    images: [],
    toSkip: 0,
    error: false,
    searchTerm: '',
    noImagesFound: false,
};

const unsplashImagesSlice = createSlice({
    name: 'unsplashImages',
    initialState: INITIAL_STATE,
    reducers: {
        loadImages(state, action) {
            state.loading = true;
            state.error = false;
            state.noImagesFound = false;
        },
        loadError(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.noImagesFound = false;
        },
        loadSuccess(state, action) {
            const images = action.payload;
            if (images && Array.isArray(images) && images.length) {
                state.loading = false;
                state.toSkip = state.toSkip + images.length;
                state.images = [...state.images, ...images];
                state.error = false;
                state.noImagesFound = false;
            } else {
                state.noImagesFound = true;
            }
        },
        updateSearch(state, action) {
            if (action.payload || action.payload === '') {
                state.searchTerm = action.payload;
                state.noImagesFound = false;
            }
        },
        emptyImages(state, action) {
            state.images = [];
            state.toSkip = 0;
            state.error = false;
            state.noImagesFound = false;
        },
        noImagesFound(state, action) {
            state.noImagesFound = action.payload;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(closeTemplate, () => {
            return INITIAL_STATE;
        });
    },
});

export const { loadImages, loadError, loadSuccess, updateSearch, emptyImages, noImagesFound } =
    unsplashImagesSlice.actions;

export default unsplashImagesSlice.reducer;

export const selectUnsplashImages = (state) => state.unsplashImages;
