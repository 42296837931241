import { createSlice } from '@reduxjs/toolkit';

const templateIdSlice = createSlice({
    name: 'templateId',
    initialState: false,
    reducers: {
        openTemplate(store, action) {
            return action.payload;
        },
        closeTemplate() {
            return false;
        },
    },
});

export const { openTemplate, closeTemplate } = templateIdSlice.actions;

export default templateIdSlice.reducer;

export const selectTemplateId = (state) => state.templateId;
