import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { LOGIN_PAGE } from 'app/language/en.lang';
import { signup } from 'app/services/auth.service';
import { PrevIcon } from 'app/constants/icons';
import { useInput } from 'app/hooks/useInput';

// Common components of login
import MeLogo from 'app/Pages/Login/Components/MeLogo';
import SigninWithGoogleC from 'app/Pages/Login/Components/signInWithGoogle';
import SigninWithAppleC from 'app/Pages/Login/Components/signInWithApple';
import BackgroundCarousel from 'app/Pages/Login/Components/BackgroundCarousel';

// Styled components import
import {
    FormWrapper,
    FormMainIn,
    Heading,
    Paragraph,
    LoginSeparator,
    LoginSeparatorIn,
    LoginSocial,
    DualLinks,
    InputTag,
    ButtonTag,
    LabelTag,
    ErrorMsg,
    BackButton,
    BackButtonWrap,
} from './loginCommonStyle';

const {
    SIGN_IN,
    USERNAME,
    SIGN_UP,
    START_BUILDING_STORIES,
    EMAIL_ADD,
    PASSWORD,
    SIGN_UP_FOR_FREE,
    OR,
    BACK,
} = LOGIN_PAGE;

function SignUp() {
    const [error, setError] = useState(false);
    const [userEmail, setEmail, resetEmail] = useInput('');
    const [userPassword, setPassword, resetPassword] = useInput('');
    const [userUsername, setUsername, resetUsername] = useInput('');

    const handleCallback = (childData) => {
        setError(childData);
    };

    function handleSubmitSignUp(e) {
        e.preventDefault();
        const email = userEmail.value;
        const password = userPassword.value;

        signup(email, password)
            .then(() => {
                swal(
                    `Please verify your email address to continue using makestories. We just sent a mail to your email address : ${email}`,
                );
            })
            .catch((error) => {
                let errMessage = error.message;
                setError(errMessage);
            });

        resetEmail();
        resetPassword();
        resetUsername();
    }

    return (
        <div className="login">
            <BackgroundCarousel />
            <FormWrapper>
                <BackButtonWrap>
                    <Link to="/login">
                        <BackButton>
                            <PrevIcon />
                            {BACK}
                        </BackButton>
                    </Link>
                </BackButtonWrap>
                <MeLogo />
                <FormMainIn>
                    <div>
                        <Heading>{SIGN_UP}</Heading>
                        <Paragraph>{START_BUILDING_STORIES}</Paragraph>
                    </div>
                    <form onSubmit={handleSubmitSignUp}>
                        <div>
                            <LabelTag>{USERNAME}</LabelTag>
                            <InputTag
                                type="user"
                                id="username"
                                placeholder="Username"
                                {...userUsername}
                            ></InputTag>
                        </div>
                        <div>
                            <LabelTag>{EMAIL_ADD}</LabelTag>
                            <InputTag
                                type="text"
                                id="email"
                                placeholder="Enter your Email"
                                {...userEmail}
                            ></InputTag>
                        </div>
                        <div>
                            <LabelTag>{PASSWORD}</LabelTag>
                            <InputTag
                                type="password"
                                id="password"
                                placeholder="Create Password"
                                {...userPassword}
                            ></InputTag>
                        </div>
                        <DualLinks>
                            <ButtonTag>{SIGN_UP_FOR_FREE}</ButtonTag>
                            <Link to="/login">{SIGN_IN}</Link>
                        </DualLinks>
                        <LoginSeparator>
                            <LoginSeparatorIn>{OR}</LoginSeparatorIn>
                        </LoginSeparator>
                        <LoginSocial>
                            <SigninWithGoogleC parentCallback={handleCallback}></SigninWithGoogleC>
                            <SigninWithAppleC parentCallback={handleCallback}></SigninWithAppleC>
                        </LoginSocial>
                    </form>
                    <div>{error && <ErrorMsg>{error}</ErrorMsg>}</div>
                </FormMainIn>
            </FormWrapper>
        </div>
    );
}

export default SignUp;
