import React from 'react';
import { Menu, Dropdown, Button, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { SUPPORTED_LANGUAGES } from 'app/constants/languages.constants';
import { selectAppLanguage, setAppLanguage } from 'redux/features/app_language/language.slice';

export const LanguageDropdown = () => {
    const appLanguage = useSelector(selectAppLanguage);
    const dispatch = useDispatch();
    const onMenuItemClick = (e) => {
        dispatch(setAppLanguage(e.key));
        message.info('Language changed to : ' + SUPPORTED_LANGUAGES[e.key]);
    };
    let menu_entries = [];
    Object.entries(SUPPORTED_LANGUAGES).map(([key, value]) => {
        menu_entries.push(<Menu.Item key={key}>{value}</Menu.Item>);
    });
    let menu = <Menu onClick={onMenuItemClick}>{menu_entries}</Menu>;
    return (
        <Dropdown overlay={menu}>
            <Button>
                {SUPPORTED_LANGUAGES[appLanguage]} <DownOutlined />
            </Button>
        </Dropdown>
    );
};
