import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import animationfile from 'app/assets/animation/logo.json';

const PageLoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Loader = ({ message }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationfile,
        renderingSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <PageLoaderWrapper>
            <div>
                <Lottie options={defaultOptions} height={150} width={150} />
                {message ? <h3>{message}</h3> : null}
            </div>
        </PageLoaderWrapper>
    );
};
