import { createSlice } from '@reduxjs/toolkit';

export const languageDropdownSlice = createSlice({
    name: 'app_language',
    initialState: {
        value: 'en',
    },
    reducers: {
        //action type: app_language/set
        setAppLanguage: {
            reducer: (state, action) => {
                state.value = action.payload.selectedLanguage;
            },
            prepare: (languageString) => {
                return {
                    payload: {
                        selectedLanguage: languageString,
                    },
                };
            },
        },
    },
});

//selector function
export const selectAppLanguage = (state) => state.app_language.value;

export const { setAppLanguage } = languageDropdownSlice.actions;

export default languageDropdownSlice.reducer;
