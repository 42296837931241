import {
    templateLoaded,
    addOuterSection,
    removeOuterSection,
    updateText,
    addBlock,
    updateStyle,
    updateTextElement,
    updateImageUrl,
    updateOuterSectionStyle,
    updateSectionStyle,
    duplicateOuterSection,
    moveOuterSection,
    updateCtaUrl,
    removeBlock,
    updateImageAltTag,
    updateOuterSectionBackground,
    updateSectionBackground,
    removeSocialBlock,
    addSocialBlock,
    updateSocialBlock,
    updateTitle,
    updateForm,
    moveBlock,
    duplicateBlock,
    pasteBlock,
} from 'redux/features/email_editor/emailEditorSlice';

/*
#makePages
const undoRedoConfig = {
    filter: function filterActions(action: { type: any }) {
        const pageTypes = Object.keys(PageTypes);
        if (!pageTypes.includes(action.type)) {
            return false;
        }
        const actionsToBeIgnored = [
            'PAGE_LOADING',
            'PAGE_ERROR',
            'SAVE_PAGE_DATA_SUCCESS',
            'SAVE_PAGE_DATA',
            'UPDATE_GRID_VALUES',
            'SET_PAGE_NAME',
            'SET_HANDLER_TYPE',
        ];
        // only add to history if action is none of the above
        return !actionsToBeIgnored.includes(action.type);
    },
    ignoreInitialState: true,
    groupBy: groupByActionTypes(['UPDATE_SECTION_STYLING']),
};
*/

const undoRedoConfig = {
    filter: function filterActions(action, currentState, previousHistory) {
        const actionsToBeConsidered = [
            templateLoaded.toString(),
            addOuterSection.toString(),
            removeOuterSection.toString(),
            updateText.toString(),
            addBlock.toString(),
            updateStyle.toString(),
            updateTextElement.toString(),
            updateImageUrl.toString(),
            updateOuterSectionStyle.toString(),
            updateSectionStyle.toString(),
            duplicateOuterSection.toString(),
            moveOuterSection.toString(),
            updateCtaUrl.toString(),
            removeBlock.toString(),
            updateImageAltTag.toString(),
            updateOuterSectionBackground.toString(),
            updateSectionBackground.toString(),
            removeSocialBlock.toString(),
            addSocialBlock.toString(),
            updateSocialBlock.toString(),
            updateTitle.toString(),
            updateForm.toString(),
            moveBlock.toString(),
            duplicateBlock.toString(),
            pasteBlock.toString(),
        ];
        if (!actionsToBeConsidered.includes(action.type)) {
            return false;
        }
        const actionsToBeIgnored = []; //updateTitle.toString()

        // only add to history if action is none of the above
        return !actionsToBeIgnored.includes(action.type);
    },
    ignoreInitialState: true,
};

export { undoRedoConfig };
