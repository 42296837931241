import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'make-ui-v2';
import { selectEmailEditor, updateTitle } from 'redux/features/email_editor/emailEditorSlice';
import styled from 'styled-components';

const EmailTitle = () => {
    const [localTitle, setlocalTitle] = useState('New Template');
    const { title } = useSelector(selectEmailEditor);
    const dispatch = useDispatch();

    useEffect(() => {
        setlocalTitle(title);
    }, []);

    const debouncedSave = useCallback(
        debounce((value) => {
            dispatch(updateTitle({ updatedTitle: value }));
        }, 1000),
        [],
    );

    const handleChange = (e) => {
        let value = e.target.value;
        setlocalTitle(value);
        debouncedSave(value);
    };

    useEffect(() => {
        if (title !== localTitle) {
            setlocalTitle(title);
        }
    }, [title]);

    return (
        <StyledEmailTitle>
            <Input value={localTitle} onChange={handleChange} />
        </StyledEmailTitle>
    );
};

export default EmailTitle;

const StyledEmailTitle = styled.div`
    .hMSgUj {
        margin: 0;

        label {
            display: none;
        }
    }

    .custom-input {
        width: 100%;
        font-size: 18px;
        font-family: 'Circular Std Book';
        color: rgb(32, 33, 36);
        max-width: 130px;
        position: relative;
        text-align: center;
    }
`;
