import * as STRINGS from './en.lang';

export const russian = {
    [STRINGS.LOGIN_PAGE.TRIP_TO_BALI]: 'Поездка на Бали',
    [STRINGS.LOGIN_PAGE.SIGN_IN]: 'Войти',
    [STRINGS.LOGIN_PAGE.SIGN_UP]: 'Зарегистрироваться',
    [STRINGS.LOGIN_PAGE.WELCOME_BACK_TO_MAKESTORIES]: 'Добро Пожаловать в MakeStories',
    [STRINGS.LOGIN_PAGE.EMAIL_ADD]: 'Электронный адрес',
    [STRINGS.LOGIN_PAGE.PASSWORD]: 'Пароль',
    [STRINGS.LOGIN_PAGE.FORGOT_PASSWORD]: 'Забыл Пароль',
    [STRINGS.LOGIN_PAGE.CAPS_SIGN_IN]: 'ВОЙТИ',
    [STRINGS.LOGIN_PAGE.OR]: 'ИЛИ',
    [STRINGS.LOGIN_PAGE.GOOGLE]: 'GOOGLE',
    [STRINGS.LOGIN_PAGE.START_BUILDING_STORIES]: 'Начать Строить Истории',
    [STRINGS.LOGIN_PAGE.ABSOLUTELY_FREE]: 'Полностью Бесплатно',
    [STRINGS.LOGIN_PAGE.USERNAME]: 'Имя Пользователя',
    [STRINGS.LOGIN_PAGE.SIGN_UP_FOR_FREE]: 'Бесплатно зарегестрироваться',
    [STRINGS.LOGIN_PAGE.WE_WILL_SEND_EMAIL_WITH_INSTRUCTION]:
        'Мы отправим вам письмо с инструкциями',
    [STRINGS.LOGIN_PAGE.SEND_RESET_INSTRUCTION]: 'Отправить Инструкции по Сбросу',
};
