import { createSlice } from '@reduxjs/toolkit';
import { openTemplate } from 'redux/features/email_editor/templateIdSlice';
import { templateLoaded } from 'redux/features/email_editor/emailEditorSlice';

const templateLoadingStatusSlice = createSlice({
    name: 'templateLoadingStatus',
    initialState: false,
    extraReducers: (builder) => {
        builder
            .addCase(openTemplate, () => {
                return true;
            })
            .addCase(templateLoaded, () => {
                return false;
            });
    },
});

export default templateLoadingStatusSlice.reducer;

export const selectTemplateLoadingStatus = (state) => state.templateLoadingStatus;
