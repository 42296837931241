const COUNTER_TEST = {
    ADD_VALUE: 'Add Value',
    ADD_ASYNC: 'Add Async',
};

const PROFILE_SETTINGS = {
    PROFILE: 'Profile',
    LOGOUT: 'Logout',
};

const LOGIN_PAGE = {
    TRIP_TO_BALI: 'Trip To Bali',
    SIGN_IN: 'Sign In',
    SIGN_UP: 'Sign Up',
    BACK: 'Back',
    WELCOME_BACK_TO_MAKESTORIES: 'Welcome back to MakeStories',
    EMAIL_ADD: 'Email address',
    PASSWORD: 'Password',
    FORGOT_PASSWORD: 'Forgot Password',
    CAPS_SIGN_IN: 'SIGN IN',
    OR: 'OR',
    GOOGLE: 'GOOGLE',
    START_BUILDING_STORIES: 'Start Building Stories',
    ABSOLUTELY_FREE: 'Absolutely Free',
    USERNAME: 'Username',
    SIGN_UP_FOR_FREE: 'Sign UP for Free',
    WE_WILL_SEND_EMAIL_WITH_INSTRUCTION: 'We will send you an Email with Instructions',
    SEND_RESET_INSTRUCTION: 'Send Reset Instructions',
    INSTRUCTIONS_ARE_SENT_TO_YOUR_EMAIL: 'Instructions are sent to your Email.',
    SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
    SIGN_IN_WITH_APPLE: 'Sign in with Apple',
    NOT_SIGN_IN: 'Not Signed Up Yet?',
    CREATE_ACCOUNT: 'Create Account',
    PLEASE_ENTER_YOUR_EMAIL: 'Please enter your email',
};

const USER_PROFILE = {
    NEW_PASSWORD: 'New Password',
    CHANGE_PASSWORD: 'Change Password?',
    OLD_PASSWORD: 'Old password',
    CONFIRM_PASSWORD: 'Confirmed Password',
    RESET: 'Reset',
    PROFILE_SETTINGS: 'Profile Settings',
    NAME: 'Name',
    EMAIL: 'Email',
    SAVE_CHANGES: 'Save Changes',
    CURRENT_PASSWORD: 'Current Password',
    UPDATE_PASSWORD: 'Update Password',
};

const PROFILE_NOTIFICATIONS = {
    NAME_UPDATED: 'Name Updated',
    PROFILE_SUCCESSFULLY_UPDATED: 'Your profile has been successfully updated',
    ERROR: 'Error',
    ERR_UPDATING_PROFILE: 'Error while updating profile',
    EMPTY_DISPLAY_NAME: 'Display Name Cannot Be Empty',
    USER_NOT_FOUND: 'Cannot find user',
    PASSWORD_UPDATED: 'Password Updated',
    PASSWORD_SUCCESSFULLY_UPDATED: 'Your password has been successfully updated',
    ERR_UPDATING_PASSWORD: 'Error while updating password',
    DIFFERENT_PASSWORD:
        'New password and Confirm password are different. Please verify and try again',
    INCOMPLETE_DETAILS: 'Please in fill all the details to proceed',
};

const EMAIL_TEMPLATES = {
    CREATE_NEW_DESIGN: 'Create New Design',
    DELETE: 'Delete',
    CLONE: 'Clone',
    EDIT: 'EDIT',
    MY_TEMPLATES: 'My Templates',
};

const EDITOR = {
    SAVED: 'Saved',
    UN_SAVE: 'Unsaved Changes',
    SAVING: 'Saving Changes',
    DEFAULT_BLOCKS: 'Default Blocks',
    SAVED_TEMPLATES: 'Saved Templates',
    FORM: 'Form',
    CREATE_CUSTOM_FORM: 'Create Custom Form',
};

const RIGHTBAR = {
    EDITOR: {
        LAYOUT: 'Layout',
        SECTION: 'Section',
        APPEARANCE: 'Appearance',
        COLOR: 'Color',
        BACKGROUND_COLOR: 'Background color',
        FONT_WEIGHT: 'Font Weight',
        FONT_FAMILY: 'Font Family',
        BORDER: 'Border',
        SHADOW: 'Shadow',
        CTA_SETTINGS: 'CTA Settings',
        IMAGE: 'Image',
        TEXT: 'Text',
        CTA_TEXT: 'CTA Text',
        CTA_IMAGE: 'CTA Image',
        CTA_DESIGNS: 'CTA Designs',
        IMAGE_SETTINGS: 'Image Settings',
        COLORS: 'Colors',
        FILTER: 'Filter',
        LETTER_SPACING: 'Letter Spacing',
        LINE_HEIGHT: 'Line Height',
        OPACITY: 'Opacity',
        FONT_SIZE: 'Font Size',
        STROKE_SIZE: 'Stroke Size',
        STROKE_COLOR: 'Stroke Color',
        WIDTH: 'Width',
        ALIGNMENT: 'Alignment',
        SOCIAL: 'Social',
        BOX_SHADOW: 'Box Shadow',
        DROP_SHADOW: 'Drop Shadow',
        BLUR: 'Blur',
        SPREAD: 'Spread',
        PADDING: 'Padding',
        BORDER_RADIUS: 'Border Radius',
        MARGIN: 'Margin',
        ALTERNATE_TEXT: 'Alternate Text',
        ALT: 'Alt',
        URL: 'URL',
        LINK_URL: 'Link URL',
        IMAGE_URL: 'Image URL',
        ADD_SOCIAL_BLOCK: 'Add Social Icon',
    },
    SETTINGS: {
        //
    },
};

const SHOW_NOTIFICATION = {
    SAVING_TEMPLATE: 'Saving Template',
    TEMPLATE_SAVED_SUCCESSFULLY: 'Template Saved Successfully',
    SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER: 'Something went wrong. Please try again later',
    WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE:
        'We ran into some error. Please refresh the page to continue',
    TEMPLATE_DELETED_SUCCESSFULLY: 'Template deleted successfully',
    FAILED_TO_DELETE_TEMPLATE: 'Failed to delete template',
    TEMPLATE_CLONED_SUCCESSFULLY: 'Template cloned successfully',
    SOME_ERROR_OCCURED_WHILE_CLONING_TEMPLATE_PLEASE_TRY_AGAIN_AFTER_SOME_TIME:
        'Some error occured while cloning template, please Try again after sometime',
    DELETE_TEMPLATE: 'Delete Template',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TEMPLATE: 'Are you sure you want to delete this template',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    WHAT_WOULD_YOU_LIKE_TO_NAME_NEW_TEMPLATE: 'What would you like to name new template',
    CLONE: 'Clone',
};

const LEFT_NAVBAR = {
    MY_WORKSPACE: 'My WorkSpace',
    DASHBOARD: 'Dashboard',
    CREATE_CAMPAIGN: 'Create Campaign',
    EMAIL_TEMPLATES: 'Email Templates',
    CAMPAIGNS: 'Campaigns',
    INTEGRATIONS: 'Integrations',
    SETTINGS: 'Settings',
    CONTACTS: 'Contacts',
    LISTS: 'Lists',
    FIELDS: 'Fields',
    IMPORT_HISTORY: 'Import History',
    IMPORT_CONTACTS: 'Import Contacts',
    TRASH: 'Trash',
    VIEW_ALL: 'View All',
    ACCEPT: 'Accept',
};

const WORKSPACE_PAGE = {
    BACK_TO_DASHBOARD: 'Back To Dashboard',
    CREATE_WORKSPACE: 'Create workspace to get started',
    CREATE: 'Create workspace',
    GET_STARTED: 'Get started by selecting your own workspaces or create new',
    ALL_WORKSPACE: 'All Workspaces',
};

const EDITOR_TOOLTIP = {
    DELETE_ELEMENT: 'Delete element',
    MOVE_ELEMENT_UP: 'Move Element Up',
    MOVE_ELEMENT_DOWN: 'Move Element Down',
    DUPLICATE_ELEMENT: 'Duplicate Element',
    DELETE_SECTION: 'Delete Section',
    MOVE_SECTION_UP: 'Move Section Up',
    MOVE_SECTION_DOWN: 'Move Section Down',
    DUPLICATE_SECTION: 'Duplicate Section',
    SAVE_SECTION: 'Save Section as a Template',
    COPY_ELEMENT: 'Copy Element',
    PASTE_ELEMENT: 'Paste Element Below',
};

const CONTACT_LIST_DASHBOARD = {
    CONTACT_LIST: 'Contact List',
    CONTACTS: 'Contacts',
    CREATE: 'Create',
    ADD_CONTACT: 'Add Contact',
    CREATE_CONTACT: 'Create Contact',
    CREATE_FIELDS: 'Create Fields',
    SEGMENTS: 'Segments',
    SEGMENTS_LISTS: 'Segments Lists',
    CREATE_NEW_LIST: 'Create a New List',
    CREATE_LIST_INFO: `Creating a new list is recommended. Only contacts that are added to a list
    after an automation is set live will be entered into the automation.`,
    LIST_NAME: 'List Name',
    DELETE_LIST_INFO: 'Do you wish to delete the Table: ',
    NEW_LIST: 'New List',
    NEW_SEGMENT: 'New Segment',
    UPLOAD_CSV: 'Upload CSV',
    MANUAL_ADD: 'Manual Add',
    UPDATE_CONTACT_LIST: 'Update Contact List',
    DELETE_CONTACT_LIST: 'Delete Contact List',
    RESTORE_CONTACT_LIST: 'Restore Contact List',
    SEARCH_LIST: 'Search Lists...',
    ACTIVE_IMPORTS: 'Active Imports',
    ALL_CONTACTS: 'All Contacts',
    GLOBAL: 'Global',
    LIST: 'List',
    NAME: 'Name',
    TYPE: 'Type',
    COUNT: 'Count',
    SUBSCRIBER_COUNT: 'Subscriber Count',
    ACTION: 'Action',
    PARENT_LIST_ID: 'Parent List Id',
    TRASH_BIN: 'Trash Bin',
    TRASH_BIN_NOTE: `Note: the deleted list will only stay here for 30 days after that it
    will be deleted permanently`,
    CONTACT_LIST_BIN: 'Contact Lists Bin',
    SEGMENTS_BIN: 'Segments Bin',
    CONTACT_BIN: 'Contact Bin',
    BACK_TO_ALL_LIST: 'Back To All List',
    CUSTOM_FIELDS: 'Custom Fields',
    PERCENT_OF_MEMBERS: '% of Members',
    NO_DATA: 'No Data',
    RESTORE_LIST: 'Restore List',
    DELETE_LIST: 'Delete List',
    RESTORE_LIST_INFO: 'Restore Contact List',
    UPDATE_CONTACT_LIST: 'Update Contact List',
    UPDATE_CONTACT_LIST_INFO: 'Update contact list name:',
    DEFAULT_ERROR_MESSAGE: 'Something went wrong please try again later',
};

export {
    COUNTER_TEST,
    PROFILE_SETTINGS,
    LOGIN_PAGE,
    USER_PROFILE,
    PROFILE_NOTIFICATIONS,
    EMAIL_TEMPLATES,
    EDITOR,
    RIGHTBAR,
    SHOW_NOTIFICATION,
    LEFT_NAVBAR,
    EDITOR_TOOLTIP,
    CONTACT_LIST_DASHBOARD,
    WORKSPACE_PAGE,
};
