import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE } from 'app/language/en.lang';
import { PrevIcon } from 'app/constants/icons';
import { forgetPassword } from 'app/services/auth.service';
import { useInput } from 'app/hooks/useInput';

// Common components of Login
import MeLogo from 'app/Pages/Login/Components/MeLogo';
import BackgroundCarousel from 'app/Pages/Login/Components/BackgroundCarousel';

// Styled components import
import {
    FormWrapper,
    FormMainIn,
    Heading,
    Paragraph,
    DualLinks,
    InputTag,
    ButtonTag,
    LabelTag,
    ErrorMsg,
    VerifyEmail,
    BackButtonWrap,
    BackButton,
} from './loginCommonStyle';

const {
    BACK,
    SIGN_IN,
    INSTRUCTIONS_ARE_SENT_TO_YOUR_EMAIL,
    WE_WILL_SEND_EMAIL_WITH_INSTRUCTION,
    FORGOT_PASSWORD,
    EMAIL_ADD,
    SEND_RESET_INSTRUCTION,
    PLEASE_ENTER_YOUR_EMAIL,
} = LOGIN_PAGE;

function ForgotPassword() {
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [Message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [userEmail, setEmail, resetEmail] = useInput('');

    function changePassword(e) {
        e.preventDefault();
        const email = userEmail.value;
        if (email !== '') {
            forgetPassword(email)
                .then(() => {
                    setError('');
                    setIsSuccessful(true);
                    setMessage(INSTRUCTIONS_ARE_SENT_TO_YOUR_EMAIL);
                })
                .catch((error) => {
                    const errMessage = error.message;
                    setMessage('');
                    setIsSuccessful(false);
                    setError(errMessage);
                });
        } else {
            setError('');
            setIsSuccessful(false);
            setMessage(PLEASE_ENTER_YOUR_EMAIL);
        }
        resetEmail();
    }

    return (
        <div className="login">
            <BackgroundCarousel />
            <FormWrapper>
                <BackButtonWrap>
                    <Link to="/login">
                        <BackButton>
                            <PrevIcon />
                            {BACK}
                        </BackButton>
                    </Link>
                </BackButtonWrap>
                <MeLogo />
                <FormMainIn>
                    <div>
                        <Heading>{FORGOT_PASSWORD}</Heading>
                        <Paragraph>{WE_WILL_SEND_EMAIL_WITH_INSTRUCTION}</Paragraph>
                    </div>
                    <form onSubmit={(e) => changePassword(e)}>
                        <div>
                            <LabelTag>{EMAIL_ADD}</LabelTag>
                            <InputTag
                                type="text"
                                id="email"
                                placeholder="Enter your Email"
                                {...userEmail}
                            ></InputTag>
                        </div>
                        <DualLinks>
                            <ButtonTag>{SEND_RESET_INSTRUCTION}</ButtonTag>
                            <Link to="/login">{SIGN_IN}</Link>
                        </DualLinks>
                    </form>
                    <div>{error && <ErrorMsg>{error}</ErrorMsg>}</div>
                    <div>{Message && isSuccessful && <VerifyEmail>{Message}</VerifyEmail>}</div>
                </FormMainIn>
            </FormWrapper>
        </div>
    );
}

export default ForgotPassword;
