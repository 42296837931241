import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props) {
    const { component: Component, isUserAuthenticated, path, ...rest } = props;

    return isUserAuthenticated ? (
        <Route component={Component} path={path} {...rest} />
    ) : (
        <Redirect to="/login" />
    );
}

export default PrivateRoute;
