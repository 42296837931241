import { createSlice } from '@reduxjs/toolkit';
import { makeOuterSectionInActive } from './activeOuterSectionIdSlice';
import { removeOuterSection, removeBlock } from './emailEditorSlice';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

const activeBlockIdSlice = createSlice({
    name: 'activeBlockId',
    initialState: false,
    reducers: {
        makeBlockActive(state, action) {
            return action.payload;
        },
        makeBlockInActive() {
            return false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(makeOuterSectionInActive, () => {
                return false;
            })
            .addCase(removeOuterSection, () => {
                return false;
            })
            .addCase(removeBlock, () => {
                return false;
            })
            .addCase(closeTemplate, () => {
                return false;
            });
    },
});

export const { makeBlockActive, makeBlockInActive } = activeBlockIdSlice.actions;

export default activeBlockIdSlice.reducer;

export const selectActiveBlockId = (state) => state.activeBlockId;
