import React from 'react';
import ReactDOM from 'react-dom';
import { store } from 'redux/store/store';
import { displayMediaLibrary } from 'redux/features/media_library/mediaLibrary.slice';
import { showModal } from 'redux/actions/mediaLibAction';

class MediaPortal extends React.Component {
    render() {
        const { children, portalRoot } = this.props;
        if (portalRoot && portalRoot.current) {
            return ReactDOM.createPortal(children, portalRoot.current);
        } else {
            return null;
        }
    }
}

export function selectMedia(
    callback = () => {},
    mode = 'all',
    single = false,
    selectedMedia = [],
    options = {},
) {
    options = {
        details: false,
        btnText: 'Add Media',
        ...(typeof options === 'object' ? options : {}),
    };

    store.dispatch(showModal(callback, mode, single, selectedMedia, options));
    store.dispatch(displayMediaLibrary(true));
}

export default MediaPortal;
