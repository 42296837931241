import styled from 'styled-components';

// Styled components
export const ProfileWrap = styled.div`
    background: #f4f4fa;
    height: 100vh;
`;

export const ProfileSetting = styled.div`
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
    justify-content: space-between;
`;

export const Heading = styled.h2`
    margin-bottom: 1.875rem;
    font-size: 1.125rem;
    color: #202124;
    font-weight: normal;
    font-family: Circular Std Bold;
`;

export const ProfileSettingIn = styled.div`
    padding: 30px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 3px 3px 6px rgba(244, 244, 244, 0.8);
    width: 48%;
`;

export const Label = styled.label`
    font-size: 0.875rem;
    color: #474747;
    display: inline-block;
    margin-bottom: 1rem;
    font-family: Circular Std Book;
`;

export const Input = styled.input`
    display: block;
    margin-bottom: 1.5625rem;
    border: 1px solid transparent;
    border-radius: 5px;
    line-height: 2.3rem;
    font-size: 0.9rem;
    width: 100%;
    color: #474747;
    height: auto;
    padding: 0.325rem 1rem;
    background: #f0f3f4;

    &[readonly] {
        cursor: not-allowed;
    }
`;

export const SaveChanges = styled.button`
    width: 100%;
    background: #1657cd;
    border-radius: 5px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0 1rem;
    font-size: 0.875rem;
    letter-spacing: 2.52px;
    height: 36px;
    line-height: 36px;
    font-family: Circular Std Bold;
`;
