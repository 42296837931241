import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    workspaceId: '',
    workSpaceList: [],
    activeWorkSpace: '',
};

const workspaceIdSlice = createSlice({
    name: 'workspace',
    initialState: initialState,
    reducers: {
        changeWorkspaceId(state, action) {
            state.workspaceId = action.payload;
        },
        loadWorkSpaceListData(state, action) {
            if (action.payload && Array.isArray(action.payload)) {
                state.workSpaceList = action.payload;
            }
            return state;
        },
    },
});

export const { changeWorkspaceId, loadWorkSpaceListData } = workspaceIdSlice.actions;

export default workspaceIdSlice.reducer;

export const selectActiveSectionId = (state) => state.workspaceId;
