import React from 'react';

const PageNotFound = () => {
    return (
        <div>
            <h1>404</h1>
            <h3>Page not found</h3>
            <p>You tried to access a page which is not available.</p>
        </div>
    );
};

export default PageNotFound;
