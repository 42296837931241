import React from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';

// Background Images
import backgroundImageOne from '../../../assets/images/signin-background-one.jpg';
import backgroundImageTwo from '../../../assets/images/signin-background-two.jpg';
import backgroundImageThree from '../../../assets/images/signin-background-three.jpg';

function BackgroundCarousel() {
    const settings = {
        speed: 2000,
    };
    return (
        <Carousel effect="fade" dots={false} autoplay {...settings}>
            <div>
                <BackgroundImage style={{ backgroundImage: `url(${backgroundImageOne})` }} />
            </div>
            <div>
                <BackgroundImage style={{ backgroundImage: `url(${backgroundImageTwo})` }} />
            </div>
            <div>
                <BackgroundImage style={{ backgroundImage: `url(${backgroundImageThree})` }} />
            </div>
        </Carousel>
    );
}

export default BackgroundCarousel;

// Styled Components
const BackgroundImage = styled.div`
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
`;
