import { createAction } from '@reduxjs/toolkit';

const saveEmail = createAction('SAVE_EMAIL');

const saveSectionTemplate = createAction('SAVE_SEACTION_AS_TEMPLATE');

const loadSavedSectionTemplates = createAction('LOAD_SAVED_SECTION_TEMPLATES');

const loadSingleSectionTemplate = createAction('LOAD_SINGLE_SECTION_TEMPLATE');

const deleteSectionTemplate = createAction('DELETE_SECTION_TEMPLATE');

export {
    saveEmail,
    saveSectionTemplate,
    loadSavedSectionTemplates,
    loadSingleSectionTemplate,
    deleteSectionTemplate,
};
