import { takeEvery, put } from 'redux-saga/effects';
import { loginSuccess, logout } from 'redux/features/app_login/loginSlice';
import { changeWorkspaceId } from 'redux/features/workspace/workspaceSlice';
import { login } from 'redux/actions/loginAction';

function* handleLogin(action) {
    const { user } = action.payload;
    try {
        // temporarily this user.uid is getting pass as workspace id, until workspace is built inside project
        yield put(changeWorkspaceId(user.uid));
        yield put(loginSuccess(user));
    } catch (error) {
        yield put(logout());
    }
}

const [markLoaded, waitForUserLoad] = (function () {
    let userLoaded = false;
    let toResolve = [];
    let sendLoadedEvent = () => {
        userLoaded = true;
        toResolve.map((t) => {
            if (typeof t === 'function') {
                t();
            }
        });
    };

    const waitForUserLoad = () => {
        return new Promise((res) => {
            toResolve.push(res);
            if (userLoaded) {
                res(true);
            }
        });
    };

    return [sendLoadedEvent, waitForUserLoad];
})();

function* handleUserLoad() {
    yield markLoaded();
}

function* loginSaga() {
    yield takeEvery(login, handleLogin);
    yield takeEvery(loginSuccess, handleUserLoad);
}

export default loginSaga;

export { waitForUserLoad };
