import styled from 'styled-components';
import { Table } from 'antd';

export const ParentWrapper = styled.div`
    padding: 40px;
    padding-top: 10px;
    // height: calc(100vh - 50px);
    // overflow-y: scroll;

    span.anticon.anticon-search {
        position: relative;
        bottom: 2px;
    }
    .kDaZAC tbody > tr:hover {
        background-color: #fafafa;
        a {
            color: #2697f3;
        }
    }

    .ant-tabs-tab-btn {
        font-weight: 600;
        color: #7f90a0;s
    }

    button.ant-btn.ant-dropdown-trigger {
        font-weight: 400;
    }

    button.ant-btn.export-btn {
        padding-right: 18px;
        padding-left: 18px;
        /* padding: 8px 29px; */
        /* height: 44px; */
        border-color: #489be8;
        display: inline-block;
        padding: 11px 30px;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        border-radius: 2px;
        box-sizing: border-box;
        transition: color .3s,background-color .3s,border-color .3s;
        user-select: none;
        color: #1a82e2;
        background-color: #fff;
    }

    span.anticon.anticon-export {
        position: relative;
        bottom: 7px;
        right: 3px;
    }

    span.anticon.anticon-export {
        position: relative;
        bottom: 7px;
        right: 3px;
    }

    span.export-btn-txt {
        position: relative;
        bottom: 4.5px;
    }

    .ant-tabs-nav-list {
        padding: 0 16px;
        background: #f9fafb;
    }

    .ant-tabs-nav {
        .ant-tabs-extra-content {
            flex-basis: 65%;

            .tab-header-right {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .breadcrumb-block {
        margin-bottom: 10px;
        font-size: 10px;

        .back-icon {
            vertical-align: middle;
        }
    }

    .table-content {
        & thead > tr {
            text-align: left;
            > * {
                &:nth-child(1) {
                    width: 400px;
                }
            }
            > * {
                &:nth-child(2) {
                    width: 250px;
                }
            }
            > * {
                &:nth-child(3) {
                    width: 250px;
                }
            }
            > * {
                &:nth-child(4) {
                    width: 150px;
                }
            }
            > * {
                &:nth-child(5) {
                    width: 150px;
                }
            }
            th {
                padding: 8px !important;
                color: #7f90a0;
                font-size: 12px;
            }
        }
        & tbody > tr  {
            border-bottom: 1px solid #f0f0f0;
            > * {
                &:nth-child(1) {
                    width: 400px;
                }
            }
            > * {
                &:nth-child(2) {
                    width: 250px;
                }
            }
            > * {
                &:nth-child(3) {
                    width: 250px;
                }
            }
            > * {
                &:nth-child(4) {
                    width: 150px;
                }
            }
            > * {
                &:nth-child(5) {
                    width: 150px;
                }
            }
            td {
                padding: 8px !important;
                color: #7f90a0;
                font-size: 12px;
                .contact-action-btn {
                    visibility: hidden;
                }
            }
            &:hover {
                .contact-action-btn {
                    visibility: visible;
                }
            }

            .list-name {
                display: flex;
                align-items: center;

                a {
                    color: #7f90a0;
                    margin-left: 5px;
                    &:hover {
                        color:#2697f3;
                    }
                }
                svg {
                    color:#7f90a0;
                }

                h4 {
                    font-size: 12.5px;
                    margin-left: 11px;
                    margin-top: -4px;
                    color: #7f90a0;
                }

                .contact-email {
                    line-height: 2;
                }
            }
        }
        tbody {
            background-color: #fff;
        }

        .empty-table {
            width: 100%;
        }
    }

    .column-sort-options {
        display: flex;
        align-items: center;
    }
`;

export const Title = styled.h1`
    margin-bottom: 27px;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1px;
    font-weight: 700;
    color: #294661;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    .edit-name-block {
        position: relative;
        .search-icon-btn {
            position: absolute;
            top: 5px;
            left: 8px;

            svg {
                color: #7f90a0;
            }

            &:focus + input,
            &:hover + input {
                width: 100%;
                padding: 4px 8px 4px 35px;
            }
        }

        input {
            width: 35px;
            padding: 4px 8px 4px 20px;
            margin: 0;
            box-sizing: border-box;
            // border: 1px solid #7f90a0;
            border-radius: 3px;
            font-size: 16px;
            background-color: white;
            transition: width 0.5s ease-in-out;

            &:focus,
            &:hover {
                width: 100%;
                padding: 4px 8px 4px 35px;
            }
        }
    }

    .flex-title-row {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        h1 {
            margin-bottom: 0;
            margin-right: 20px;
        }

        button {
            background-color: #fff;
            border: 1px solid #fff;
        }
    }
`;

export const TitleWrapper = styled.div``;

export const ButtonWrapper = styled.div`
    > * {
        &:nth-child(2) {
            margin-bottom: 20px;
            padding: 0px 11px;
            height: 40px;
            font-size: 13px;
            border-radius: 3px;
            color: #fff;
            background-color: rgb(14, 100, 255);
            font-weight: 100;
            border: 1px solid rgb(14, 100, 255);

            &:nth-child(2) {
                position: relative;
                bottom: 2px;
                left: -1px;
            }
        }
    }
    > * {
        &:nth-child(1) {
            margin-bottom: 20px;
            margin-right: 20px;
            padding: 0px 11px;
            height: 40px;
            font-size: 13px;
            border-radius: 3px;
            color: rgb(14, 100, 255);
            font-weight: 100;
            border: 1px solid #fff;
            background-color: #fff;
        }
    }
`;

export const TableWrapper = styled.div`
    padding: 35px;
    background-color: #fff;

    .table-tabs {
        .ant-tabs-nav-list {
            padding: 10px;
            .ant-tabs-tab {
                padding: 5px;
            }
            .ant-tabs-tab-active {
                background-color: #fff;
                border-radius: 3px;
            }

            .ant-tabs-ink-bar-animated {
                display: none;
            }
        }

        .contact-list-search {
            .ant-input-group {
                position: relative;
                .ant-input-group-addon {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    border-color: transparent;
                    background-color: transparent;

                    button {
                        background-color: transparent;
                        border-color: transparent;
                        box-shadow: none;
                        z-index: 1;
                        svg {
                            color: #000;
                        }
                    }
                }

                input {
                    padding: 0 0 0 24px;
                    &:focus,
                    &:hover {
                        border: none;
                    }
                }
            }
        }
    }

    .import-btn {
        background-color: transparent;
        color: rgb(14, 100, 255);
        border: 1px solid transparent;
        border-radius: 3px;
        transition: 0.5s ease-in-out;
        &:hover {
            // background-color: rgb(14, 100, 255);
            border-bottom-color: rgb(14, 100, 255);
            // color: #fff;
        }
    }

    .action-btn-block {
        button {
            background-color: transparent;
            border: 1px solid transparent;
            visibility: hidden;
            display: flex;
        }
    }

    tbody > tr:hover {
        background-color: #fafafa;
        .action-btn-block {
            button {
                visibility: visible;
            }
        }
    }
`;

export const NoteWrapper = styled.div`
    font-size: 14px;
    color: #7f90a0;

    p {
        margin-bottom: 25px;
    }
`;

export const ModalWrapper = styled.div`
    p {
        padding-bottom: 10px;
    }
    input {
        margin-bottom: 10px;
        border-color: #000;
    }
    .error-message {
        color: red;
        font-size: 12px;
    }
`;

export const ListWrapper = styled.ul`
    width: 100%;
    display: flex;
    // align-items: center;
    align-items: stretch;

    .tab-btn {
        padding: 20px;
        line-height: 40px;
        flex-basis: 20%;
        text-align: center;
        background-color: #fff;
        border-bottom: 1px solid #f0f0f0;

        &:not(:last-child) {
            border-right: 1px solid #f0f0f0;
        }

        span {
            display: block;
            line-height: 2;
        }

        .count-ratio {
            font-size: 12px;
        }
    }

    .active-tab-btn {
        background-color: #ebf4ff;
    }
    .setting-btn {
        flex-basis: 30%;
        text-align: right;
        position: relative;
        background-color: transparent;
        align-self: end;
        padding-right: 0;
        padding-bottom: 0;

        svg {
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: transparent;
            transition: 0.5s ease-in-out;

            &:hover {
                color: rgb(14, 100, 255);
            }
        }

        button {
            background-color: #fff;
            border: 1px solid #fff;
            border-radius: 4px;
            font-size: 10px;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between !important;
        }
    }

    .ant-dropdown-menu-item {
        width: 100%;
        justify-content: space-between !important;
    }
`;

export const LinkWrapper = styled.div`
    a {
        margin-right: 10px;
        border-bottom: 1px solid transparent;
        transition: 0.5s ease-in-out;

        &:hover {
            color: rgb(14, 100, 255);
            border-color: rgb(14, 100, 255);
        }
    }

    button {
        margin-bottom: 20px;
        padding: 0px 11px;
        height: 40px;
        font-size: 13px;
        border-radius: 3px;
        color: #fff;
        background-color: rgb(14, 100, 255);
        font-weight: 100;
        border: 1px solid rgb(14, 100, 255);
    }
`;

export const StyledTable = styled(Table)`
margin-top: 30px;
position: relative;
background-color: white;

.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
    background: unset;
}

&.table {
    border-collapse: collapse;
};
thead {
    background: #f9fafb;
};
& thead > tr > th {
    padding: 8px !important;
    background-color: transparent;
    color: #7f90a0;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    color: #7f90a0;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: middle;
    font-weight: 600;
    text-align: left;
};
& tbody > tr.ant-table-row-level-1:hover > td {
    background: unset;
}

.fSlToe tbody > tr:hover {
    background-color: #fafafa;
    a {
        color: #2697f3 !important;
    }
}
& thead > tr {
    > * {
        &:nth-child(1) {
            width: 500px;
        }
    }
    > * {
        &:nth-child(2) {
            width: 200px;
        }
    }
    > * {
        &:nth-child(3) {
            width: 80px;
        }
    }
    > * {
        &:nth-child(4) {
            width: 200px;
        }
    }
    > * {
        &:nth-child(5) {
            width: 100px;
        }
    }
}
& tbody > tr  {
    > * {
        &:nth-child(1) {
            // width: 200px;
            display: flex;
            padding-bottom: 32px;
            padding-top: 40px;
            line-height: 2;
            //border-left: 1px solid #f0f0f0;

            .list-name {
                display: flex;
                justify-content: space-between;
                align-items: center;

                a {
                    color: #7f90a0;
                    margin-left: 5px;
                    &:hover {
                        color:#2697f3;
                    }
                }
                svg {
                    color:#7f90a0;
                }

                h4 {
                    font-size: 12.5px;
                    margin-left: 11px;
                    margin-top: -4px;
                    color: #7f90a0;
                }
            }
        }
    }

    > * {
        &:nth-child(2) {
            width: 200px;
            h4 {
                color: #7f90a0;
            }
           a {
                color: #489be8;
                text-decoration: none;
                transition: color .3s;
                font-weight: bold;
           }
        }
    }
    > * {
        &:nth-child(3) {
            width: 100px;
            padding: 29px 34px;
            .list-count {
                display: flex;
                align-items: center;
                span {
                    margin-left: 5px
                }
            }
        }
    }
    > * {
        &:nth-child(4) {
            width: 80px;
            padding: 29px 60px;
            .list-subscriber-count {
                display: flex;
                align-items: center;
                span {
                    margin-left: 5px
                }
            }
        }
    }
    > * {
        &:nth-child(5) {
            width: 100px;
            // border-right: 1px solid #f0f0f0;
            button {
                border: none;
                background-color: transparent;
            }
        }
    }
};
& tbody > tr > td {
    padding: 8px !important;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-weight: 400;
}
& tbody > tr  {
    font-size: 13px;
    font-weight: 100;
    color: #7f90a0;

    &:hover {
        background-color: #fafafa;
        a {
            color: #2697f3;
        }
    }
};
.ant-table-cell-row-hover {
    a {
        color: #2697f3 !important;
    }
};
'& thead > tr': {
    borderWidth: '2px',
    borderColor: 'yellow',
    borderStyle: 'solid',
},
`;

export const TrashStyledTable = styled(Table)`
    margin-top: 30px;
    background-color: white;

    .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
        background: unset;
    }

    &.table {
        border-collapse: collapse;
    };
    thead {
        background: #f9fafb;
    };
    & thead > tr > th {
        padding: 8px !important;
        background-color: transparent;
        color: #7f90a0;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 900;
        font-size: 12px;
        line-height: 15px;
        color: #7f90a0;
        text-transform: uppercase;
        letter-spacing: 1px;
        vertical-align: middle;
        font-weight: 600;
        text-align: left;
    };
    & tbody > tr.ant-table-row-level-1:hover > td {
        background: unset;
    }

    .fSlToe tbody > tr:hover {
        background-color: #fafafa;
        a {
            color: #2697f3 !important;
        }
    }
    & tbody > tr  {
        > * {
            &:nth-child(1) {
               display: flex;
               padding-bottom: 31px;
               padding-top: 40px;
               border-left: 1px solid #f0f0f0;
               svg {
                   color:#2697f3;
               }

               h4 {
                font-size: 12.5px;
                margin-left: 11px;
                margin-top: -4px;
                // color: #294661
                color: #7f90a0;
               }
            }
        }

        > * {
            &:nth-child(2) {
               a {
                    color: #489be8;
                    text-decoration: none;
                    transition: color .3s;
                    font-weight: bold;
               }

               h4 {
                color: #7f90a0;
               }
            }
        }
        > * {
            &:nth-child(3) {
                padding: 29px 34px;
            }
        }
        > * {
            &:nth-child(4) {
                padding: 29px 60px;

            }
        }
        > * {
            &:nth-child(5) {
                border-right: 1px solid #f0f0f0;
                button {
                    border: none;
                    background-color: transparent;
                }
            }
        }
    };
  & tbody > tr > td {
        padding: 8px !important;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.3s;
        font-weight: 400;
    }
    & tbody > tr  {
        font-size: 13px;
        font-weight: 100;
        color: #7f90a0;

        &:hover {
            background-color: #fafafa;
            a {
                color: #2697f3 !important;
            }
        }
    };
    '& thead > tr': {
        borderWidth: '2px',
        borderColor: 'yellow',
        borderStyle: 'solid',
    };
    .list-name {
        display: flex;
        align-items: center;
    }
`;
