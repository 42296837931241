import React, { useEffect, useRef } from 'react';

export const useClickOutside = (onClickOutside) => {
    const reference = useRef();

    useEffect(() => {
        const handleClick = (e) => {
            if (reference?.current?.contains(e.target) === true) {
                return;
            }

            onClickOutside();
        };

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, [onClickOutside]);

    return {
        reference,
    };
};
