import { takeLatest, put } from 'redux-saga/effects';
import i18next from 'i18next';
import get from 'lodash/get';
import {
    myTemplatesList,
    setTemplateLoadingStatus,
} from 'redux/features/email_templates/emailTemplatesSlice';
import { loadMyTemplates, duplicateTemplate } from 'redux/actions/templatesAction';
import { showNotification } from '_helpers';
import * as STRINGS from 'app/language/en.lang';
import { generateId } from 'app/services/database.service';
import FetchUtils from 'app/services/api';

const {
    TEMPLATE_CLONED_SUCCESSFULLY,
    SOME_ERROR_OCCURED_WHILE_CLONING_TEMPLATE_PLEASE_TRY_AGAIN_AFTER_SOME_TIME,
} = STRINGS.SHOW_NOTIFICATION;

function* handleLoadMyTemplates() {
    yield put(setTemplateLoadingStatus(true));
    const response = yield FetchUtils.getData('/email-template/templates', '', '', {}, true);
    yield put(setTemplateLoadingStatus(false));
    if (response.success && response.data && Array.isArray(response.data)) {
        const data = response.data;
        yield put(myTemplatesList(data));
    } else {
        showNotification(
            'LoadMyTemplatesError',
            `${get(response, 'error', 'Something went wrong.')}`,
            '',
            'error',
        );
    }
}

function* handleduplicateTemplate(action) {
    let { templateId, name, callback } = action.payload;
    name = name ? name : 'New Template';
    if (templateId) {
        const postData = {
            templateId: generateId(),
            name: name,
        };
        const response = yield FetchUtils.postData(
            `/email-template/clone/${templateId}`,
            postData,
            '',
            true,
        );
        if (response.success) {
            yield put(loadMyTemplates());
            callback({
                status: 'success',
                message: i18next.t(TEMPLATE_CLONED_SUCCESSFULLY),
            });
        } else {
            callback({
                status: 'error',
                message: i18next.t(
                    SOME_ERROR_OCCURED_WHILE_CLONING_TEMPLATE_PLEASE_TRY_AGAIN_AFTER_SOME_TIME,
                ),
            });
        }
    }
}

function* templatesSaga() {
    yield takeLatest(loadMyTemplates, handleLoadMyTemplates);
    yield takeLatest(duplicateTemplate, handleduplicateTemplate);
}

export default templatesSaga;
