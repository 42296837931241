import { createSlice } from '@reduxjs/toolkit';

export const mediaLibrarySlice = createSlice({
    name: 'mediaLibrarySlice',
    initialState: {
        value: false,
    },
    reducers: {
        displayMediaLibrary(state, action) {
            state.value = action.payload;
        },
    },
});

// selector function
export const showMediaLibrary = (state) => state.mediaLibrarySlice.value;

export const { displayMediaLibrary } = mediaLibrarySlice.actions;

export default mediaLibrarySlice.reducer;
