import { takeEvery } from 'redux-saga/effects';
import { setAppLanguage } from 'redux/features/app_language/language.slice';
import i18n from '_helpers/i18n';

const translateTo = (lang) => i18n.changeLanguage(lang);

//set language in local storage and translate to that language
function* setAppLanguageSaga(action) {
    localStorage.setItem('app_lang', action.payload.selectedLanguage);
    translateTo(action.payload.selectedLanguage);
}

function* languageSaga() {
    yield takeEvery(setAppLanguage, setAppLanguageSaga);
}

export default languageSaga;
