import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import styled from 'styled-components';
import {
    selectPastEmailEditor,
    selectFutureEmailEditor,
} from 'redux/features/email_editor/emailEditorSlice';
import { Undo, Redo } from 'app/constants/icons';

const UndoRedoButton = () => {
    const pastEmailEditor = useSelector(selectPastEmailEditor);
    const futureEmailEditor = useSelector(selectFutureEmailEditor);
    const dispatch = useDispatch();
    const handleUndo = () => {
        dispatch(ActionCreators.undo());
    };
    const handleRedo = () => {
        dispatch(ActionCreators.redo());
    };
    const handleClear = () => {
        dispatch(ActionCreators.clearHistory());
    };
    const canUndo = pastEmailEditor.length > 0;
    const canRedo = futureEmailEditor.length > 0;
    return (
        <UndoWrapper>
            <button onClick={handleUndo} disabled={!canUndo}>
                <Undo />
            </button>
            <button onClick={handleRedo} disabled={!canRedo}>
                <Redo />
            </button>
            {/* <button onClick={handleClear}>Clear</button> */}
        </UndoWrapper>
    );
};

export default UndoRedoButton;

const UndoWrapper = styled.div`
    button {
        border: none;
        background: none;
    }

    button[disabled]:not(.no-bg) {
        background-color: transparent;
    }

    svg {
        width: 14px;
        height: 14px;
        stroke: #000;
    }
`;
