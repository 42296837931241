import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import { authService } from 'auth-service';

import { firebaseConfig } from './firebaseConfig';

firebase.initializeApp(firebaseConfig);
authService.setFirebaseInstance(firebase);

export const auth = firebase.auth();
export const database = firebase.database();

export const baseFunctionsUrl = `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`;

export default firebase;
