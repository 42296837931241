import React from 'react';
import { SocialBtn } from 'app/Pages/Login/loginCommonStyle';
import { useDispatch } from 'react-redux';
import { GoogleIcon } from 'app/constants/icons';
import { LOGIN_PAGE } from 'app/language/en.lang';
import { generateId } from '_helpers';
import { signinWithGoogle } from 'app/services/auth.service';
import { login } from 'redux/actions/loginAction';

const { SIGN_IN_WITH_GOOGLE } = LOGIN_PAGE;

function SigninWithGoogleC(props) {
    const dispatch = useDispatch();

    const signInWithGoogle = () => {
        let generatedId = generateId();
        signinWithGoogle()
            .then((data) => {
                dispatch(login({ user: data.user }));
                localStorage.setItem('user', data.user.uid);
                localStorage.setItem('generatedId', generatedId);
            })
            .catch((error) => {
                console.log(error, 'google-error');
                let errorMessage = error.message;
                props.parentCallback(errorMessage);
            });
    };

    return (
        <SocialBtn type="button" onClick={signInWithGoogle}>
            <GoogleIcon />
            {SIGN_IN_WITH_GOOGLE}
        </SocialBtn>
    );
}

export default SigninWithGoogleC;
