import { all, fork } from 'redux-saga/effects';
import languageSaga from './language.saga';
import loginSaga from './login.saga';
import emailSaga from './email.saga';
import templatesSaga from './templates.saga';
import unsplashImagesSaga from './unsplash.images.saga';
import tenorGifsSaga from './tenor.gifs.saga';
import contactListSaga from './contactList.saga';
import workspaceSaga from './workspace.saga';

const rootSaga = function* () {
    yield all([
        fork(languageSaga),
        fork(loginSaga),
        fork(emailSaga),
        fork(templatesSaga),
        fork(unsplashImagesSaga),
        fork(tenorGifsSaga),
        fork(contactListSaga),
        fork(workspaceSaga),
    ]);
};

export default rootSaga;
