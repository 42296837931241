import { put, takeLatest } from 'redux-saga/effects';
import { store } from 'redux/store/store';
import {
    loadGifs,
    loadError,
    loadSuccess,
    noGifsFound,
} from 'redux/features/tenor_gifs/tenorGifsSlice';
import { fetchGif } from 'app/constants/gifPickerApi';

function* loadGifsFromTenor() {
    try {
        const {
            tenorGifs: { searchTerm, pos },
        } = store.getState();
        const { results: gifs, next } = yield fetchGif(searchTerm, pos);
        if (gifs && Array.isArray(gifs)) {
            if (next === '0') {
                yield put(noGifsFound(true));
            } else {
                const obj = { gifs, next };
                yield put(loadSuccess(obj));
            }
        } else {
            yield put(loadError('Error in Fetching Gifs'));
        }
    } catch (error) {
        console.log(error);
    }
}

function* tenorGifsSaga() {
    yield takeLatest(loadGifs, loadGifsFromTenor);
}

export default tenorGifsSaga;
