import { createSlice } from '@reduxjs/toolkit';
import { makeOuterSectionInActive } from './activeOuterSectionIdSlice';
import { removeOuterSection } from './emailEditorSlice';
import { closeTemplate } from 'redux/features/email_editor/templateIdSlice';

const activeSectionIdSlice = createSlice({
    name: 'activeSectionId',
    initialState: false,
    reducers: {
        makeSectionActive(state, action) {
            return action.payload;
        },
        makeSectionInActive() {
            return false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(makeOuterSectionInActive, () => {
                return false;
            })
            .addCase(removeOuterSection, () => {
                return false;
            })
            .addCase(closeTemplate, () => {
                return false;
            });
    },
});

export const { makeSectionActive, makeSectionInActive } = activeSectionIdSlice.actions;

export default activeSectionIdSlice.reducer;

export const selectActiveSectionId = (state) => state.activeSectionId;
