import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tabs, Dropdown, Menu, Input, Table, Modal, Spin } from 'antd';
import {
    DownOutlined,
    FileOutlined,
    DeleteOutlined,
    UnorderedListOutlined,
    UsergroupAddOutlined,
    NodeIndexOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import {
    loadContactList,
    searchContactList,
    createContactList,
    softDelContactList,
    updateContactList,
} from 'redux/actions/contactListAction';
import { clearError } from 'redux/features/contactlist/contactListSlice';
import {
    ParentWrapper,
    Title,
    ButtonWrapper,
    TitleWrapper,
    TableWrapper,
    HeaderWrapper,
    ModalWrapper,
    StyledTable,
} from './Styles';
import { contactListColumns } from '../../constants/ContactListTable';
import * as STRINGS from 'app/language/en.lang';
import { SearchIcon, VerticalDots, EnvelopeIcon, UserIcon } from 'app/constants/icons';

const {
    CONTACT_LIST,
    CREATE,
    ADD_CONTACT,
    SEGMENTS,
    SEGMENTS_LISTS,
    CREATE_NEW_LIST,
    CREATE_LIST_INFO,
    LIST_NAME,
    DELETE_LIST_INFO,
    NEW_LIST,
    NEW_SEGMENT,
    UPLOAD_CSV,
    MANUAL_ADD,
    UPDATE_CONTACT_LIST,
    DELETE_CONTACT_LIST,
    DELETE_LIST,
    UPDATE_CONTACT_LIST_INFO,
    ACTIVE_IMPORTS,
    LIST,
    DEFAULT_ERROR_MESSAGE,
} = STRINGS.CONTACT_LIST_DASHBOARD;

const ContactList = () => {
    let { t } = useTranslation('translation');

    const dispatch = useDispatch();
    const contactListData = useSelector((state) => state?.contactList?.contactList ?? []);
    let errorState = useSelector((state) => state?.contactList?.errorState ?? false);
    let errorMessage = useSelector((state) => state?.contactList?.errorMessage ?? '');

    const { TabPane } = Tabs;

    const inputEl = useRef();
    const updateInput = useRef();

    const [firstLoad, setFirstLoad] = useState(true);
    const [pageErrorState, setPageErrorState] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [loadingMoreContacts, setLoadingMoreContacts] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [collectionListId, setCollectionListId] = useState({});
    const [confirmDeleteLoading, setConfirmDeleteLoading] = useState(false);
    const [confirmUpdateLoading, setConfirmUpdateLoading] = useState(false);
    const [isUpdateModelOpen, setIsUpdateModelOpen] = useState(false);

    const searchContact = (event) => {
        const value = event.target.value;
        if (event.code === 'Enter' && value) {
            setLoadingContacts(true);
            setContactList([]);
            dispatch(searchContactList({ name: value }));
        }

        if (event.code === 'Enter' && value == '') {
            setLoadingContacts(true);
            const callback = () => {
                '';
            };
            dispatch(loadContactList({ callback }));
        }
    };

    //open model function
    const handelAddContactEvent = (e) => {
        if (e.key === 'newlist') {
            setIsModelOpen(true);
        }
    };

    //close mode function
    const handleCancel = () => {
        inputEl.current.state.value = '';
        dispatch(clearError());
        setIsModelOpen(false);

        if (errorState) {
            dispatch(clearError());
        }
    };

    //handle delete or edit list click
    const handelActionEvent = (e) => {
        if (e.key === 'deletecontacts') {
            setIsDeleteModelOpen(true);
        }

        if (e.key === 'updatecontacts') {
            setIsUpdateModelOpen(true);
        }
    };

    //add new list function
    const handleOk = () => {
        const newlist = inputEl.current.state.value;
        setConfirmLoading(true);
        const showError = () => {
            if (!errorState) {
                inputEl.current.state.value = '';
                dispatch(clearError());
                setIsModelOpen(false);
                setConfirmLoading(false);
            } else {
                setConfirmLoading(false);
            }
        };
        dispatch(createContactList({ newlist, showError }));
        setConfirmLoading(false);
    };

    //close Delete model function
    const handleDeleteCancel = () => {
        setCollectionListId({});
        setIsDeleteModelOpen(false);
        setConfirmDeleteLoading(false);
    };

    //Submit Delete Model
    const handleDeleteOk = () => {
        const listId = collectionListId.id;
        if (listId) {
            setConfirmDeleteLoading(true);
            const finishDel = () => {
                setIsDeleteModelOpen(false);
                setConfirmDeleteLoading(false);
                setCollectionListId({});

                // dispatch(loadContactList({ callback: null }));
            };
            dispatch(softDelContactList({ listId, finishDel }));
        }
    };

    //close update model function
    const handleUpdateCancel = () => {
        setCollectionListId({});
        setIsUpdateModelOpen(false);
        setConfirmUpdateLoading(false);
        updateInput.current.state.value = '';

        if (errorState) {
            dispatch(clearError());
        }
    };

    //Submit Update Model
    const handleUpdateOk = () => {
        const newlist = updateInput.current.state.value;
        const listId = collectionListId.id;
        if (listId && newlist != '') {
            setConfirmUpdateLoading(true);
            const finishUpdate = (error = null) => {
                if (!error) {
                    updateInput.current.state.value = '';
                    dispatch(clearError());
                    setIsUpdateModelOpen(false);
                    setConfirmUpdateLoading(false);
                    setCollectionListId({});
                } else {
                    setConfirmUpdateLoading(false);
                }
            };
            dispatch(updateContactList({ listId, newlist, finishUpdate }));
        }
    };

    // create list drop down
    const createContactMenu = (
        <Menu onClick={handelAddContactEvent}>
            <Menu.Item key="newlist" icon={<UnorderedListOutlined />}>
                {t(NEW_LIST)}
            </Menu.Item>
            <Menu.Item key="newSegment" icon={<NodeIndexOutlined />}>
                {t(NEW_SEGMENT)}
            </Menu.Item>
        </Menu>
    );

    // upload file drop down
    const addContactMenu = (
        <Menu onClick={''}>
            <Menu.Item key="uploadCsv" icon={<FileOutlined />}>
                {t(UPLOAD_CSV)}
            </Menu.Item>
            <Menu.Item key="manualAdd" icon={<UsergroupAddOutlined />}>
                {t(MANUAL_ADD)}
            </Menu.Item>
        </Menu>
    );

    const actionMenu = (
        <Menu onClick={handelActionEvent}>
            <Menu.Item key="updatecontacts" icon={<NodeIndexOutlined />}>
                {t(UPDATE_CONTACT_LIST)}
            </Menu.Item>
            <Menu.Item key="deletecontacts" icon={<DeleteOutlined />}>
                {t(DELETE_CONTACT_LIST)}
            </Menu.Item>
        </Menu>
    );

    const Operations = {
        right: (
            <div className="tab-header-right" style={{ justifyContent: 'flex-end' }}>
                <button className={'import-btn'}>{t(ACTIVE_IMPORTS)}</button>
            </div>
        ),
    };

    const fetchLists = () => {
        const callback = (error = null) => {
            setFirstLoad(false);
            if (error) {
                setPageErrorState(true);
            }
        };
        dispatch(loadContactList({ callback }));
    };

    useEffect(() => {
        setLoadingContacts(true);
        fetchLists();
    }, []);

    useEffect(() => {
        let listLength = contactListData;
        if (Array.isArray(listLength) && listLength.length > 0) {
            if (!loadingMoreContacts) {
                setLoadingContacts(true);
            }

            if (Array.isArray(contactListData)) {
                const userCollectionList = contactListData.map((items) => {
                    return {
                        key: items._id,
                        name: (
                            <div className={'list-name action-btn-block'}>
                                <Dropdown
                                    overlay={actionMenu}
                                    onMouseEnter={() => {
                                        setCollectionListId({ id: items._id, name: items.name });
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            setCollectionListId({
                                                id: items._id,
                                                name: items.name,
                                            });
                                        }}
                                    >
                                        <VerticalDots />
                                    </button>
                                </Dropdown>
                                <Link
                                    to={`/contact/list/${items._id}`}
                                    state={{ from: items.name }}
                                >
                                    {items.name.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                                </Link>
                            </div>
                        ),
                        type: (
                            <>
                                <h4>{t(LIST)}</h4>
                            </>
                        ),
                        count: (
                            <div className={'list-count'}>
                                <EnvelopeIcon />
                                <span>{items.contactCount}</span>
                            </div>
                        ),
                        subscriberCount: (
                            <div className={'list-subscriber-count'}>
                                <UserIcon />
                                <span>{items.subscriberCount}</span>
                            </div>
                        ),
                    };
                });

                setContactList(userCollectionList);
            }

            if (!loadingMoreContacts) {
                setLoadingContacts(false);
            } else {
                setLoadingMoreContacts(false);
            }
        }

        if (!firstLoad && Array.isArray(listLength) && listLength.length == 0) {
            setLoadingContacts(false);
        }
    }, [contactListData, firstLoad]);

    return (
        <>
            {!loadingContacts && !pageErrorState && (
                <ParentWrapper>
                    <HeaderWrapper>
                        <TitleWrapper className={'flex-title-row'}>
                            <Title>{t(CONTACT_LIST)}</Title>
                            <div className={'edit-name-block'}>
                                <span className={'search-icon-btn'}>{<SearchIcon />}</span>
                                <input
                                    type="search"
                                    name="search"
                                    id="myInput"
                                    placeholder="Search.."
                                    onKeyPress={(e) => {
                                        searchContact(e);
                                    }}
                                />
                            </div>
                        </TitleWrapper>
                        <ButtonWrapper>
                            <Dropdown overlay={createContactMenu}>
                                <button>
                                    {t(CREATE)} <DownOutlined />
                                </button>
                            </Dropdown>
                            <Dropdown overlay={addContactMenu}>
                                <button>
                                    {t(ADD_CONTACT)} <DownOutlined />
                                </button>
                            </Dropdown>
                        </ButtonWrapper>
                    </HeaderWrapper>
                    <TableWrapper>
                        <Tabs
                            defaultActiveKey="1"
                            tabBarExtraContent={Operations}
                            className={'table-tabs'}
                        >
                            <TabPane tab={t(CONTACT_LIST)} key="1">
                                {loadingContacts ? (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                type={'loading'}
                                                style={{ fontSize: '50px' }}
                                            />
                                        }
                                    />
                                ) : (
                                    <StyledTable
                                        dataSource={contactList}
                                        columns={contactListColumns}
                                        pagination={{
                                            pageSize: 10,
                                        }}
                                        loading={loadingContacts}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab={t(SEGMENTS)} key="2">
                                <h6>{t(SEGMENTS_LISTS)}</h6>
                            </TabPane>
                        </Tabs>
                    </TableWrapper>
                    <Modal
                        title={t(CREATE_NEW_LIST)}
                        visible={isModelOpen}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        <ModalWrapper>
                            <p className={'create-list-info'}>{t(CREATE_LIST_INFO)}</p>
                            <Input ref={inputEl} placeholder={t(LIST_NAME)} />
                            {errorState && errorMessage ? (
                                <p className={'error-message'}>{errorMessage}</p>
                            ) : null}
                            {errorState && errorMessage == '' ? (
                                <p className={'error-message'}>{t(DEFAULT_ERROR_MESSAGE)}</p>
                            ) : null}
                        </ModalWrapper>
                    </Modal>
                    <Modal
                        title={t(DELETE_LIST)}
                        visible={isDeleteModelOpen}
                        onOk={handleDeleteOk}
                        confirmLoading={confirmDeleteLoading}
                        onCancel={handleDeleteCancel}
                    >
                        <p>
                            {t(DELETE_LIST_INFO)} {collectionListId.name}
                        </p>
                    </Modal>
                    <Modal
                        title={t(UPDATE_CONTACT_LIST)}
                        visible={isUpdateModelOpen}
                        onOk={handleUpdateOk}
                        confirmLoading={confirmUpdateLoading}
                        onCancel={handleUpdateCancel}
                    >
                        <ModalWrapper>
                            <p className={'create-list-info'}>
                                {t(UPDATE_CONTACT_LIST_INFO)} {collectionListId.name}
                            </p>
                            <Input ref={updateInput} placeholder={t(LIST_NAME)} />
                            {errorState && errorMessage ? (
                                <p className={'error-message'}>{errorMessage}</p>
                            ) : null}
                            {errorState && errorMessage == '' ? (
                                <p className={'error-message'}>{t(DEFAULT_ERROR_MESSAGE)}</p>
                            ) : null}
                        </ModalWrapper>
                    </Modal>
                </ParentWrapper>
            )}
            {loadingContacts && (
                <Spin
                    indicator={<LoadingOutlined type={'loading'} style={{ fontSize: '50px' }} />}
                />
            )}
            {pageErrorState && !loadingContacts && <p>{t(DEFAULT_ERROR_MESSAGE)}</p>}
        </>
    );
};

export default ContactList;
