import React from 'react';
import UserProfileSettings from './ProfileSettings';

function Profile() {
    return (
        <div>
            <UserProfileSettings />
        </div>
    );
}

export default Profile;
