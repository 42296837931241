import firebase, { auth } from '../../config/firebase';
import { readDB, saveDB, trigger, updateDB } from './database.service';
const isUserVerified = (user) => {
    //This helper function is from makestories
    return new Promise(function (res, rej) {
        if (user.emailVerified) {
            res();
        } else {
            user.sendEmailVerification()
                .then(function () {
                    rej({
                        error: 'UNVERIFIED_EMAIL',
                        message:
                            'Please verify your email address to continue using makestories. We just sent a mail to your email address : ',
                        email: user.email,
                    });
                })
                .catch(function () {
                    user.sendEmailVerification()
                        .then(function () {
                            rej({
                                error: 'UNVERIFIED_EMAIL',
                                message:
                                    'Please verify your email address to continue using makestories. We just sent a mail to your email address : ',
                                email: user.email,
                            });
                        })
                        .catch(function () {
                            rej({
                                error: 'OTHER_ERROR',
                                message:
                                    'Please verify your email address to continue using makestories. We just sent a mail to your email address : ',
                                email: user.email,
                            });
                        });
                });
        }
    });
};

const checkAndUpdate = (path, userData) => {
    const callback = (data) => {
        if (data && data.displayName) delete userData.displayName;
        updateDB(path, userData);
    };
    readDB(path, trigger.VALUE, callback);
};

const login = async (email, password) => {
    try {
        const data = await auth.signInWithEmailAndPassword(email, password);
        try {
            await isUserVerified(data.user);
            return data;
        } catch (error) {
            //Do Nothing
        }
    } catch (error) {
        throw new Error(error);
    }
};
const logout = async () => {
    try {
        await auth.signOut();
        return true;
    } catch (error) {
        throw new Error(error);
    }
};
const signup = async (email, password) => {
    try {
        const data = await auth.createUserWithEmailAndPassword(email, password);
        const path = `${data.user.uid}/profile`;
        const userData = { email: data.user.email, displayName: data.user.displayName };
        saveDB(path, userData);
        try {
            await isUserVerified(data.user);
            return data;
        } catch (error) {
            //Do Nothing
        }
    } catch (error) {
        throw new Error(error);
    }
};
const signinWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    try {
        const data = await auth.signInWithPopup(provider);
        const { uid, email, displayName } = data.user;
        const path = `${uid}/profile`;
        const userData = { email, displayName };
        checkAndUpdate(path, userData);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
const signinWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
        const data = await auth.signInWithPopup(provider);
        const { uid, email, displayName } = data.user;
        const path = `${uid}/profile`;
        const userData = { email, displayName };
        console.log({ userData });
        checkAndUpdate(path, userData);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
const signinWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    try {
        const data = await auth.signInWithPopup(provider);
        const { uid, email, displayName } = data.user;
        const path = `${uid}/profile`;
        const userData = { email, displayName };
        checkAndUpdate(path, userData);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};
const forgetPassword = async (email) => {
    try {
        await auth.sendPasswordResetEmail(email);
        return true;
    } catch (error) {
        throw new Error(error);
    }
};
const changePassword = async (currentPassword, newPassword) => {
    try {
        const { currentUser } = auth;
        const credentials = firebase.auth.EmailAuthProvider.credential(
            currentUser.email,
            currentPassword,
        );
        await currentUser.reauthenticateWithCredential(credentials);
        await currentUser.updatePassword(newPassword);
        return true;
    } catch (error) {
        throw new Error(error);
    }
};
export {
    isUserVerified,
    login,
    logout,
    signup,
    signinWithFacebook,
    signinWithGoogle,
    signinWithApple,
    forgetPassword,
    changePassword,
};
