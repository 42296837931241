import { hasOwnProperty } from '_helpers';
import { baseFunctionsUrl } from 'config/firebase';
const key = '7ef9a12d8c9a497c8fac68379c5d2ce9';
const url = `${baseFunctionsUrl}/tenorGetGif/anonid?key=${key}`;

const fetchGif = async (searchTerm, pos) => {
    let toFetch = url;
    if (searchTerm) {
        toFetch = url + '&tag=' + searchTerm;
    }
    if (pos) {
        toFetch += `&pos=${pos}`;
    }
    let response = await fetch(`${toFetch}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    if (hasOwnProperty(data, 'results') && hasOwnProperty(data, 'next')) {
        return data;
    }
    return data;
};

export { fetchGif };
