import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { waitForUserLoad } from 'redux/sagas/login.saga';
import firebase from 'config/firebase';
import msLogo from 'app/assets/images/makestories-logo.svg';
import * as STRINGS from 'app/language/en.lang';
import { loadWorkSpaceList } from 'redux/actions/workSpaceAction';
import Nav, { TextEllipsis } from './leftBarStyles';
import { useClickOutside } from 'app/hooks/useClickOutSide';
import { Avatar } from 'make-ui-v2';
import { Loader } from 'app/Components/Loader/Loader';
import UserProfile from 'app/Components/TopBar/UserProfile';
import { LanguageDropdown } from 'app/Components/LanguageDropdown/LanguageDropdown';
import { currentUserState } from 'redux/features/app_login/loginSlice';
import {
    Dashboard,
    LeftArrowIcon,
    LeftBarPlusIcon,
    Contact,
    EmailTemplate,
    CampaignIcon,
    IntegrationIcon,
    SettingIcon,
} from 'app/constants/icons';
import { changeWorkspaceId } from 'redux/features/workspace/workspaceSlice';
// import state from 'sweetalert/typings/modules/state';

const {
    MY_WORKSPACE,
    DASHBOARD,
    CREATE_CAMPAIGN,
    EMAIL_TEMPLATES,
    INTEGRATIONS,
    SETTINGS,
    CAMPAIGNS,
    CONTACTS,
    LISTS,
    FIELDS,
    IMPORT_CONTACTS,
    IMPORT_HISTORY,
    TRASH,
    VIEW_ALL,
    ACCEPT,
} = STRINGS.LEFT_NAVBAR;

const LeftBar = () => {
    let { t } = useTranslation('translation');
    let dispatch = useDispatch();
    const history = useHistory();

    const workSpaceList = useSelector((state) => state?.workSpaceReducer?.workSpaceList ?? []);
    const workspaceId = useSelector((state) => state?.workSpaceReducer?.workspaceId ?? '');
    const userId = useSelector((state) => state?.currentUser?.uid ?? '');
    const currentUser = useSelector(currentUserState);
    const { displayName, email } = currentUser;

    const [listData, setListData] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [navDropdown, setNavDropdown] = useState(false);
    const [leftBarStatus, setLeftBarStatus] = useState(true);
    const [loadingState, setLoadingState] = useState(false);

    const getUserDetail = async () => {
        await waitForUserLoad();
        const user = firebase.auth().currentUser;
        if (user) {
            let userDetails = {
                displayName: user.displayName,
                logo: user.photoURL,
                email: user.email,
            };
            setUserDetails({
                ...userDetails,
            });
        }
    };

    const fetchWorkSpaceData = () => {
        const callback = () => {
            setLoadingState(false);
        };
        dispatch(loadWorkSpaceList({ callback }));
    };

    useEffect(() => {
        // setLoadingState(true);
        // getUserDetail();
        // fetchWorkSpaceData();
    }, []);

    useEffect(() => {
        if (workSpaceList.length > 0) {
            setListData(workSpaceList);
            // setLoadingState(false);
        }
    }, [workSpaceList]);

    const { reference: navContainer } = useClickOutside(() => {
        if (navDropdown) setNavDropdown(false);
    });

    const navDropdownclick = useCallback((value) => (e) => {
        if (e) e.preventDefault();
        setNavDropdown((state) => (state === value ? null : value));
    });

    const closeLeftBar = (val, e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
            setLeftBarStatus(val);
            setNavDropdown(false);
        }
    };

    const setActiveWorkspace = (activeWorkSpace, dontRedirect = false) => {
        if (activeWorkSpace !== workspaceId) {
            dispatch(changeWorkspaceId(activeWorkSpace));
            // redirect home route
            if (history.location.pathname !== '/' && !dontRedirect) history.push('/');
        }
    };

    return (
        <>
            {loadingState && <Loader />}
            {!loadingState && (
                <Nav onClick={() => setLeftBarStatus(true)}>
                    <div
                        ref={navContainer}
                        className={`side-bar-main ${leftBarStatus ? 'dropdown-open' : ''}`}
                    >
                        <ul className="siderbar-nav-contents">
                            <li className="logo-area">
                                <Link to="/">
                                    <img src={msLogo} alt="MakeStories-logo-image" />
                                </Link>
                                <div
                                    onClick={(e) => {
                                        closeLeftBar(false, e);
                                    }}
                                >
                                    <LeftArrowIcon />
                                </div>
                            </li>
                            <li
                                className={`position-relative ${
                                    navDropdown === 1 ? 'show-dropdown' : ''
                                }`}
                            >
                                <p onClick={navDropdownclick(1)} className="nav-user">
                                    <Avatar
                                        initials="W"
                                        new-story-button="true"
                                        classes={'workspace-icon'}
                                    />
                                    <span className="hidden-text">{t(MY_WORKSPACE)}</span>
                                </p>
                                <div className="user-dropdown">
                                    {Array.isArray(listData) &&
                                        listData?.slice(0, 3)?.map((workspace) => {
                                            const {
                                                id,
                                                name,
                                                teamMembers,
                                                logo,
                                                email,
                                                accepted = 1,
                                            } = workspace;
                                            let accept_id = '';
                                            if (teamMembers && Array.isArray(teamMembers)) {
                                                teamMembers.every(({ email, member_id }) => {
                                                    if (
                                                        email?.trim() ===
                                                        (userDetails || {}).email?.trim()
                                                    ) {
                                                        accept_id = member_id;
                                                        return false;
                                                    }
                                                    return true;
                                                });
                                            }
                                            return (
                                                <div
                                                    onClick={() => {
                                                        setActiveWorkspace(id);
                                                        setNavDropdown(null);
                                                    }}
                                                    key={id}
                                                    className="flex-row no-margin align-items-center workspace-item add-margin-custom"
                                                >
                                                    <Avatar
                                                        name={name.charAt(0)}
                                                        img={logo !== '0' ? logo : ''}
                                                    />
                                                    <div className={'workspace-details'}>
                                                        <TextEllipsis width={119}>
                                                            {name}
                                                        </TextEllipsis>
                                                        <span>
                                                            {id !== userId
                                                                ? (teamMembers || []).length +
                                                                  '' +
                                                                  'Members'
                                                                : null}
                                                            {!accepted ? (
                                                                <button
                                                                    className="button invite-btn"
                                                                    onClick={(event) => {
                                                                        acceptInvite(accept_id);
                                                                        event.stopPropagation();
                                                                    }}
                                                                >
                                                                    {t(ACCEPT)}
                                                                </button>
                                                            ) : null}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    <NavLink
                                        onClick={() => {
                                            if (navDropdown) setNavDropdown(false);
                                        }}
                                        to="/workspaces"
                                        className="link"
                                    >
                                        {t(VIEW_ALL)}
                                    </NavLink>
                                </div>
                            </li>
                            <li>
                                <Link
                                    className="new-story-button"
                                    onClick={() => {
                                        // serFromTeam(inTeam);
                                        navDropdownclick(1);
                                    }}
                                    to="/"
                                >
                                    <span className="story-icon">
                                        <LeftBarPlusIcon />
                                    </span>
                                    <span className="hidden-text">{t(CREATE_CAMPAIGN)}</span>
                                </Link>
                            </li>
                            <div className="sidebar-menu-items-group">
                                <li onClick={() => setNavDropdown(null)}>
                                    <NavLink
                                        to="/"
                                        exact
                                        className="is-active nav-link"
                                        title={t(DASHBOARD)}
                                    >
                                        <span className="nav-icon">
                                            <Dashboard />
                                        </span>
                                        <span className="hidden-text">{t(DASHBOARD)}</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`nav-dropdown ${
                                        navDropdown === 2 ? 'show-dropdown' : ''
                                    }`}
                                    onClick={navDropdownclick(2)}
                                >
                                    <NavLink
                                        to="/contact/lists"
                                        className="is-active nav-link"
                                        title={t(CONTACTS)}
                                    >
                                        <span className="nav-icon">
                                            <Contact />
                                        </span>
                                        <span className="hidden-text">{t(CONTACTS)}</span>
                                    </NavLink>
                                    <div className="nav-dropdown-menu">
                                        {/* Contact List */}
                                        <NavLink
                                            to="/contact/lists"
                                            activeClassName="is-active"
                                            onClick={(event) => {
                                                setNavDropdown(2);
                                                event.stopPropagation();
                                            }}
                                            title={t(LISTS)}
                                        >
                                            {t(LISTS)}
                                        </NavLink>
                                        <NavLink
                                            to="/contact/custom-fields"
                                            activeClassName="is-active"
                                            onClick={(event) => {
                                                setNavDropdown(2);
                                                event.stopPropagation();
                                            }}
                                            title={t(FIELDS)}
                                        >
                                            {t(FIELDS)}
                                        </NavLink>
                                        <NavLink
                                            to="/contact/import-history"
                                            activeClassName="is-active"
                                            onClick={(event) => {
                                                setNavDropdown(2);
                                                event.stopPropagation();
                                            }}
                                            title={t(IMPORT_HISTORY)}
                                        >
                                            {t(IMPORT_HISTORY)}
                                        </NavLink>
                                        <NavLink
                                            to="/contact/import-contact"
                                            activeClassName="is-active"
                                            onClick={(event) => {
                                                setNavDropdown(2);
                                                event.stopPropagation();
                                            }}
                                            title={t(IMPORT_CONTACTS)}
                                        >
                                            {t(IMPORT_CONTACTS)}
                                        </NavLink>
                                        <NavLink
                                            to="/contact/trash-bin"
                                            activeClassName="is-active"
                                            onClick={(event) => {
                                                setNavDropdown(2);
                                                event.stopPropagation();
                                            }}
                                            title={t(TRASH)}
                                        >
                                            {t(TRASH)}
                                        </NavLink>
                                    </div>
                                </li>
                                <li onClick={() => setNavDropdown(null)}>
                                    <NavLink
                                        to="/templates"
                                        className="is-active nav-link"
                                        title={t(EMAIL_TEMPLATES)}
                                    >
                                        <span className="nav-icon">
                                            <EmailTemplate />
                                        </span>
                                        <span className="hidden-text">{t(EMAIL_TEMPLATES)}</span>
                                    </NavLink>
                                </li>
                                <li onClick={() => setNavDropdown(null)}>
                                    <NavLink
                                        to="/campaigns"
                                        className="is-active nav-link"
                                        title={t(CAMPAIGNS)}
                                    >
                                        <span className="nav-icon">
                                            <CampaignIcon />
                                        </span>
                                        <span className="hidden-text">{t(CAMPAIGNS)}</span>
                                    </NavLink>
                                </li>
                                <li onClick={() => setNavDropdown(null)}>
                                    <NavLink
                                        to="/integrations"
                                        className="is-active nav-link"
                                        title={t(INTEGRATIONS)}
                                    >
                                        <span className="nav-icon">
                                            <IntegrationIcon />
                                        </span>
                                        <span className="hidden-text">{t(INTEGRATIONS)}</span>
                                    </NavLink>
                                </li>
                                <li
                                    onClick={() => {
                                        setNavDropdown(null);
                                    }}
                                >
                                    <NavLink
                                        to="/settings"
                                        className="is-active nav-link"
                                        title={t(SETTINGS)}
                                    >
                                        <span className="nav-icon">
                                            <SettingIcon />
                                        </span>
                                        <span className="hidden-text">{t(SETTINGS)}</span>
                                    </NavLink>
                                </li>
                                <li className={'last-element'}>
                                    <div className={'language-dropdown'}>
                                        <div className={'user-profile-block'}>
                                            <UserProfile />
                                            <span className="profile-name">
                                                {displayName ? displayName : email}
                                            </span>
                                        </div>
                                        <LanguageDropdown />
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </Nav>
            )}
        </>
    );
};

export default LeftBar;
