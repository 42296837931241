import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PageNotFound from 'app/Pages/PageNotFound';
import Dashboard from 'app/Pages/Dashboard';
import TopBar from 'app/Components/TopBar';
import Profile from 'app/Pages/Profile';
import LeftBar from 'app/Pages/LeftBar';
import { STYLE_CONSTANTS } from 'app/constants/styles.constants';
import Templates from 'app/Pages/Templates';
import ContactList from 'app/Pages/ContactList';
import SingleContactList from 'app/Pages/ContactList/SingleContactList';
import CustomFields from 'app/Pages/ContactList/CustomFields';
import ImportHistory from 'app/Pages/ContactList/ImportHistory';
import ImportContact from 'app/Pages/ContactList/ImportContact';
import TrashBin from 'app/Pages/ContactList/TrashBin';
import Workspace from 'app/Pages/Workspace';

const DashboardWrapper = styled.div`
    display: flex;

    & > div {
        //height: calc(100vh - ${STYLE_CONSTANTS.topBarHeight});
        height: 100vh;
    }

    .global-leftbar-wrapper {
        flex-basis: ${STYLE_CONSTANTS.dashboardLeftBarWidth};
    }

    .page-container {
        padding: 10px 5px;
        flex-basis: calc(100% - ${STYLE_CONSTANTS.dashboardLeftBarWidth});
        background-color: rgb(250, 250, 250);
        overflow: scroll;
    }
`;

const ProtectedRoutes = () => {
    return (
        <div>
            <div>{/* <TopBar /> */}</div>
            <DashboardWrapper>
                <div className="global-leftbar-wrapper">
                    <LeftBar />
                </div>
                <div className="page-container">
                    <Switch>
                        <Route path={'/templates'} exact component={Templates} />
                        <Route path={'/profile'} exact component={Profile} />
                        <Route path={'/contact/lists'} exact component={ContactList} />
                        <Route path={'/contact/list/:id'} exact component={SingleContactList} />
                        <Route path={'/contact/custom-fields'} exact component={CustomFields} />
                        <Route path={'/contact/import-history'} exact component={ImportHistory} />
                        <Route path={'/contact/import-contact'} exact component={ImportContact} />
                        <Route path={'/contact/trash-bin'} exact component={TrashBin} />
                        <Route path={'/workspaces'} exact component={Workspace} />
                        <Route path={'/'} exact component={Dashboard} />
                        <Route component={PageNotFound} />
                    </Switch>
                </div>
            </DashboardWrapper>
        </div>
    );
};

export default withRouter(ProtectedRoutes);
