import * as STRINGS from './en.lang';

export const german = {
    [STRINGS.COUNTER_TEST.ADD_VALUE]: 'Mehrwert',
    [STRINGS.COUNTER_TEST.ADD_ASYNC]: 'Asyn hinzufügen',
    [STRINGS.PROFILE_SETTINGS.PROFILE]: 'Profil',
    [STRINGS.PROFILE_SETTINGS.LOGOUT]: 'Abmelden',
    [STRINGS.LOGIN_PAGE.TRIP_TO_BALI]: 'Reise nach Bali',
    [STRINGS.LOGIN_PAGE.SIGN_IN]: 'Anmelden',
    [STRINGS.LOGIN_PAGE.SIGN_UP]: 'Registrieren',
    [STRINGS.LOGIN_PAGE.BACK]: 'zurück',
    [STRINGS.LOGIN_PAGE.WELCOME_BACK_TO_MAKESTORIES]: 'Willkommen zurück bei MakeStories',
    [STRINGS.LOGIN_PAGE.EMAIL_ADD]: 'E-Mail-Addresse',
    [STRINGS.LOGIN_PAGE.PASSWORD]: 'Passwort',
    [STRINGS.LOGIN_PAGE.FORGOT_PASSWORD]: 'Passwort vergessen',
    [STRINGS.LOGIN_PAGE.CAPS_SIGN_IN]: 'ANMELDEN',
    [STRINGS.LOGIN_PAGE.OR]: 'ODER',
    [STRINGS.LOGIN_PAGE.GOOGLE]: 'GOOGLE',
    [STRINGS.LOGIN_PAGE.START_BUILDING_STORIES]: 'Konstruiere jetzt eine Story',
    [STRINGS.LOGIN_PAGE.ABSOLUTELY_FREE]: 'Absolut kostenlos',
    [STRINGS.LOGIN_PAGE.USERNAME]: 'Benutzernamem',
    [STRINGS.LOGIN_PAGE.SIGN_UP_FOR_FREE]: 'Kostenlos registrieren',
    [STRINGS.LOGIN_PAGE.WE_WILL_SEND_EMAIL_WITH_INSTRUCTION]:
        'Wir senden Ihnen eine E-Mail mit Anweisungen',
    [STRINGS.LOGIN_PAGE.SEND_RESET_INSTRUCTION]: 'Reset-Anweisungen senden',
    [STRINGS.LOGIN_PAGE.INSTRUCTIONS_ARE_SENT_TO_YOUR_EMAIL]:
        'Anweisungen werden an Ihre E-Mail gesendet.',
    [STRINGS.LOGIN_PAGE.SIGN_IN_WITH_GOOGLE]: 'Anmeldung mit Google',
    [STRINGS.LOGIN_PAGE.SIGN_IN_WITH_APPLE]: 'Melden Sie sich bei Apple an',
    [STRINGS.LOGIN_PAGE.NOT_SIGN_IN]: 'Noch nicht angemeldet?',
    [STRINGS.LOGIN_PAGE.CREATE_ACCOUNT]: 'Benutzerkonto anlegen',
    [STRINGS.LOGIN_PAGE.PLEASE_ENTER_YOUR_EMAIL]: 'Bitte geben Sie ihre E-Mail-Adresse ein',
    [STRINGS.USER_PROFILE.NEW_PASSWORD]: 'Neues Passwort',
    [STRINGS.USER_PROFILE.CHANGE_PASSWORD]: 'Passwort ändern?',
    [STRINGS.USER_PROFILE.OLD_PASSWORD]: 'Altes Passwort',
    [STRINGS.USER_PROFILE.CONFIRM_PASSWORD]: 'Passwort bestätigen',
    [STRINGS.USER_PROFILE.RESET]: 'Zurücksetzen',
    [STRINGS.USER_PROFILE.PROFILE_SETTINGS]: 'Profileinstellungen',
    [STRINGS.USER_PROFILE.NAME]: 'Name',
    [STRINGS.USER_PROFILE.EMAIL]: 'E-Mail',
    [STRINGS.USER_PROFILE.SAVE_CHANGES]: 'Änderungen speichern',
    [STRINGS.USER_PROFILE.CURRENT_PASSWORD]: 'Aktuelles Passwort',
    [STRINGS.USER_PROFILE.UPDATE_PASSWORD]: 'Passwort aktualisieren',
    [STRINGS.PROFILE_NOTIFICATIONS.NAME_UPDATED]: 'Name aktualisiert',
    [STRINGS.PROFILE_NOTIFICATIONS.PROFILE_SUCCESSFULLY_UPDATED]: 'Profil erfolgreich aktualisiert',
    [STRINGS.PROFILE_NOTIFICATIONS.ERROR]: 'Fehler',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PROFILE]: 'Fehler beim Aktualisieren des Profils',
    [STRINGS.PROFILE_NOTIFICATIONS.EMPTY_DISPLAY_NAME]: 'Anzeigename darf nicht leer sein',
    [STRINGS.PROFILE_NOTIFICATIONS.USER_NOT_FOUND]: 'Kann Benutzer nicht finden',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_UPDATED]: 'Passwort aktualisiert',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_SUCCESSFULLY_UPDATED]:
        'Ihr Passwort wurde erfolgreich aktualisiert',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PASSWORD]:
        'Fehler beim Aktualisieren des Passworts',
    [STRINGS.PROFILE_NOTIFICATIONS.DIFFERENT_PASSWORD]:
        'Neues Passwort und bestätigtes Passwort sind unterschiedlich. Bitte überprüfen und erneut versuchen',
    [STRINGS.PROFILE_NOTIFICATIONS.INCOMPLETE_DETAILS]:
        'Bitte füllen Sie alle Details aus, um fortzufahren',
};
