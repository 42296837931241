import React, { Fragment, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import swal from 'sweetalert';
import SignIn from '../Pages/Login/SignIn';
import SignUp from '../Pages/Login/SignUp';
import PrivateRoute from 'app/Components/PrivateRoute';
import ForgotPassword from '../Pages/Login/ForgotPassword';
import { isUserAuthenticated } from '../../redux/features/app_login/loginSlice';
import PageNotFound from 'app/Pages/PageNotFound';
import ProtectedRoutes from './protectedRoutes';
import { logout } from 'redux/features/app_login/loginSlice';
import { auth } from '../../config/firebase';
// import { isUserVerified } from '_helpers';
import { login } from 'redux/actions/loginAction';

function Routes() {
    const isUserAuthenticatedTemp = useSelector(isUserAuthenticated);
    const dispatch = useDispatch();
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                dispatch(login({ user }));

                // **currently we are not verifying in firebase.
                // isUserVerified(user)
                //     .then(() => {
                //         if (user) {
                //             dispatch(login({ user }));
                //         } else {
                //             dispatch(logout());
                //         }
                //     })
                //     .catch((error) => {
                //         console.log(error, 'Err');
                //         let message = error.message + error.email;
                //         dispatch(logout());
                //         swal(message);
                //     });
            } else {
                dispatch(logout());
            }
        });
    }, []);
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route path="/login" component={SignIn} />
                    <Route path={'/sign-up'} exact component={SignUp} />
                    <Route path={'/forgotten-password'} component={ForgotPassword} />
                    <PrivateRoute
                        path="/"
                        component={ProtectedRoutes}
                        isUserAuthenticated={isUserAuthenticatedTemp}
                    />
                    <Route component={PageNotFound} />
                </Switch>
            </Router>
        </Fragment>
    );
}

export default Routes;
