import { database } from 'config/firebase';
import { store } from 'redux/store/store';

export const trigger = Object.freeze({
    CHILD_ADDED: 'child_added',
    CHILD_CHANGED: 'child_changed',
    CHILD_MOVED: 'child_moved',
    CHILD_REMOVED: 'child_removed',
    VALUE: 'value',
});

const saveDB = (path, data) => database.ref(path).set(data);
const updateDB = (path, data) => database.ref(path).update(data);
const deleteDB = (path) => database.ref(path).remove();

const readDB = (path, triggerKey, callBack) =>
    database.ref(path).on(triggerKey, (snapshot) => callBack(snapshot.val()));

const getBasePath = () => {
    const { currentUserId } = store.getState();
    return currentUserId + '/';
};

const getFullPath = (path) => {
    return getBasePath() + path;
};

const generateId = (path = 'story') =>
    database
        .ref()
        .child(getFullPath(path) || path)
        .push().key;

export { saveDB, readDB, updateDB, deleteDB, generateId };
