import * as STRINGS from './en.lang';

export const french = {
    [STRINGS.COUNTER_TEST.ADD_VALUE]: 'Mehrwert',
    [STRINGS.COUNTER_TEST.ADD_ASYNC]: 'Asyn hinzufügen',
    [STRINGS.PROFILE_SETTINGS.PROFILE]: 'Profil',
    [STRINGS.PROFILE_SETTINGS.LOGOUT]: 'Abmelden',
    [STRINGS.LOGIN_PAGE.BACK]: 'dos',
    [STRINGS.LOGIN_PAGE.TRIP_TO_BALI]: 'Voyage à Bali',
    [STRINGS.LOGIN_PAGE.SIGN_IN]: 'Se connecter',
    [STRINGS.LOGIN_PAGE.SIGN_UP]: "S'inscrire",
    [STRINGS.LOGIN_PAGE.WELCOME_BACK_TO_MAKESTORIES]: 'Bon retour sur MakeStories',
    [STRINGS.LOGIN_PAGE.EMAIL_ADD]: 'Adresse électronique',
    [STRINGS.LOGIN_PAGE.PASSWORD]: 'Mot de passe',
    [STRINGS.LOGIN_PAGE.FORGOT_PASSWORD]: 'Mot de passe oublié',
    [STRINGS.LOGIN_PAGE.CAPS_SIGN_IN]: "S'INSCRIRE",
    [STRINGS.LOGIN_PAGE.OR]: 'OU',
    [STRINGS.LOGIN_PAGE.GOOGLE]: 'GOOGLE',
    [STRINGS.LOGIN_PAGE.START_BUILDING_STORIES]: 'Commencer à créer des stories',
    [STRINGS.LOGIN_PAGE.ABSOLUTELY_FREE]: 'Complètement gratuit',
    [STRINGS.LOGIN_PAGE.USERNAME]: "Nom d'utilisateur",
    [STRINGS.LOGIN_PAGE.SIGN_UP_FOR_FREE]: "S'inscrire gratuitement",
    [STRINGS.LOGIN_PAGE.WE_WILL_SEND_EMAIL_WITH_INSTRUCTION]:
        'Nous vous enverrons un mail avec des instructions',
    [STRINGS.LOGIN_PAGE.SEND_RESET_INSTRUCTION]: 'Envoyer les instructions de réinitialisation',

    [STRINGS.LOGIN_PAGE.INSTRUCTIONS_ARE_SENT_TO_YOUR_EMAIL]:
        'Les instructions seront envoyées à votre adresse e-mail.',
    [STRINGS.LOGIN_PAGE.SIGN_IN_WITH_GOOGLE]: 'Connectez-vous avec Google',
    [STRINGS.LOGIN_PAGE.SIGN_IN_WITH_APPLE]: 'Connectez-vous à Apple',
    [STRINGS.LOGIN_PAGE.NOT_SIGN_IN]: 'Pas encore inscrit?',
    [STRINGS.LOGIN_PAGE.CREATE_ACCOUNT]: 'Créer un compte',
    [STRINGS.LOGIN_PAGE.PLEASE_ENTER_YOUR_EMAIL]: 'Veuillez saisir votre adresse e-mail',
    [STRINGS.USER_PROFILE.NEW_PASSWORD]: 'Nouveau mot de passe',
    [STRINGS.USER_PROFILE.CHANGE_PASSWORD]: 'Changer de mot de passe ?',
    [STRINGS.USER_PROFILE.OLD_PASSWORD]: 'Ancien mot de passe',
    [STRINGS.USER_PROFILE.CONFIRM_PASSWORD]: 'Mot de passe confirmé',
    [STRINGS.USER_PROFILE.RESET]: 'Réinitialiser',
    [STRINGS.USER_PROFILE.PROFILE_SETTINGS]: 'Paramètres du profil',
    [STRINGS.USER_PROFILE.NAME]: 'Nom',
    [STRINGS.USER_PROFILE.EMAIL]: 'Mail',
    [STRINGS.USER_PROFILE.SAVE_CHANGES]: 'Enregistrer les changements',
    [STRINGS.USER_PROFILE.CURRENT_PASSWORD]: 'Mot de passe actuel',
    [STRINGS.USER_PROFILE.UPDATE_PASSWORD]: 'Mise à jour du mot de passe',
    [STRINGS.PROFILE_NOTIFICATIONS.NAME_UPDATED]: 'Name Updated',
    [STRINGS.PROFILE_NOTIFICATIONS.PROFILE_SUCCESSFULLY_UPDATED]:
        'Votre profil a été mis à jour avec succès',
    [STRINGS.PROFILE_NOTIFICATIONS.ERROR]: 'Erreur',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PROFILE]: 'Erreur lors de la mise à jour du profil',
    [STRINGS.PROFILE_NOTIFICATIONS.EMPTY_DISPLAY_NAME]: "nom d'affichage vide",
    [STRINGS.PROFILE_NOTIFICATIONS.USER_NOT_FOUND]: 'utilisateur non trouvé',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_UPDATED]: 'Mot de passe mis à jour',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_SUCCESSFULLY_UPDATED]:
        'Votre mot de passe a été mis à jour avec succès',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PASSWORD]:
        'Erreur lors de la mise à jour du mot de passe',
    [STRINGS.PROFILE_NOTIFICATIONS.DIFFERENT_PASSWORD]:
        'Votre nouveau mot de passe et le mot de passe de confirmation sont différents. Veuillez les resaisir.',
    [STRINGS.PROFILE_NOTIFICATIONS.INCOMPLETE_DETAILS]:
        'Veuillez remplir tous les détails pour continuer',
};
