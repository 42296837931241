import React, { useState, useEffect } from 'react';
import {
    ProfileWrap,
    ProfileSetting,
    Heading,
    ProfileSettingIn,
    Label,
    Input,
    SaveChanges,
} from './ProfileCommonStyle';
import { Tooltip, message } from 'antd';
import { useSelector } from 'react-redux';
import { showNotification } from '_helpers';
import { currentUserState } from 'redux/features/app_login/loginSlice';
import { USER_PROFILE, PROFILE_NOTIFICATIONS } from 'app/language/en.lang';
import { readDB, trigger, updateDB } from 'app/services/database.service';
import ChangePassword from './ChangePassword';
import { useInput } from 'app/hooks/useInput';

const { PROFILE_SETTINGS, NAME, EMAIL, SAVE_CHANGES } = USER_PROFILE;
const { NAME_UPDATED, PROFILE_SUCCESSFULLY_UPDATED } = PROFILE_NOTIFICATIONS;

const UserProfileSettings = () => {
    const currentUser = useSelector(currentUserState);
    const { uid, displayName, email } = currentUser;

    const [userData, setUserData] = useState();

    const [userName, setUsername, resetUsername] = useInput(displayName || '');
    const [userEmail, setUseremail, resetUseremail] = useInput(email || '');

    useEffect(() => {
        readDB(uid, trigger.VALUE, setUserData);
    }, [uid, displayName, email]);

    useEffect(() => {
        const { profile } = userData || {};
        if (profile) {
            // TODO: update store
            setUsername(profile.displayName || '');
            setUseremail(profile.email);
        }
        return () => {
            setUsername('');
        };
    }, [userData]);

    const handleSubmit = () => {
        const updatedName = {
            displayName: userName.value,
        };
        const path = `${uid}/profile`;
        updateDB(path, updatedName);
        showNotification(
            'ProfileNameUpdated',
            NAME_UPDATED + '!',
            PROFILE_SUCCESSFULLY_UPDATED,
            'success',
        );
    };

    return (
        <ProfileWrap>
            <ProfileSetting>
                <ProfileSettingIn>
                    <Heading>{PROFILE_SETTINGS}</Heading>
                    <Label>{NAME}</Label>
                    <Input type="text" {...userName}></Input>
                    <Label>{EMAIL}</Label>
                    <Tooltip placement={'bottom'} title="Email address cannot be updated.">
                        <Input readOnly={true} value={userEmail.value}></Input>
                    </Tooltip>
                    <SaveChanges onClick={(e) => handleSubmit(e)}>{SAVE_CHANGES}</SaveChanges>
                </ProfileSettingIn>
                <ChangePassword />
            </ProfileSetting>
        </ProfileWrap>
    );
};

export default UserProfileSettings;
