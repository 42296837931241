import { takeLeading, put } from 'redux-saga/effects';
import axios from 'axios';
import {
    loadImages,
    loadError,
    noImagesFound,
    loadSuccess,
} from 'redux/features/Unsplash_Images/unsplashImagesSlice';
import { store } from 'redux/store/store';
import { baseApiUrlMakestories } from 'app/constants/apiUrl';

const POSTS_PER_PAGE = 30;

let retries = 0;

const AfterFiveSeconds = () => new Promise((res) => setTimeout(res, 5000));

function* loadImagesGen() {
    try {
        const {
            unsplashImages: { toSkip = 0, searchTerm = '' },
        } = store.getState();

        let { data, status } = yield axios(
            `${baseApiUrlMakestories}/images/free?toshow=${POSTS_PER_PAGE}&toskip=${toSkip}&API_TOKEN=MakeStoriesAttachmentBuilder&tag=${searchTerm}`,
        );
        if (status === 200 && Array.isArray(data)) {
            if (data.length > 0) {
                yield put(loadSuccess(data));
            } else {
                yield put(noImagesFound(true));
            }
            retries = 0;
        } else {
            retries++;
            yield put(loadError('Some error occurred while fetching images'));
            if (retries < 5) {
                yield AfterFiveSeconds();
                yield put(loadImages());
            }
        }
    } catch (error) {
        retries++;
        yield put(loadError('Some error occurred while fetching images'));
        if (retries < 5) {
            yield AfterFiveSeconds();
            yield put(loadImages());
        }
    }
}

function* unsplashImageSaga() {
    yield takeLeading(loadImages, loadImagesGen);
}

export default unsplashImageSaga;
