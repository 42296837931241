import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from '../app/language';
let internationalizationService = {};

i18n.use(initReactI18next).init({
    resources: resources,

    lng: 'en',
    fallbackLng: 'en',
    debug: false, //make it true to see in console which words pending/need to be converted in which language.
    nsSeparator: false,
    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },

    register: internationalizationService,
});

export default i18n;
