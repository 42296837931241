import axios from 'axios';
import firebase from 'config/firebase';
import get from 'lodash/get';
import { waitForUserLoad } from 'redux/sagas/login.saga';
import { getIdToken } from '_helpers';
import { baseApiUrlMakestories, baseApiUrlMakeEmails } from 'app/constants/apiUrl';

const handleResponse = ({ response, error }) => {
    // handle both response types
    const responseData = get(response, 'data.data') || (response && response.data) || null;
    let formattedResponse = {};
    if (error || responseData.status === 'fail' || responseData.status === 'error') {
        return (formattedResponse = {
            error: error || 'Error',
            success: false,
            status: get(responseData, 'status') || get(error, 'response.status'),
        });
    }

    formattedResponse = {
        data: responseData,
        success: true,
        status: get(response, 'data.status') || get(response, 'response.status'),
    };

    return formattedResponse;
};

class FetchUtils {
    baseUrl;
    baseMeUrl;

    constructor() {
        // this.baseUrl = `${process.env.REACT_APP_BASE_URL}`;
        // this.baseMpUrl = `${process.env.REACT_APP_MAKEPAGES_BASE_URL}`;
        this.baseUrl = baseApiUrlMakestories;
        this.baseMeUrl = baseApiUrlMakeEmails;
    }

    getBaseUrl = (base) => `${this.baseUrl}${base}`;

    getMeBaseUrl = () => `${this.baseMeUrl}`;

    getApiUrl = (useMpUrl, base, url) =>
        useMpUrl ? this.getMeBaseUrl() + url : this.getBaseUrl(base) + url;

    // set headers with authentication token
    setHeaders(headersValue = {}, responseType = {}) {
        const config = {
            ...responseType,
            headers: {
                ...headersValue,
            },
        };

        return config || {};
    }

    postData = async (url, body = {}, base = '', useMpUrl = false) => {
        if (!firebase.auth().currentUser) {
            await waitForUserLoad();
        }
        const idToken = await getIdToken();
        const bodyWithToken = {
            token: idToken,
            ...body,
        };
        try {
            const headers = this.setHeaders();
            const apiUrl = this.getApiUrl(useMpUrl, base, url);
            const urlWithToken = `${apiUrl}?token=${idToken}`;
            const response = await axios.post(urlWithToken, bodyWithToken, headers);
            return handleResponse({ response });
        } catch (error) {
            return handleResponse({ error });
        }
    };

    putData = async (url, body, base = '') => {
        try {
            const headers = this.setHeaders();
            const apiUrl = this.getBaseUrl(base) + url;
            const response = await axios.put(apiUrl, body, headers);

            return response;
        } catch (error) {
            return error;
        }
    };

    patchData = async (url, body, base = '', useMpUrl = false) => {
        try {
            if (!firebase.auth().currentUser) {
                await waitForUserLoad();
            }
            const idToken = await getIdToken();

            const headers = this.setHeaders();
            const apiUrl = this.getApiUrl(useMpUrl, base, url);
            const urlWithToken = `${apiUrl}?token=${idToken}`;
            const response = await axios.patch(urlWithToken, body, headers);
            return handleResponse({ response });
        } catch (error) {
            // console.log('error ^^^^^^^', error);
            return handleResponse({ error });
        }
    };

    deleteData = async (url, base = '', useMpUrl = false) => {
        try {
            if (!firebase.auth().currentUser) {
                await waitForUserLoad();
            }
            const idToken = await getIdToken();
            const headers = this.setHeaders();
            const apiUrl = this.getApiUrl(useMpUrl, base, url);
            const urlWithToken = `${apiUrl}?token=${idToken}`;

            const response = await axios.delete(urlWithToken, headers);
            return handleResponse({ response });
        } catch (error) {
            // console.log('error ^^^^^^^', error);
            return handleResponse({ error });
        }
    };

    getData = async (url, queryParams = '', base = '', customHeaders = {}, useMpUrl = false) => {
        const apiUrl = this.getApiUrl(useMpUrl, base, url);

        if (!firebase.auth().currentUser) {
            await waitForUserLoad();
        }
        const idToken = await getIdToken();
        const urlWithToken = `${apiUrl}?token=${idToken}${queryParams}`;

        try {
            const headers = this.setHeaders(customHeaders);
            const response = await axios.get(urlWithToken, headers);
            return handleResponse({ response });
        } catch (error) {
            return handleResponse({ error });
        }
    };
}

export default new FetchUtils();
