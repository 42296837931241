import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import { store } from 'redux/store/store';
import {
    myContactList,
    softDeleteContactList,
    myUpdatedContactList,
    myContactListError,
    mySingleContact,
    mySingleContactList,
} from 'redux/features/contactlist/contactListSlice';
import { baseApiUrl } from 'app/constants/apiUrl';
import {
    loadContactList,
    searchContactList,
    loadDeletedList,
    createContactList,
    softDelContactList,
    deleteContactList,
    loadMoreContactList,
    loadSingleContact,
    updateContactList,
    restoreContactList,
    loadSingleContactList,
} from 'redux/actions/contactListAction';
import { getIdToken } from '_helpers';
import { waitForUserLoad } from 'redux/sagas/login.saga';

function* handleLoadContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const { callback } = action.payload;
    try {
        if (userToken) {
            const response = yield axios(`${baseApiUrl}/contact-list/all`, {
                params: {
                    token: userToken,
                    // limit: 10,
                },
            });
            if (response.data && response.status == 200) {
                const data = response.data.data;
                callback();
                yield put(myContactList(data));
            } else {
                callback();
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        callback('error');
        console.log(err, 'ERR');
    }
}

function* handleSearchContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();

    try {
        if (userToken) {
            const { name } = action.payload;
            const response = yield axios(`${baseApiUrl}/contact-list/all`, {
                params: {
                    token: userToken,
                    value: name,
                },
            });
            if (response.data && response.status == 200) {
                const data = response.data.data;
                yield put(myContactList(data));
            } else {
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
    }
}

function* handleLoadDeletedContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const {
        callback = () => {
            '';
        },
    } = action.payload;
    try {
        if (userToken) {
            const response = yield axios(`${baseApiUrl}/contact-list/deleted-list`, {
                params: {
                    token: userToken,
                },
            });
            if (response.data && response.status == 200) {
                const data = response.data.data;
                callback();
                yield put(softDeleteContactList(data));
            } else {
                callback();
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        callback('error');
        console.log(err, 'ERR');
    }
}

function* handleCreateContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();

    try {
        if (userToken) {
            const { newlist, showError } = action.payload;
            const postData = { name: newlist };
            const response = yield axios.post(`${baseApiUrl}/contact-list/create`, postData, {
                params: {
                    token: userToken,
                },
            });

            if (response.data && response.status == 201) {
                const callback = () => {
                    '';
                };
                yield put(loadContactList({ callback }));
                showError();
            } else {
                yield put(myContactListError(response.data.message));
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        const { response } = err;
        yield put(myContactListError(response?.data?.message));
        console.log(err, 'ERR');
    }
}

function* handleSoftDeleteContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();

    try {
        if (userToken) {
            const { listId, finishDel } = action.payload;
            const response = yield axios.delete(
                `${baseApiUrl}/contact-list/soft-delete/${listId}`,
                {
                    params: {
                        token: userToken,
                    },
                },
            );

            if (response.status == 200) {
                const callback = () => {
                    '';
                };
                yield put(loadContactList({ callback }));
                finishDel();
            } else {
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
    }
}

function* handleDeleteContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();

    try {
        if (userToken) {
            const { listId, finishDel } = action.payload;
            const response = yield axios.delete(`${baseApiUrl}/contact-list/delete/${listId}`, {
                params: {
                    token: userToken,
                },
            });

            if (response.status == 200) {
                const callback = () => {
                    '';
                };
                yield put(loadDeletedList({ callback }));
                finishDel();
            } else {
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
    }
}

function* handleUpdateContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const { listId, newlist, finishUpdate } = action.payload;
    try {
        if (userToken) {
            const postData = { name: newlist };
            const response = yield axios.patch(
                `${baseApiUrl}/contact-list/update/${listId}`,
                postData,
                {
                    params: {
                        token: userToken,
                    },
                },
            );

            if (response.status == 200) {
                const callback = () => {
                    '';
                };
                yield put(loadContactList({ callback }));
                finishUpdate();
            } else {
                yield put(myContactListError(response.data.message));
                finishUpdate();
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        const { response } = err;
        yield put(myContactListError(response.data.message));
        finishUpdate('error');
        console.log(err, 'ERR');
    }
}

function* handleRestoreContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();

    try {
        if (userToken) {
            const { listId, finishRestore } = action.payload;
            const response = yield axios.post(`${baseApiUrl}/contact-list/restore/${listId}`, '', {
                params: {
                    token: userToken,
                },
            });

            if (response.status == 201) {
                const callback = () => {
                    '';
                };
                yield put(loadDeletedList({ callback }));
                finishRestore();
            } else {
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
    }
}

function* handleLoadMoreContactList() {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const {
        contactList: { nextPage, contactListLength },
    } = store.getState();
    try {
        if (userToken && nextPage) {
            const loadLimit = contactListLength + 10;
            const response = yield axios(`${baseApiUrl}/contact-list/all`, {
                params: {
                    token: userToken,
                    limit: loadLimit,
                },
            });
            if (response.data && response.status == 200) {
                const data = response.data.data;
                yield put(myUpdatedContactList(data));
            } else {
                console.log('response data is different as expected');
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
    }
}

function* handleLoadSingleContact(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const { id, callback } = action.payload;
    try {
        if (userToken) {
            const response = yield axios(`${baseApiUrl}/contact-list/${id}`, {
                params: {
                    token: userToken,
                },
            });

            if (response.data && response.status == 200) {
                const data = response.data.data;
                yield put(mySingleContact(data));
                callback();
            } else {
                console.log('response data is different as expected');
                callback();
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
        callback('error');
    }
}

function* handleLoadSingleContactList(action) {
    yield waitForUserLoad();
    const userToken = yield getIdToken();
    const {
        id,
        listType,
        callback = () => {
            '';
        },
    } = action.payload;
    try {
        if (userToken) {
            const response = yield axios(`${baseApiUrl}/contacts/all/${id}`, {
                params: {
                    token: userToken,
                    status: listType,
                },
            });

            if (response.data && response.status == 200) {
                const data = response.data.data;
                yield put(mySingleContactList(data));
                callback();
            } else {
                console.log('response data is different as expected');
                callback();
            }
        }
    } catch (err) {
        console.log(err, 'ERR');
        callback();
    }
}

function* contactListSaga() {
    yield takeLatest(loadContactList, handleLoadContactList);
    yield takeLatest(searchContactList, handleSearchContactList);
    yield takeLatest(loadDeletedList, handleLoadDeletedContactList);
    yield takeLatest(createContactList, handleCreateContactList);
    yield takeLatest(softDelContactList, handleSoftDeleteContactList);
    yield takeLatest(deleteContactList, handleDeleteContactList);
    yield takeLatest(updateContactList, handleUpdateContactList);
    yield takeLatest(restoreContactList, handleRestoreContactList);
    yield takeLatest(loadMoreContactList, handleLoadMoreContactList);
    yield takeLatest(loadSingleContact, handleLoadSingleContact);
    yield takeLatest(loadSingleContactList, handleLoadSingleContactList);
}

export default contactListSaga;
