import styled from 'styled-components';

export const Workspacewrapper = styled.div`
    .db-area-main {
        margin-left: auto;
        padding: 2rem 30px;
        .db-container {
            max-width: 1250px;
            margin: 0 auto;
        }

        .db-heading {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            margin: 0 0 35px;
        }
    }
    .db-back-link {
        margin-bottom: 40px;
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
    }

    .workspace-cards-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -15px;

        .single-workspace-tile {
            padding: 0px 15px;
            width: 20%;
            text-align: center;
            margin-bottom: 30px;
            background-color: transparent;
            border: none;

            .padding-30 {
                padding: 30px;
                transition: 0.4s ease;
                transform: translateY(0) scale(1);
                &:hover {
                    transform: translateY(-10px) scale(1.04);
                    box-shadow: 0 10px 45px rgba(0, 0, 0, 0.025);
                }
            }

            .worksapce-desc {
                h4 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 15px;
                }

                .workspace-people {
                    display: flex;
                    -webkit-box-pack: center;
                    justify-content: center;
                    margin-left: 10px;

                    .single-person {
                        position: relative;
                        margin-left: -10px;

                        .person-photo {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 1px solid rgb(255, 255, 255);
                            display: block;

                            div {
                                width: 30px;
                                height: 30px;
                            }
                        }

                        .person-name {
                            position: absolute;
                            background-color: #0e64ff;
                            color: #fff;
                            padding: 5px;
                            left: -50%;
                            right: 0;
                            min-width: 120px;
                            border-radius: 6px;
                            box-shadow: 0px 4px 4px rgba(221, 221, 221, 0.25);
                            opacity: 0;
                            visibility: hidden;
                            transition: 0.3s ease;
                            text-align: center;
                        }

                        &:hover {
                            .person-name {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    .total-persons {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        line-height: 30px;
                        box-shadow: rgb(0 0 0 / 12%) 2.39493px 1.59662px 31.9324px;
                        margin-left: -10px;
                        position: relative;
                        z-index: 1;
                        display: block;
                        background-color: rgb(255, 255, 255);
                    }
                }
            }
        }

        .workspace-logo {
            max-width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0px auto 20px;
            line-height: 80px;
            font-size: 27px;

            div {
                max-width: 80px;
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 40px;
                }
            }
        }
    }

    .person-photo {
        width: fit-content;
        height: fit-content;
        transform: scale(0.89);
    }
`;

export const WhiteBlock = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;

  &.equal-height {
    min-height: 100%;
  }
  .white-block-head {
    padding: 22px 35px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    border-bottom: 1px solid #eaeaea;
    margin: 0;
  }

  .white-block-hd {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 22px;
    padding-bottom: 22px;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      height: 1.8px;
      width: calc(100% + 70px);
      left: -35px;
      bottom: 0;
      background: #f7f7f7;
    }
  }
  
  .white-block-body {
    padding: 22px 35px;
    &.wbb{ 
      display: flex; gap:30px;
      @media (max-width: 926.98px) {
        display: block;
       .col-6{ width: 100%;
          &:first-child{
            margin-bottom: 30px;
          }
        } 
      }
    }
    input{
      transition: all 0.2s linear;
      &:focus,&:hover{border:1px solid #e5e5e5;}
    }
    textarea{
      transition: all 0.2s linear;
      &:focus,&:hover{border:1px solid #e5e5e5;}
    }
    .ant-alert-info{
      margin-bottom: 20px;
    }
  }
  .white-block-body-small {
    padding: 15px;
  }
  }
  .padding-30 {
    padding: 30px;
  }

  .create-workspace {
    h1 {
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 10px;
      }
  
      p {
        font-size: 16px;
        margin: 0;
      }

      .workspace-title-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        margin: 0px -15px;
      }

      .workspace-text-block {
        padding: 0px 15px;
      }

      .workspace-btn-block {
          .button {
            color: rgb(255, 255, 255);
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            background: rgb(14, 100, 255);
            padding: 14px 20px;
            display: inline-block;
            text-align: center;
            border: none;
            border-radius: 8px;
            transition: all 0.4s linear 0s;
          }
      }
  }
  
`;
