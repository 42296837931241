export const CONTACT_LIST = {
    LOAD_CONTACT_LIST: 'LOAD_CONTACT_LIST',
    LOAD_SINGLE_CONTACT_LIST: 'LOAD_SINGLE_CONTACT_LIST',
    LOAD_SINGLE_CONTACT_TYPE: 'LOAD_SINGLE_CONTACT_TYPE',
    LOAD_MORE_CONTACT_LIST: 'LOAD_MORE_CONTACT_LIST',
    LOAD_SEGMENTS_LIST: 'LOAD_SEGMENTS_LIST',
    SEARCH_CONTACT_LIST: 'SEARCH_CONTACT_LIST',
    LOAD_DELETED_LIST: 'LOAD_DELETED_LIST',
    CREATE_CONTACT_LIST: 'CREATE_CONTACT_LIST',
    SOFT_DELETE_CONTACT_LIST: 'SOFT_DELETE_CONTACT_LIST',
    DELETE_CONTACT_LIST: 'DELETE_CONTACT_LIST',
    UPDATE_CONTACT_LIST: 'UPDATE_CONTACT_LIST',
    RESTORE_CONTACT_LIST: 'RESTORE_CONTACT_LIST',
};

export const WORKSPACE_ACTIONS = {
    LOAD_WORKSPACE_LIST: 'LOAD_WORKSPACE_LIST',
};
