import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    trashLoading: false,
    contactCount: 0,
    contactList: [],
    segmentsList: [],
    softDeleteList: [],
    singleContact: [],
    singleContactList: [],
    contactListLength: 0,
    nextPage: null,
    errorState: false,
    errorMessage: '',
};

const contactListSlice = createSlice({
    name: 'contactList',
    initialState: initialState,
    reducers: {
        myContactList(state, action) {
            state.contactCount = action.payload.contactCount;
            if (action.payload && Array.isArray(action.payload.list)) {
                state.contactList = action.payload.list;
            }

            if (action.payload && Array.isArray(action.payload.list)) {
                state.contactListLength = action.payload.list.length;
            }

            if (action.payload && action.payload.totalDocs) {
                state.nextPage = action.payload.totalDocs.nextPage;
            }
            state.errorState = false;
            state.errorMessage = '';
            return state;
        },
        softDeleteContactList(state, action) {
            if (action.payload && Array.isArray(action.payload)) {
                state.softDeleteList = action.payload;
            }
            state.trashLoading = true;
            return state;
        },
        myUpdatedContactList(state, action) {
            state.contactCount = action.payload.contactCount;
            if (action.payload && Array.isArray(action.payload.list)) {
                state.contactList = action.payload.list;
            }

            if (action.payload && Array.isArray(action.payload.list)) {
                state.contactListLength = action.payload.list.length;
            }

            if (action.payload && action.payload.totalDocs) {
                state.nextPage = action.payload.totalDocs.nextPage;
            }
            state.errorState = false;
            state.errorMessage = '';
            return state;
        },
        myContactListError(state, action) {
            state.errorState = true;
            if (action.payload) {
                state.errorMessage = action.payload;
            }
            return state;
        },
        clearError(state) {
            state.errorState = false;
            state.errorMessage = '';
            return state;
        },
        mySingleContact(state, action) {
            if (action.payload) {
                state.singleContact = action.payload;
            }
            return state;
        },
        mySingleContactList(state, action) {
            if (action.payload) {
                state.singleContactList = action.payload;
            }
            return state;
        },
    },
});

export const {
    myContactList,
    softDeleteContactList,
    myUpdatedContactList,
    myContactListError,
    clearError,
    mySingleContact,
    mySingleContactList,
} = contactListSlice.actions;

export default contactListSlice.reducer;

export const selectContactList = (state) => state.contactList.contactList;
