import { put, takeLatest } from 'redux-saga/effects';
import i18next from 'i18next';
import get from 'lodash/get';
import { message } from 'antd';
import { store } from 'redux/store/store';
import { hasOwnProperty, showNotification } from '_helpers';
import { savingTemplate } from 'redux/features/email_editor/editorSettingsSlice';
import { openTemplate } from 'redux/features/email_editor/templateIdSlice';
import { templateLoaded, addOuterSection } from 'redux/features/email_editor/emailEditorSlice';
import {
    saveEmail,
    saveSectionTemplate,
    loadSavedSectionTemplates,
    loadSingleSectionTemplate,
    deleteSectionTemplate,
} from 'redux/actions/emailAction';
import { loadMyTemplates, deleteTemplate } from 'redux/actions/templatesAction';
import { generateId } from 'app/services/database.service';
import { generateId as generateRandomId } from '_helpers/index';
import { savedSectionsList } from 'redux/features/section_templates/sectionTemplatesSlice';
import * as STRINGS from 'app/language/en.lang';
import FetchUtils from 'app/services/api';

const {
    SAVING_TEMPLATE,
    TEMPLATE_SAVED_SUCCESSFULLY,
    SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER,
    WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE,
    TEMPLATE_DELETED_SUCCESSFULLY,
    FAILED_TO_DELETE_TEMPLATE,
} = STRINGS.SHOW_NOTIFICATION;

function* handleSaveEmail(action) {
    const { saveTemplateCb = () => {} } = action.payload;
    yield put(savingTemplate(true));
    const { templateId, emailEditor } = store.getState();
    if (
        templateId &&
        typeof emailEditor.present === 'object' &&
        hasOwnProperty(emailEditor.present, 'outerSections') &&
        Array.isArray(emailEditor.present.outerSections)
    ) {
        const postData = {
            name: emailEditor.present.title,
            emailTemplateObject: emailEditor.present,
        };

        const response = yield FetchUtils.patchData(
            `/email-template/templates/${templateId}`,
            postData,
            '',
            true,
        );
        if (response.success) {
            yield put(savingTemplate(false));
            if (typeof saveTemplateCb === 'function') {
                saveTemplateCb();
            }
        } else {
            yield put(savingTemplate(false));
            console.log(get(response, 'error', 'Error')); //Give Notification Here
            showNotification(
                'storyCriticalError',
                i18next.t(WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE),
                '',
                'error',
            );
        }
    } else {
        yield put(savingTemplate(false));
        console.log('Internal Error');
        showNotification(
            'storyCriticalError',
            i18next.t(WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE),
            '',
            'error',
        );
    }
}

function* handleCreateNewTemplate() {
    const { templateId } = store.getState();
    const initialState = {
        id: templateId,
        outerSections: [],
        title: 'New Template',
    };
    if (templateId) {
        const postData = {
            templateId,
            name: 'New Template',
            emailTemplateObject: initialState,
        };
        const response = yield FetchUtils.postData('/email-template/create', postData, '', true);

        if (response.success) {
            yield put(templateLoaded(initialState));
        } else {
            showNotification(
                'storyCriticalError',
                i18next.t(WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE),
                '',
                'error',
            );
        }
    } else {
        console.log('Internal Error');
    }
}

function* handleOpenTemplate(action) {
    const templateID = action.payload;

    if (templateID) {
        const { data, success } = yield FetchUtils.getData(
            `/email-template/templates/${templateID}`,
            '',
            '',
            {},
            true,
        );
        if (success && data) {
            const { exists = false, email = {} } = data;
            if (exists) {
                const { templateId = false, name = '', emailTemplateObject = {} } = email;
                if (templateId && name && emailTemplateObject) {
                    const state = {
                        id: templateId,
                        outerSections: emailTemplateObject.outerSections,
                        title: name,
                    };
                    yield put(templateLoaded(state));
                }
            } else {
                yield handleCreateNewTemplate();
            }
        } else {
            showNotification(
                'storyCriticalError',
                i18next.t(WE_RAN_INTO_SOME_ERROR_PLEASE_REFRESH_PAGE_TO_CONTINUE),
                '',
                'error',
            );
        }
    } else {
        console.log('Internal Error');
    }
}

function* handleDeleteTemplate(action) {
    const { templateId, callback } = action.payload;
    if (templateId) {
        const response = yield FetchUtils.deleteData(
            `/email-template/templates/${templateId}`,
            '',
            true,
        );
        if (response.success) {
            yield put(loadMyTemplates());
            callback({
                message: i18next.t(TEMPLATE_DELETED_SUCCESSFULLY),
                status: 'success',
            });
        } else {
            callback({ message: i18next.t(FAILED_TO_DELETE_TEMPLATE), status: 'error' });
        }
    }
}

function* handleSaveSectionAsTemplate(action) {
    message.loading({ content: i18next.t(SAVING_TEMPLATE), key: 'save_section_as_template' });
    const { outerSectionId } = action.payload;
    const {
        emailEditor: {
            present: { outerSections },
        },
    } = store.getState();

    if (outerSectionId && Array.isArray(outerSections)) {
        const index = outerSections.findIndex((outerSection) => outerSectionId === outerSection.id);
        if (index > -1) {
            const oldData = outerSections[index];
            // copying the element
            let newOuterSection = JSON.parse(JSON.stringify(oldData));

            if (newOuterSection) {
                // updating the element with different Id
                newOuterSection.id = generateRandomId();
                if (Array.isArray(newOuterSection.sections)) {
                    newOuterSection.sections.map((section) => {
                        section.id = generateRandomId();
                        if (Array.isArray(section.blocks)) {
                            section.blocks.map((block) => (block.id = generateRandomId()));
                        }
                    });
                }
            }
            const postData = {
                sectionId: generateId(),
                sectionTemplateObject: newOuterSection,
            };
            const response = yield FetchUtils.postData(
                '/email-template/createSection',
                postData,
                '',
                true,
            );
            if (response.success) {
                message.success({
                    content: i18next.t(TEMPLATE_SAVED_SUCCESSFULLY),
                    key: 'save_section_as_template',
                    duration: 2,
                });
            } else {
                message.error({
                    content: i18next.t(SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER),
                    key: 'save_section_as_template',
                    duration: 2,
                });
            }
        }
    }
}

function* handleloadSavedSectionTemplates() {
    const {
        data,
        success,
        error = '',
    } = yield FetchUtils.getData('/email-template/section/all', '', '', {}, true);
    if (success) {
        if (data && Array.isArray(data)) {
            yield put(savedSectionsList(data));
        } else {
            yield put(savedSectionsList([]));
        }
    } else {
        console.log(error);
        yield put(savedSectionsList([]));
        message.error({
            content: i18next.t(SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER),
            key: 'save_section_as_template',
            duration: 2,
        });
    }
}

function* handleloadSingleSectionTemplate(action) {
    const { sectionId, activeOuterSectionId, selectedButton } = action.payload;
    if (sectionId) {
        const {
            data,
            success,
            error = '',
        } = yield FetchUtils.getData(`/email-template/sections/${sectionId}`, '', '', {}, true);
        if (success && data) {
            const { sectionTemplateObject } = data;
            if (sectionTemplateObject && typeof sectionTemplateObject == 'object') {
                // copying the element
                let newOuterSection = JSON.parse(JSON.stringify(sectionTemplateObject));
                if (newOuterSection) {
                    // updating the element with different Id
                    newOuterSection.id = generateRandomId();
                    if (Array.isArray(newOuterSection.sections)) {
                        newOuterSection.sections.map((section) => {
                            section.id = generateRandomId();
                            if (Array.isArray(section.blocks)) {
                                section.blocks.map((block) => (block.id = generateRandomId()));
                            }
                        });
                    }
                }
                if (activeOuterSectionId && selectedButton) {
                    yield put(
                        addOuterSection({
                            outerSection: newOuterSection,
                            activeOuterSectionId,
                            selectedButton,
                        }),
                    );
                }
            }
        } else {
            console.log(error);
            message.error({
                content: i18next.t(SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER),
                key: 'save_section_as_template',
                duration: 2,
            });
        }
    }
}

function* handledeleteSectionTemplate(action) {
    const { sectionId, callback } = action.payload;
    if (sectionId) {
        const response = yield FetchUtils.deleteData(
            `/email-template/delete/section/${sectionId}`,
            '',
            true,
        );
        if (response.success) {
            yield put(loadSavedSectionTemplates());
            callback({
                message: i18next.t(TEMPLATE_DELETED_SUCCESSFULLY),
                status: 'success',
            });
        } else {
            console.log(get(response, 'error'));
            callback({ message: i18next.t(FAILED_TO_DELETE_TEMPLATE), status: 'error' });
        }
    } else {
        console.log('Internal Error');
    }
}

function* emailSaga() {
    yield takeLatest(saveEmail, handleSaveEmail);
    yield takeLatest(openTemplate, handleOpenTemplate);
    yield takeLatest(deleteTemplate, handleDeleteTemplate);
    yield takeLatest(saveSectionTemplate, handleSaveSectionAsTemplate);
    yield takeLatest(loadSavedSectionTemplates, handleloadSavedSectionTemplates);
    yield takeLatest(loadSingleSectionTemplate, handleloadSingleSectionTemplate);
    yield takeLatest(deleteSectionTemplate, handledeleteSectionTemplate);
}

export default emailSaga;
