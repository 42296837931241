import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/features/app_login/loginSlice';
import { logout as logoutAuth } from 'app/services/auth.service';
import { PROFILE_SETTINGS } from 'app/language/en.lang';

const { LOGOUT } = PROFILE_SETTINGS;

function Logout() {
    const dispatch = useDispatch();
    const handleLogout = () => {
        logoutAuth()
            .then(() => {
                dispatch(logout());
            })
            .catch(() => {
                console.log('logout in catch!');
            });
    };
    return (
        <button type="button" onClick={handleLogout}>
            Logout
        </button>
    );
}

export default Logout;
