import * as STRINGS from './en.lang';

export const italian = {
    [STRINGS.LOGIN_PAGE.TRIP_TO_BALI]: 'Viaggio a Bali',
    [STRINGS.LOGIN_PAGE.SIGN_IN]: 'Accedi',
    [STRINGS.LOGIN_PAGE.SIGN_UP]: 'Registrati',
    [STRINGS.LOGIN_PAGE.WELCOME_BACK_TO_MAKESTORIES]: 'Bentornato a MakeStories',
    [STRINGS.LOGIN_PAGE.EMAIL_ADD]: 'Indirizzo Email',
    [STRINGS.LOGIN_PAGE.PASSWORD]: 'Password',
    [STRINGS.LOGIN_PAGE.FORGOT_PASSWORD]: 'Password dimenticata',
    [STRINGS.LOGIN_PAGE.CAPS_SIGN_IN]: 'ACCEDI',
    [STRINGS.LOGIN_PAGE.OR]: 'O',
    [STRINGS.LOGIN_PAGE.GOOGLE]: 'GOOGLE',
    [STRINGS.LOGIN_PAGE.START_BUILDING_STORIES]: 'Inizia a Costruire Storie',
    [STRINGS.LOGIN_PAGE.ABSOLUTELY_FREE]: 'Completamente Gratuito',
    [STRINGS.LOGIN_PAGE.USERNAME]: 'Username',
    [STRINGS.LOGIN_PAGE.SIGN_UP_FOR_FREE]: 'Registrati Gratuitamente',
    [STRINGS.LOGIN_PAGE.WE_WILL_SEND_EMAIL_WITH_INSTRUCTION]:
        'Ti invieremo una Email con le Istruzioni',
    [STRINGS.LOGIN_PAGE.SEND_RESET_INSTRUCTION]: 'Invia Istruzioni di Reset',
    [STRINGS.PROFILE_NOTIFICATIONS.NAME_UPDATED]: 'Nome aggiornato',
    [STRINGS.PROFILE_NOTIFICATIONS.PROFILE_SUCCESSFULLY_UPDATED]:
        'Il tuo profilo è stato aggiornato con successo',
    [STRINGS.PROFILE_NOTIFICATIONS.ERROR]: 'Errore',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PROFILE]: 'Errore mentre si aggiorna il profilo',
    [STRINGS.PROFILE_NOTIFICATIONS.EMPTY_DISPLAY_NAME]: 'Il Nome mostrato non può essere vuoto',
    [STRINGS.PROFILE_NOTIFICATIONS.USER_NOT_FOUND]: 'Non possibile trovare utente',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_UPDATED]: 'Password Aggiornata',
    [STRINGS.PROFILE_NOTIFICATIONS.PASSWORD_SUCCESSFULLY_UPDATED]:
        'La tua password è stata aggiornata con successo',
    [STRINGS.PROFILE_NOTIFICATIONS.ERR_UPDATING_PASSWORD]: 'Errore mentre aggiorno la password',
    [STRINGS.PROFILE_NOTIFICATIONS.DIFFERENT_PASSWORD]:
        'Nuova password e Conferma password sono differenti. Si prega di verificare a riprovare',
    [STRINGS.PROFILE_NOTIFICATIONS.INCOMPLETE_DETAILS]:
        'Si prega di inserire tutti i dettagli per procedere',
    [STRINGS.USER_PROFILE.NEW_PASSWORD]: 'Nuova Password',
    [STRINGS.USER_PROFILE.CHANGE_PASSWORD]: 'Cambia Password?',
    [STRINGS.USER_PROFILE.OLD_PASSWORD]: 'Vecchia password',
    [STRINGS.USER_PROFILE.CONFIRM_PASSWORD]: 'Password confermata',
    [STRINGS.USER_PROFILE.RESET]: 'Reset',
    [STRINGS.USER_PROFILE.PROFILE_SETTINGS]: 'Impostazioni del Profilo',
    [STRINGS.USER_PROFILE.NAME]: 'Nome',
    [STRINGS.USER_PROFILE.EMAIL]: 'Email',
    [STRINGS.USER_PROFILE.SAVE_CHANGES]: 'Salva Cambiamenti',
    [STRINGS.USER_PROFILE.CURRENT_PASSWORD]: 'Password attuale',
    [STRINGS.USER_PROFILE.UPDATE_PASSWORD]: 'Aggiorna Password',
};
