import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { login } from 'app/services/auth.service';
import { LOGIN_PAGE } from 'app/language/en.lang';
import { isUserAuthenticated } from 'redux/features/app_login/loginSlice';
import { useInput } from 'app/hooks/useInput';
import { login as loginAction } from 'redux/actions/loginAction';

// Common components of Login
import MeLogo from 'app/Pages/Login/Components/MeLogo';
import SigninWithGoogleC from 'app/Pages/Login/Components/signInWithGoogle';
import SigninWithAppleC from 'app/Pages/Login/Components/signInWithApple';
import BackgroundCarousel from 'app/Pages/Login/Components/BackgroundCarousel';

// Styled components import
import {
    FormWrapper,
    FormMainIn,
    Heading,
    Paragraph,
    LoginSocial,
    LoginSeparator,
    LoginSeparatorIn,
    DualLinks,
    InputTag,
    ButtonTag,
    LabelTag,
    ErrorMsg,
} from './loginCommonStyle';

const {
    SIGN_IN,
    WELCOME_BACK_TO_MAKESTORIES,
    EMAIL_ADD,
    PASSWORD,
    CAPS_SIGN_IN,
    OR,
    NOT_SIGN_IN,
    CREATE_ACCOUNT,
    FORGOT_PASSWORD,
} = LOGIN_PAGE;

function SignIn() {
    const [error, setError] = useState(false);
    const [userEmail, setEmail, resetEmail] = useInput('');
    const [userPassword, setPassword, resetPassword] = useInput('');

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(isUserAuthenticated);

    const handleCallback = (childData) => {
        setError(childData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = userEmail.value;
        const password = userPassword.value;
        login(email, password)
            .then((data) => {
                dispatch(loginAction({ user: data.user }));
            })
            .catch((error) => {
                let errorMessage = error.message;
                setError(errorMessage);
            });

        resetEmail();
        resetPassword();
    };

    return isAuthenticated ? (
        <Redirect to="/" />
    ) : (
        <div className="login">
            <BackgroundCarousel />
            <FormWrapper>
                <MeLogo />
                <FormMainIn>
                    <div>
                        <Heading>{SIGN_IN}</Heading>
                        <Paragraph>{WELCOME_BACK_TO_MAKESTORIES}</Paragraph>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <LabelTag>{EMAIL_ADD}</LabelTag>
                            <InputTag
                                type="email"
                                id="email"
                                placeholder="Registered address"
                                {...userEmail}
                            ></InputTag>
                        </div>
                        <div>
                            <LabelTag>{PASSWORD}</LabelTag>
                            <InputTag
                                type="password"
                                id="password"
                                placeholder="Password"
                                {...userPassword}
                            ></InputTag>
                        </div>
                        <div>
                            <Link to="/forgotten-password">{FORGOT_PASSWORD}</Link>
                        </div>
                        <DualLinks>
                            <ButtonTag onClick={handleSubmit}>{CAPS_SIGN_IN}</ButtonTag>
                        </DualLinks>
                        <LoginSeparator>
                            <LoginSeparatorIn>{OR}</LoginSeparatorIn>
                        </LoginSeparator>
                        <LoginSocial>
                            <SigninWithGoogleC parentCallback={handleCallback}></SigninWithGoogleC>
                            <SigninWithAppleC parentCallback={handleCallback}></SigninWithAppleC>
                        </LoginSocial>
                    </form>
                    <div>{error && <ErrorMsg>{error}</ErrorMsg>}</div>
                    <p>
                        {NOT_SIGN_IN}
                        <Link to="/sign-up"> {CREATE_ACCOUNT}</Link>
                    </p>
                </FormMainIn>
            </FormWrapper>
        </div>
    );
}

export default SignIn;
