import * as STRINGS from 'app/language/en.lang';

const { NAME, TYPE, COUNT, SUBSCRIBER_COUNT, ACTION, PARENT_LIST_ID } =
    STRINGS.CONTACT_LIST_DASHBOARD;

export const contactListColumns = [
    {
        title: NAME,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: TYPE,
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: COUNT,
        dataIndex: 'count',
        key: 'count',
    },
    {
        title: SUBSCRIBER_COUNT,
        dataIndex: 'subscriberCount',
        key: 'subscriberCount',
    },
];

export const segmentListColumns = [
    {
        title: NAME,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: TYPE,
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: PARENT_LIST_ID,
        dataIndex: 'parentListId',
        key: 'parentListId',
    },
    {
        title: ACTION,
        dataIndex: 'action',
        key: 'action',
    },
];

export const singleContactTabBtns = [
    {
        title: 'All',
        dataIndex: 'all',
        key: 'all',
        type: 'ALL',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        type: 'ACTIVE',
    },
    {
        title: 'Unsubscribed',
        dataIndex: 'unsubscribed',
        key: 'unsubscribed',
        type: 'UNSUBSCRIBE',
    },
    {
        title: 'Bounced',
        dataIndex: 'bounced',
        key: 'bounced',
        type: 'BOUNCED',
    },
    {
        title: 'Archive',
        dataIndex: 'archive',
        key: 'archive',
        type: 'ARCHIVED',
    },
];

export const singleContactTableColumn = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        checked: true,
    },
    {
        title: 'First Name',
        dataIndex: 'firstname',
        key: 'firstname',
        checked: true,
    },
    {
        title: 'Last Name',
        dataIndex: 'lastname',
        key: 'lastname',
        checked: true,
    },
    {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        checked: true,
    },
    {
        title: 'Created At',
        dataIndex: 'created-date',
        key: 'created_date',
        checked: true,
    },
];
