import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { storeSyncMiddleware } from 'auth-service';
import undoable from 'redux-undo';
import createSagaMiddleware from 'redux-saga';
import counterReducer from 'app/Components/Counter/counterSlice';
import languageReducer from 'redux/features/app_language/language.slice';
import loginReducer, {
    currentUserReducer,
    currentUserIdReducer,
} from 'redux/features/app_login/loginSlice';
import emailEditorReducer from 'redux/features/email_editor/emailEditorSlice';
import activeOuterSectionIdReducer from 'redux/features/email_editor/activeOuterSectionIdSlice';
import activeSectionIdReducer from 'redux/features/email_editor/activeSectionIdSlice';
import activeBlockIdReducer from 'redux/features/email_editor/activeBlockIdSlice';
import templateIdReducer from 'redux/features/email_editor/templateIdSlice';
import editorSettingsReducer from 'redux/features/email_editor/editorSettingsSlice';
import emailTemplatesReducer from 'redux/features/email_templates/emailTemplatesSlice';
import sectionTemplatesReducer from 'redux/features/section_templates/sectionTemplatesSlice';
import workspaceIdReducer from 'redux/features/workspace/workspaceSlice';
import mediaLibrarySlice from 'redux/features/media_library/mediaLibrary.slice';
import templateLoadingStatusReducer from 'redux/features/email_editor/templateLoadingStatusSlice';
import unsplashImagesReducer from 'redux/features/Unsplash_Images/unsplashImagesSlice';
import tenorGifsReducer from 'redux/features/tenor_gifs/tenorGifsSlice';
import contactListReducer from 'redux/features/contactlist/contactListSlice';
import { mediaLibSyncMiddleware } from 'media-library';
import rootSaga from 'redux/sagas';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { undoRedoConfig } from './undoableConfig';

const configureCustomStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const persistConfig = {
        key: 'root',
        storage,
        blacklist: [],
        whitelist: [], //'isAuthenticated', 'currentUser', 'currentUserId'
    };

    const rootReducer = combineReducers({
        counter: counterReducer,
        app_language: languageReducer,
        isAuthenticated: loginReducer,
        currentUser: currentUserReducer,
        currentUserId: currentUserIdReducer,
        emailEditor: undoable(emailEditorReducer, undoRedoConfig),
        activeOuterSectionId: activeOuterSectionIdReducer,
        activeSectionId: activeSectionIdReducer,
        activeBlockId: activeBlockIdReducer,
        templateId: templateIdReducer,
        editorSettings: editorSettingsReducer,
        emailTemplates: emailTemplatesReducer,
        sectionTemplates: sectionTemplatesReducer,
        workSpaceReducer: workspaceIdReducer,
        mediaLibrarySlice: mediaLibrarySlice,
        templateLoadingStatus: templateLoadingStatusReducer,
        unsplashImages: unsplashImagesReducer,
        tenorGifs: tenorGifsReducer,
        contactList: contactListReducer,
    });

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(storeSyncMiddleware, sagaMiddleware, mediaLibSyncMiddleware),
    });

    const persistor = persistStore(store);
    sagaMiddleware.run(rootSaga);
    return { store, persistor };
};
export const { store, persistor } = configureCustomStore();
